import React, { useCallback, useMemo, useState } from 'react';
import Gridapp from '../../../../../store/types/gridapp';
import styled from '@emotion/styled';
import Overlay from '../../../../common/overlay';
import { Spinner } from '../../../../common/spinner';
import { useGridAppBuilds } from '../../../../common/use-grid-app-builds';
import { Option } from '../../../../common/react-final-form';
import GridappBuildStatusEnum from '../../../../../store/types/gridapp-build-status.enum';
import { Divider, message, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import GridappBuild from '../../../../../store/types/gridapp-build';
import SchemaForm from '../../../../common/schema-form/schema-form.component';

type AppSettingsData = Record<string, string | number | boolean | Record<string, string>>;

interface ContentLibraryAppPreviewProps {
  tenantId: string;
  app: Gridapp;
  appVersion?: string;
  appSettingsData?: AppSettingsData,
  onAppSettingsUpdated: (
    gridApp: Gridapp,
    gridAppBuild: GridappBuild,
    appSettingsData: AppSettingsData,
  ) => void;
}

const ContentLibraryAppPreview = (props: ContentLibraryAppPreviewProps) => {
  const { tenantId, app, appVersion, appSettingsData, onAppSettingsUpdated } = props;

  const { t } = useTranslation();

  // const [initialVersionOnLoad, setInitialVersionOnLoad] = useState<string | undefined>(undefined);
  const [gridAppBuild, setGridAppBuild] = useState<GridappBuild | undefined>(undefined);

  const { data: gridAppBuilds, isLoading, isFetching } = useGridAppBuilds({ gridAppId: app.id });

  const appVersions = useMemo<Option[]>(() => {
    if (!gridAppBuilds) {
      return [];
    }

    return gridAppBuilds
      .filter((gridAppBuild) => {
        return gridAppBuild.status === GridappBuildStatusEnum.PUBLISHED && gridAppBuild.result;
      })
      .map((gridAppBuild) => {
        return { label: gridAppBuild.result!.version, value: gridAppBuild.result!.version };
      });
  }, [gridAppBuilds]);

  const initialVersionOnLoad = useMemo(() => {
    if (!gridAppBuilds) {
      return undefined;
    }

    let initialVersionOnLoad: string | undefined = undefined;

    const latestBuild = gridAppBuilds[0];
    initialVersionOnLoad = latestBuild.result ? latestBuild.result.version : undefined;

    if (appVersion) {
      initialVersionOnLoad = appVersion;
    }

    if (initialVersionOnLoad) {
      const gridAppBuild = gridAppBuilds.find((gridAPpBuild) =>
        gridAPpBuild.result && gridAPpBuild.result.version === initialVersionOnLoad ? true : false
      );
      setGridAppBuild(gridAppBuild);
    }

    return initialVersionOnLoad;
  }, [appVersion, gridAppBuilds]);

  const handleAppVersionChange = useCallback(
    (appVersion: string) => {
      if (!gridAppBuilds) {
        return;
      }

      const gridAppBuild = gridAppBuilds.find((gridAppBuild) =>
        gridAppBuild.result && gridAppBuild.result.version === appVersion ? true : false,
      );
      setGridAppBuild(gridAppBuild);
    },
    [gridAppBuilds],
  );

  return (
    <AppPreviewContainer>
      {(isLoading || isFetching) && (
        <AppPreviewLoading>
          <Overlay>
            <Spinner />
          </Overlay>
        </AppPreviewLoading>
      )}

      {!isLoading && !isFetching && (
        <AppPreviewFieldsContainer>
          <AppPreviewVersionsContainer>
            <Typography.Text>{t('contents.chooseVersion')}</Typography.Text>
            <Select
              onChange={handleAppVersionChange}
              placeholder={t('contents.pleaseSelect')}
              defaultValue={initialVersionOnLoad}
            >
              {appVersions.map((appVersion) => {
                return (
                  <Select.Option key={appVersion.value} value={appVersion.value}>
                    {appVersion.label}
                  </Select.Option>
                );
              })}
            </Select>
          </AppPreviewVersionsContainer>

          <Divider />

          <AppPreviewSettingsContainer>
            {gridAppBuild && (
              <form onSubmit={(event) => event.stopPropagation()}>
                <SchemaForm
                  id={'content-library-app-preview-schema-form'}
                  organisationId={tenantId}
                  data={appSettingsData || {}}
                  schema={gridAppBuild.result && gridAppBuild.result.settingsSchema}
                  uiSchema={gridAppBuild.result && gridAppBuild.result.metaSchema}
                  onError={(_) => {
                    message.error(t('contents.errorsInTheAppSettingsForm'));
                  }}
                  onSubmit={(formData) => {
                    onAppSettingsUpdated(app, gridAppBuild, formData);
                  }}
                />
              </form>
            )}
          </AppPreviewSettingsContainer>
        </AppPreviewFieldsContainer>
      )}
    </AppPreviewContainer>
  );
};

export default ContentLibraryAppPreview;

const AppPreviewContainer = styled.div`
  height: 100%;
`;

const AppPreviewLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
`;

const AppPreviewFieldsContainer = styled.div``;

const AppPreviewVersionsContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-items: center;
  align-items: center;

  .ant-select {
    width: 50%;
  }
`;

const AppPreviewSettingsContainer = styled.div``;
