import { ContentTagType } from '../common/content-tags/content-tag-type';
import DeviceStatusEnum from './device-status.enum';
import UniversalDeviceType from './universal-device-type.enum';

export interface ParentDevice {
  id?: string;
  type?: UniversalDeviceType;
  deviceSerial?: string;
  extras?: {
    remoteId?: string;
    lastScheduledRebootAt?: string;
    rebootSchedule?: string;
  };
}

export enum UniversalDeviceModuleStatus {
  RUNNING = 'running',
  STOPPED = 'stopped',
  FAILED = 'failed',
  BACKOFF = 'backoff',
  UNHEALTHY = 'unhealthy',
}

export interface UniversalDeviceModule {
  desiredVersion: string;
  version: string;
  module: string;
  status: UniversalDeviceModuleStatus;
  // TODO: add telemetry here if needed
  // for now we don't include it as there's no use-case
}

export default interface UniversalDevice {
  appIds: string[];
  deviceName: string;
  deviceSerial: string;
  deviceProvisioned: boolean;
  deviceMonitored: boolean;
  deviceExpectedAvailability?: {
    days: number[];
    start?: string;
    stop?: string;
  };
  screenshotUrl: string;
  screenshotUpdated: string;
  id: string;
  status: DeviceStatusEnum;
  uuid: string;
  organizationId: string;
  statusUpdateTime: string;
  type: string;
  iotedge: boolean;
  parentDevice: ParentDevice | null;
  env: string;
  properties?: DeviceProperties;
  notes: string;
  externalId?: string;
  spaces: string[];
  build: string;
  lease?: {
    expiry: string;
  }
  edgeStatusLog: string;
  edgeStatus: string;
  edgeStatusUpdateTime: string;
  modules?: UniversalDeviceModule[];
  tags?: UniversalDeviceTags[];
}

export interface UniversalDeviceTags {
  tagType: ContentTagType.CONTENT_TAG;
  tagId: string;
  values: string[];
}

export interface DeviceProperties {
  reported?: {
    cpuUsage: number;
    memUsage: number;
    diskUsage: number;
    supervisorRelease: string;
    appBuild: string;
    // TO DO: Define other properties
  };
  desired?: {
    env: string;
  };
}
