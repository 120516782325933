const baseRoute = (tenantId: string) => ['', 'v2', 'report', 'tenants', tenantId];

const route = (tenantId: string, paths: string[]) =>
  [...baseRoute(tenantId), ...paths].join('/');

const routes = {
  getTenantEvents: (tenantId: string) => route(tenantId, ['events']),
  getTenantSessions: (tenantId: string) => route(tenantId, ['sessions']),
  getTenantNps: (tenantId: string) => route(tenantId, ['nps']),
  getTenantEventsFlow: (tenantId: string) => route(tenantId, ['eventsFlow']),
  getTenantProducts: (tenantId: string) => route(tenantId, ['products']),
  getTenantCategories: (tenantId: string) => route(tenantId, ['categories']),
  getTenantPurchases: (tenantId: string) => route(tenantId, ['purchases']),
  getTenantPurchasedProducts: (tenantId: string) =>
    route(tenantId, ['purchased-products']),
  getTenantQrCodes: (tenantId: string) => route(tenantId, ['qr-codes']),
  getTenantMedia: (tenantId: string) => route(tenantId, ['media']),

  getInstallationEvents: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'events']),
  getInstallationsEvents: (tenantId: string) =>
    route(tenantId, ['installations', 'events']),
  getInstallationSessions: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'sessions']),
  getInstallationsSessions: (tenantId: string) =>
    route(tenantId, ['installations', 'sessions']),
  getInstallationNps: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'nps']),
  getInstallationEventsFlow: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'eventsFlow']),
  getInstallationProducts: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'products']),
  getInstallationCategories: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'categories']),
  getInstallationPurchases: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'purchases']),
  getInstallationPurchasedProducts: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'purchased-products']),
  getInstallationQrCodes: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'qr-codes']),
  getInstallationMedia: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'media']),

  getSpaceEvents: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'events']),
  getSpacesEvents: (tenantId: string) => route(tenantId, ['spaces', 'events']),
  getSpacesInstallationEvents: (tenantId: string, installationId: string) =>
    route(tenantId, ['spaces', 'installations', installationId, 'events']),
  getSpaceSessions: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'sessions']),
  getSpacesSessions: (tenantId: string) => route(tenantId, ['spaces', 'sessions']),
  getSpacesInstallationSessions: (tenantId: string, installationId: string) =>
    route(tenantId, ['spaces', 'installations', installationId, 'sessions']),
  getSpaceNps: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'nps']),
  getSpaceEventsFlow: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'eventsFlow']),
  getSpaceProducts: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'products']),
  getSpaceCategories: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'categories']),
  getSpacePurchases: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'purchases']),
  getSpacePurchasedProducts: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'purchased-products']),
  getSpaceQrCodes: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'qr-codes']),
  getSpaceMedia: (tenantId: string, spaceId: string) =>
    route(tenantId, ['spaces', spaceId, 'media']),

  getDeviceEvents: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'events']),
  getDevicesEvents: (tenantId: string) => route(tenantId, ['devices', 'events']),
  getDeviceSessions: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'sessions']),
  getDevicesSessions: (tenantId: string) => route(tenantId, ['devices', 'sessions']),
  getDeviceNps: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'nps']),
  getDeviceEventsFlow: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'eventsFlow']),
  getDeviceProducts: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'products']),
  getDeviceCategories: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'categories']),
  getDevicePurchases: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'purchases']),
  getDevicePurchasedProducts: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'purchased-products']),
  getDeviceQrCodes: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'qr-codes']),
  getDeviceMedia: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'media']),

  getAppEvents: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'events']),
  getAppSessions: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'sessions']),
  getAppNps: (tenantId: string, appId: string) => route(tenantId, ['apps', appId, 'nps']),
  getAppEventsFlow: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'eventsFlow']),
  getAppProducts: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'products']),
  getAppCategories: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'categories']),
  getAppPurchases: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'purchases']),
  getAppPurchasedProducts: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'purchased-products']),
  getAppQrCodes: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'qr-codes']),
  getAppMedia: (tenantId: string, appId: string) =>
    route(tenantId, ['apps', appId, 'media']),

  getRealtimeStatus: (tenantId: string) =>
    ['', 'v2', 'realtime', 'latest-event', 'tenants', tenantId].join('/'),
  getRealtimeEvents: (tenantId: string) => 
    ['', 'v2', 'realtime', 'event', 'tenants', tenantId].join('/'),
  getInstallationMonitoringStatusHistory: (tenantId: string, installationId: string) =>
    route(tenantId, ['installations', installationId, 'monitoring']),
  getDeviceMonitoringStatusHistory: (tenantId: string, deviceId: string) =>
    route(tenantId, ['devices', deviceId, 'monitoring']),
};

export default routes;
