const libraryQueryKeys = {
  getQueryPrefix: () => {
    return 'content-library';
  },
  getContentLibraryMediaList: (
    tenantId: string,
    selectedFolderId = 'root',
    contentType = '',
    orientation = '',
    resolution = '',
    search = '',
    limit = 100,
    page = 1,
  ) => {
    return [
      libraryQueryKeys.getQueryPrefix(),
      'tenantId',
      tenantId,
      'selectedFolderId',
      selectedFolderId,
      'contentType',
      contentType,
      'orientation',
      orientation,
      'resolution',
      resolution,
      'search',
      search,
      'limit',
      limit,
      'page',
      page,
    ];
  },
  tenantContentLibraryMediaList: (tenantId: string) => {
    return [libraryQueryKeys.getQueryPrefix(), 'tenantId', tenantId];
  },
};

export default libraryQueryKeys;
