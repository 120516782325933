import { capitalize } from 'lodash';
import { OrientationTagValue } from '../../../../../store/common/content-tags/orientation';
import { Option } from '../../../../common/react-final-form';
import Media from '../../../../../store/types/media';

export enum ContentLibraryItemTypeEnum {
  IMAGE = 'image',
  VIDEO = 'video',
  FOLDER = 'folder',
  APP = 'app',
}

export const getContentLibraryItemTypeOptions = (): Option[] => {
  return Object.entries(ContentLibraryItemTypeEnum).map(([label, value]) => {
    return { label: capitalize(label), value };
  });
};

export const getOrientationOptions = (): Option[] => {
  return [
    { label: capitalize(OrientationTagValue.LANDSCAPE), value: 'horizontal' },
    { label: capitalize(OrientationTagValue.PORTRAIT), value: 'vertical' },
  ];
};

export enum ContentLibraryItemResolutionEnum {
  'HIGH DEFINITION' = 'hd',
  'LOW DEFINITION' = 'ld',
}

export const getResolutionOptions = (): Option[] => {
  return Object.entries(ContentLibraryItemResolutionEnum).map(([label, value]) => {
    return { label: capitalize(label), value };
  });
};

// returns vertical, horizontal or empty string when not able to determine orientation
export const extractImagePreset = (mediaItem: Media) => {
  if (
    mediaItem &&
    mediaItem.metadata &&
    mediaItem.metadata.videoAnalysisResult &&
    mediaItem.metadata.videoAnalysisResult.presets &&
    mediaItem.metadata.videoAnalysisResult.presets.length > 0
  ) {
    const preset = mediaItem.metadata.videoAnalysisResult.presets[0];
    return preset;
  }

  return '';
};

export const extractFilesFromDragDrop = (dataTransfer: DataTransfer): File[] => {
  const files: File[] = [];

  if (dataTransfer && dataTransfer.items) {
    const { items } = dataTransfer;

    for (let i = 0; i < items.length; i++) {
      const item: DataTransferItem = items[i];
      const file = item.getAsFile();
      if (file) {
        files.push(file);
      }
    }
  } else if (dataTransfer && dataTransfer.files) {
    for (let i = 0; i < dataTransfer.files.length; i++) {
      const file = dataTransfer.files[i];
      files.push(file);
    }
  }

  return files;
};

export const isAppsContentTypeSelected = (contentType: string | undefined) : boolean => {
  if (contentType) {
    const contentTypes = contentType.split(',');
    const appContentType = contentTypes.find((contentType) => contentType === ContentLibraryItemTypeEnum.APP);
    return appContentType ? true : false;
  }

  return false;
}
