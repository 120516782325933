import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Col, message, Row } from 'antd';
import useGoBack from '../../../../../common/use-go-back';
import {
  OrganizationUserApiValidationErrors,
} from './organization-user-form-fields.component';
import Message from '../../../../../common/message';
import {
  extractApiValidationErrors,
  getFieldNamesWithError,
  scrollIntoView,
} from '../../../../../common/react-final-form';
import { ApiError } from '../../../../../../services/api/api-error';
import Overlay from '../../../../../common/overlay/overlay.component';
import { Spinner } from '../../../../../common/spinner';
import { useOrganizationUserById } from '../../../../../../store/hooks/organization-users/use-organization-users-list';
import {
  ScopeLevelPickerSpaceValues,
  UseOrganizationUsersEditFormFieldsProps,
} from '../../../../../../store/hooks/organization-users/use-organization-users-edit';
import { FormContainer, BackToListButton, NotFound } from '../common';
import OrganizationUserFormFieldsComponent from './organization-user-form-fields.component';
import useOrganizationUsersEdit from '../../../../../../store/hooks/organization-users/use-organization-users-edit';
import arrayMutators from 'final-form-arrays';
import { StickyColumn } from '../../../queue-details/queue-setup/styled-block';
import OrganizationUserFormSidePanel from './organization-user-form-side-panel.component';
import { Form } from 'react-final-form';
import UserSpace from '../../../../../../store/types/user-space';
import {formatAllowedUserSpacesData} from '../../../../../../utils/user-space-utils';

interface OrganizationUserEditFormProps
  extends RouteComponentProps<{
    organisationId: string;
    userId: string;
  }> {
}

const getSpacesIds = (userSpaces: Partial<UserSpace>[], organizationId: string): {spaceIds: ScopeLevelPickerSpaceValues[], hasAccessToAllSpaces: boolean} => {
  const spaceIds: ScopeLevelPickerSpaceValues[] = [];
  const tenantSpace: Partial<UserSpace> | undefined = userSpaces.find(userSpace => userSpace.organizationId === organizationId);

  // return for not found is same as has access to all spaces to handle backward compatability (allow access to all spaces by default)
  if(!tenantSpace || tenantSpace.hasAccessToAllSpaces) {
    spaceIds.push({
      id: 'location',
      type: 'location'
    });
    return {spaceIds, hasAccessToAllSpaces: true};
  }

  if (Array.isArray(tenantSpace.spacesAccess) && tenantSpace.spacesAccess.length > 0) {
    tenantSpace.spacesAccess.forEach(spaceAccess => {
      if (spaceAccess.spaceId && spaceAccess.spaceId.id) {
        spaceIds.push({
          id: spaceAccess.spaceId.id,
          type: 'location'
        });
      }
    });
  }

  return {spaceIds, hasAccessToAllSpaces: false};
};

const OrganizationUserEditFormComponent = (props: OrganizationUserEditFormProps) => {
  const {
    match: {
      params: { organisationId, userId },
    },
  } = props;

  const { t } = useTranslation();

  const goBack = useGoBack();

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const fetchOrganizationUserState = useOrganizationUserById({ userId, organisationId });

  const {
    mutateAsync: updateOrganizationUser,
  } = useOrganizationUsersEdit();


  const initialValues = useMemo<UseOrganizationUsersEditFormFieldsProps>(() => {
    if (fetchOrganizationUserState.data) {
      const { spaceIds, hasAccessToAllSpaces } = getSpacesIds(fetchOrganizationUserState.data.userSpaces, organisationId);
      return {
        firstName: fetchOrganizationUserState.data.firstName || '',
        lastName: fetchOrganizationUserState.data.lastName || '',
        email: fetchOrganizationUserState.data.email || '',
        phoneNumber: fetchOrganizationUserState.data.phoneNumber || '',
        roleId: fetchOrganizationUserState.data.roleId || '',
        id: fetchOrganizationUserState.data.id || '',
        organizationId: organisationId || '',
        hasAccessToAllSpaces: hasAccessToAllSpaces,
        allowedSpaceIds: spaceIds
      };
    } else {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        roleId: '',
        id: '',
        organizationId: '',
        allowedSpaceIds: [],
        hasAccessToAllSpaces: true
      };
    }
  }, [fetchOrganizationUserState.data, organisationId]);

  const onFormSubmit = useCallback(
    async (values: Partial<UseOrganizationUsersEditFormFieldsProps>) => {
      const payload = formatAllowedUserSpacesData(values as UseOrganizationUsersEditFormFieldsProps);
      try {
        await updateOrganizationUser({
          userId,
          organisationId,
          payload,
        });
        message.success(<Message content={t('userManagement.editSuccess')} />);
        return {};
      } catch (error) {
        const validationErrors = extractApiValidationErrors<OrganizationUserApiValidationErrors>(error as ApiError);
        message.error(<Message content={t('userManagement.editFailed')} error={error as ApiError} />);
        return validationErrors;
      }

    },
    [
      t,
      organisationId,
      userId,
      updateOrganizationUser,
    ],
  );

  return (
    <>
      {fetchOrganizationUserState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {fetchOrganizationUserState.isSuccess && (
        <FormContainer>
          <BackToListButton type="default" icon="arrow-left" onClick={handleGoBack}>
            {t('back')}
          </BackToListButton>
          <Form
            onSubmit={onFormSubmit}
            keepDirtyOnReinitialize
            initialValues={initialValues}
            mutators={
              {
                ...arrayMutators,
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }
            }
            render={(
              {
                submitting: isSubmitting,
                handleSubmit,
                invalid: isFormInvalid,
                errors,
                form: formApi,
                dirty,
              },
            ) => {
              return (
                <form
                  onSubmit={(event) => {
                    handleSubmit(event);

                    const errorFieldNames = getFieldNamesWithError(errors);

                    if (!isFormInvalid || !errorFieldNames.length) {
                      return;
                    }

                    scrollIntoView(errorFieldNames[0]);
                  }}
                >
                  <Row gutter={{ md: 20, xl: 40 }}>
                    <Col md={24} xl={15}>
                      <OrganizationUserFormFieldsComponent
                        organizationId={organisationId}
                        description={t('userManagement.editUserFormDescription')}
                        title={t('userManagement.editUser')}
                        isEdit={true} />
                    </Col>
                    <StickyColumn md={24} xl={9}>
                      <OrganizationUserFormSidePanel
                        isSubmitEnabled={!isSubmitting}
                        isSubmitting={isSubmitting}
                        isDirty={dirty}
                      />
                    </StickyColumn>
                  </Row>
                </form>
              );
            }}
          />
        </FormContainer>
      )}

      {fetchOrganizationUserState.isError && (
        <NotFound>{t('userManagement.fetchFailed')}</NotFound>
      )}
    </>
  );
};

export default OrganizationUserEditFormComponent;