import React from 'react';
import { useStore } from 'easy-peasy';
import { RootState } from '../../../../store/models/root.model';
import OrganisationOverview from './organisation-overview.component';

type OrganisationOverviewContainerProps = Omit<
  React.ComponentProps<typeof OrganisationOverview>,
  'user'
>;

const OrganisationOverviewContainer = (props: OrganisationOverviewContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const { user, organisation } = useStore<RootState>(
    (state) => ({
      user: state.auth.user,
      organisation:
        (state.organisations.data && state.organisations.data[organisationId]) || null,
    }),
    [organisationId],
  );

  return <OrganisationOverview {...props} user={user} organisation={organisation} />;
};

export default OrganisationOverviewContainer;
