import pick from 'lodash/pick';
import set from 'lodash/set';
import get from 'lodash/get';
import moment from 'moment';
import Queue, {
  BookingsModeType,
  DayOfWeek,
  Languages,
  OpeningHoursPeriod,
  SpecialHoursPeriod,
} from '../../../../../store/types/queue';
import { capitalize } from './utils';
import { ValuesProps, WeekdayPeriods, WeekdaySpecialPeriods } from './types';
import getCustomizableTextsValue from './get-customizable-texts-value';

const DEFAULT_MAX_NUMBER_OF_PEOPLE_PER_TICKET = 1;

const buildPeriods = <T>(hoursPeriods: T[]): { periods: T[] } => ({
  periods: hoursPeriods,
});

const transformHoursToPeriods = (
  values: ValuesProps,
  hoursKey: 'bookingHours' | 'regularHours' | 'curbsidePickupHours',
): OpeningHoursPeriod[] => {
  if (!values || !values[hoursKey]) {
    return [];
  }

  const result: OpeningHoursPeriod[] = [];
  const hoursConfiguration: WeekdayPeriods = values[hoursKey] || {};

  Object.keys(hoursConfiguration).forEach((key) => {
    if (hoursConfiguration[key].enabled) {
      const { periods } = hoursConfiguration[key];
      periods.forEach((period) => {
        const builtPeriod: OpeningHoursPeriod = {
          openDay: (DayOfWeek as any)[capitalize(key)],
          closeDay: (DayOfWeek as any)[capitalize(key)],
          openTime: period.from,
          closeTime: period.to,
          capacity:
            period.capacity == null || period.capacity === ''
              ? undefined
              : parseInt(period.capacity, 10),
        };
        result.push(builtPeriod);
      });
    }
  });

  return result;
};

const transformSpecialHoursToPeriods = (
  values: ValuesProps,
  hoursKey: 'specialHours' | 'specialBookingHours',
): SpecialHoursPeriod[] => {
  if (!values || !values[hoursKey]) {
    return [];
  }

  const result: SpecialHoursPeriod[] = [];
  const hoursConfiguration: WeekdaySpecialPeriods = values[hoursKey] || { periods: [] };

  hoursConfiguration.periods.forEach((period) => {
    const { date } = period;
    const dayName = moment(date).format('dddd');
    const builtPeriod: SpecialHoursPeriod = {
      openDay: (DayOfWeek as any)[capitalize(dayName)],
      closeDay: (DayOfWeek as any)[capitalize(dayName)],
      openTime: period.from,
      closeTime: period.to,
      capacity:
        period.capacity == null || period.capacity === ''
          ? undefined
          : parseInt(period.capacity, 10),
      date,
    };
    result.push(builtPeriod);
  });

  return result;
};

const getIntValue = (
  value: string | undefined,
  defValue: number | null = null,
): number | null => {
  return value ? parseInt(value, 10) : defValue;
};

export type QueueWithoutExtraProperties = Pick<
  Queue,
  | 'status'
  | 'location'
  | 'title'
  | 'displayTitle'
  | 'pin'
  | 'shortDisplayName'
  | 'region'
  | 'locale'
  | 'timeZone'
  | 'logoUrl'
  | 'globalStyles'
  | 'defaultPhoneNumberCountry'
  | 'phoneNumberFrom'
  | 'phoneNumberCountries'
  | 'addressLines'
  | 'leaveAfterMinutes'
  | 'allowEntryAboveCapacity'
  | 'webPushEnabled'
  | 'applePassEnabled'
  | 'smsEnabled'
  | 'emailEnabled'
  | 'emailFrom'
  | 'registrationEnabled'
  | 'printingManualTicketsEnabled'
  | 'autoCheckOutByNextEnabled'
  | 'registrationFields'
  | 'phoneNumber'
  | 'curbsideLogoUrl'
  | 'isEmailLogin'
  | 'isInStore'
  | 'emailsAllowed'
  | 'timeslotDuration'
  | 'timeslotCapacity'
  | 'regularHours'
  | 'bookingHours'
  | 'curbsidePickupHours'
  | 'specialHours'
  | 'storeCapacity'
  | 'maximumStoreCapacity'
  | 'leaveReminderNotificationAfterMinutes'
  | 'bookingReminderNotificationBeforeMinutes'
  | 'notifyExtraPendingPositionOffset'
  | 'curbsidePickupArrivalWindowCapacity'
  | 'curbsidePickupArrivalWindowDuration'
  | 'textInAppCustomization'
  | 'automaticallyClearQueueTime'
  | 'availableTagsForQueue'
  | 'customFields'
  | 'bookingsMode'
  | 'checkInByQRCode'
  | 'emailsForTeamsCurbsideNotifications'
  | 'webHooks'
  | 'numberOfAvailableBookingsPerDay'
  | 'maxNumberOfPeoplePerWalkInTicket'
  | 'maxNumberOfPeoplePerBookingTicket'
  | 'staffNotifications'
  | 'booking'
  | 'ticketFlowModifiers'
  | 'specialBookingHours'
  | 'isAutoCallEnabled'
  | 'maxOccupancyIncreasingWithSensors'
  | 'isPositionWaitingTimeVisible'
  | 'isNumberInTheQueueVisible'
  | 'removedAt'
  | 'categories'
  | '_etag'
  | 'syncId'
  | 'digitalCall'
  | 'autoCheckOutAfterMinutes'
  | 'gridAppProperties'
  | 'isKeepDistanceInfoEnabled'
>;

export default function convertFormValues(
  values: ValuesProps,
): QueueWithoutExtraProperties {
  // transform hours to the same format as on backend
  const regularPeriods: OpeningHoursPeriod[] = transformHoursToPeriods(
    values,
    'regularHours',
  );
  const specialHours: SpecialHoursPeriod[] = transformSpecialHoursToPeriods(
    values,
    'specialHours',
  );
  const bookingPeriods: OpeningHoursPeriod[] = transformHoursToPeriods(
    values,
    'bookingHours',
  );
  const specialBookingHours: SpecialHoursPeriod[] = transformSpecialHoursToPeriods(
    values,
    'specialBookingHours',
  );
  const curbsidePickupHours: OpeningHoursPeriod[] = transformHoursToPeriods(
    values,
    'curbsidePickupHours',
  );
  // we will take this values without extra checking for compatibilities between form and original queue types
  // you need to omit property form here or add with some special check below
  // the simple rule - all inputs have string type for form, but some of them have number type in the Queue type

  // values restricted on backend
  // 'id', 'description', 'organization', 'open'
  // queue specific properties
  // 'seperateBookingHours',  'seperateCurbsidePickupHours',
  const formQueue: QueueWithoutExtraProperties = {
    ...{
      defaultPhoneNumberCountry: 'SE',
      title: '',
      pin: '',
      region: '',
      timeZone: '',
      // default properties. Without this hacky way with undefineds you'll get an error like
      // can't cast string | undefined to number | undefined. We'll cast them below
      phoneNumberCountries: undefined as any,
      timeslotDuration: undefined as any,
      timeslotCapacity: undefined as any,
      leaveAfterMinutes: undefined as any,
      leaveReminderNotificationAfterMinutes: undefined as any,
      bookingReminderNotificationBeforeMinutes: undefined as any,
      addressLines: ['', ''] as any,
      registrationFields: undefined as any,
      emailsAllowed: undefined as any,
      textInAppCustomization: {} as any,
      storeCapacity: undefined as any,
      maximumStoreCapacity: undefined as any,
      notifyExtraPendingPositionOffset: undefined as any,
      curbsidePickupArrivalWindowCapacity: undefined as any,
      curbsidePickupArrivalWindowDuration: undefined as any,
      automaticallyClearQueueTime: undefined as any,
      availableTagsForQueue: undefined as any,
      customFields: undefined as any,
      bookingsMode: BookingsModeType.DEFAULT,
      numberOfAvailableBookingsPerDay: 0 as any,
      booking: undefined as any,
      staffNotifications: undefined as any,
      digitalCall: undefined as any,
      autoCheckOutAfterMinutes: undefined as any,
    },
    ...pick(values, [
      'categories',
      'status',
      'location',
      'title',
      'displayTitle',
      'pin',
      'shortDisplayName',
      'region',
      'locale',
      'timeZone',
      'logoUrl',
      'globalStyles',
      'defaultPhoneNumberCountry',
      'phoneNumberFrom',
      'addressLines',
      'allowEntryAboveCapacity',
      'webPushEnabled',
      'applePassEnabled',
      'smsEnabled',
      'emailEnabled',
      'emailFrom',
      'registrationEnabled',
      'printingManualTicketsEnabled',
      'autoCheckOutByNextEnabled',
      'registrationFields',
      'phoneNumber',
      'curbsideLogoUrl',
      'isEmailLogin',
      'isInStore',
      'emailsAllowed',
      'automaticallyClearQueueTime',
      'featureList',
      'availableTagsForQueue',
      'customFields',
      'bookingsMode',
      'notifyExtraPendingPositionOffset',
      'checkInByQRCode',
      'webHooks',
      'stations',
      'feedback',
      'staffNotifications',
      'booking',
      'positionLabelPrefixes',
      'ticketFlowModifiers',
      'externalId',
      'isAutoCallEnabled',
      'maxOccupancyIncreasingWithSensors',
      'isPositionWaitingTimeVisible',
      'isNumberInTheQueueVisible',
      'removedAt',
      '_etag',
      'syncId',
      'digitalCall',
      'autoCheckOutAfterMinutes',
      'isKeepDistanceInfoEnabled',
      'gridAppProperties',
    ]),
    categories: values.categories,
    regularHours: buildPeriods<OpeningHoursPeriod>(regularPeriods),
    bookingHours: values.seperateBookingHours
      ? buildPeriods<OpeningHoursPeriod>(bookingPeriods)
      : null,
    specialBookingHours:
      values.specialBookingHours && specialBookingHours && specialBookingHours.length
        ? buildPeriods<SpecialHoursPeriod>(specialBookingHours)
        : undefined,
    specialHours:
      values.specialHours && specialHours && specialHours.length
        ? buildPeriods<SpecialHoursPeriod>(specialHours)
        : undefined,
    curbsidePickupHours: values.seperateCurbsidePickupHours
      ? buildPeriods<OpeningHoursPeriod>(curbsidePickupHours)
      : null,
    emailsForTeamsCurbsideNotifications: values.emailsForTeamsCurbsideNotifications
      ? values.emailsForTeamsCurbsideNotifications
      : [],
    webHooks: values.webHooks ? values.webHooks : {},
    maxNumberOfPeoplePerWalkInTicket: values.maxNumberOfPeoplePerWalkInTicket
      ? parseInt(values.maxNumberOfPeoplePerWalkInTicket, 10)
      : DEFAULT_MAX_NUMBER_OF_PEOPLE_PER_TICKET,
    maxNumberOfPeoplePerBookingTicket: values.maxNumberOfPeoplePerBookingTicket
      ? parseInt(values.maxNumberOfPeoplePerBookingTicket, 10)
      : DEFAULT_MAX_NUMBER_OF_PEOPLE_PER_TICKET,
    autoCheckOutAfterMinutes: values.autoCheckOutAfterMinutes
      ? values.autoCheckOutAfterMinutes
      : null,
    phoneNumberFrom: values.phoneNumberFrom ? values.phoneNumberFrom : null,
    gridAppProperties: values.gridAppProperties ? values.gridAppProperties : undefined,
    displayTitle: values.displayTitle ? values.displayTitle : { [Languages.en]: '' },
  };

  // add initialization for your filed here
  if (values.featureList.includes('OCCUPANCY')) {
    formQueue.storeCapacity = parseInt(values.storeCapacity, 10);
    if (values.maximumStoreCapacity && values.maximumStoreCapacity.length) {
      formQueue.maximumStoreCapacity = parseInt(values.maximumStoreCapacity, 10);
    }
    formQueue.allowEntryAboveCapacity = !!values.allowEntryAboveCapacity;
  }

  if (values.featureList.includes('QUEUE')) {
    formQueue.webPushEnabled = !!values.webPushEnabled;
    formQueue.applePassEnabled = !!values.applePassEnabled;
    formQueue.smsEnabled = !!values.smsEnabled;
    formQueue.emailEnabled = !!values.emailEnabled;
    formQueue.registrationEnabled = !!values.registrationEnabled;
    formQueue.printingManualTicketsEnabled = !!values.printingManualTicketsEnabled;
    formQueue.autoCheckOutByNextEnabled =
      values.isInStore && values.isEmailLogin ? values.autoCheckOutByNextEnabled : false;

    if (values.smsEnabled) {
      if (values.shortDisplayName) {
        formQueue.shortDisplayName = values.shortDisplayName;
      }

      formQueue.defaultPhoneNumberCountry = values.defaultPhoneNumberCountry;
      formQueue.phoneNumber = values.phoneNumber ? values.phoneNumber : '';
      formQueue.leaveAfterMinutes = getIntValue(values.leaveAfterMinutes);
      formQueue.leaveReminderNotificationAfterMinutes = getIntValue(
        values.leaveReminderNotificationAfterMinutes,
      );
      formQueue.phoneNumberCountries =
        values.phoneNumberCountries && values.phoneNumberCountries.length
          ? values.phoneNumberCountries
          : null;
    }
    formQueue.notifyExtraPendingPositionOffset =
      parseInt(values.notifyExtraPendingPositionOffset, 10) || 1;
    formQueue.checkInByQRCode = !!values.checkInByQRCode;

    if (values.staffNotifications) {
      formQueue.staffNotifications = {
        isEnabled: values.staffNotifications.isEnabled,
        emailList: values.staffNotifications.emailList || [],
        thresholdWaitingTime: getIntValue(values.staffNotifications.thresholdWaitingTime),
        thresholdPeopleCount: getIntValue(values.staffNotifications.thresholdPeopleCount),
        sendInterval: getIntValue(values.staffNotifications.sendInterval),
      };
    }

    if (values.digitalCall) {
      formQueue.digitalCall = {
        isEnabled: values.digitalCall.isEnabled,
        agentBaseUrl: values.digitalCall.agentBaseUrl,
        apiToken: values.digitalCall.apiToken,
        customerUrlTemplate: values.digitalCall.customerUrlTemplate,
        queueType: values.digitalCall.queueType,
        globalQueueId: values.digitalCall.globalQueueId,
        routingTimeout: getIntValue(values.digitalCall.routingTimeout) || undefined,
      };
    }
  }

  if (values.featureList.includes('BOOKINGS')) {
    formQueue.timeslotDuration = parseInt(values.timeslotDuration, 10) * 60000;
    formQueue.timeslotCapacity = parseInt(values.timeslotCapacity, 10);
    formQueue.bookingReminderNotificationBeforeMinutes = getIntValue(
      values.bookingReminderNotificationBeforeMinutes,
    );

    // set address lines. '' is default value and must be provided
    const addressLineFirst =
      values.addressLines && values.addressLines[0] ? values.addressLines[0] : '';
    const addressLineSecond =
      values.addressLines && values.addressLines[1] ? values.addressLines[1] : '';
    formQueue.addressLines = [addressLineFirst, addressLineSecond];

    formQueue.numberOfAvailableBookingsPerDay = values.numberOfAvailableBookingsPerDay
      ? parseInt(values.numberOfAvailableBookingsPerDay, 10)
      : undefined;

    if (formQueue.booking && values.booking) {
      formQueue.booking.physicalBooking.enabled = get(
        values,
        'booking.digitalBookingWithMsTeams.enabled',
      )
        ? get(values, 'booking.physicalBooking.enabled')
        : true;
    }

    if (
      formQueue.booking &&
      values.booking &&
      values.booking.isQueueRegistrationFormUsed
    ) {
      formQueue.booking.registrationFields = values.registrationFields;
    }
  }

  if (values.featureList.includes('CURBSIDEPICKUP')) {
    formQueue.curbsidePickupArrivalWindowDuration =
      parseInt(values.curbsidePickupArrivalWindowDuration, 10) * 60000; // to ms
    formQueue.curbsidePickupArrivalWindowCapacity = parseInt(
      values.curbsidePickupArrivalWindowCapacity,
      10,
    );
    if (values.curbsideLogoUrl) {
      formQueue.curbsideLogoUrl = values.curbsideLogoUrl;
    }
    if (values.curbsidePickupHours && curbsidePickupHours.length) {
      formQueue.curbsidePickupHours = {
        periods: curbsidePickupHours,
      };
    }
  }
  // ------ initialization end ---

  // clean up Invalid dates from auto reset
  formQueue.automaticallyClearQueueTime =
    values.automaticallyClearQueueTime && values.automaticallyClearQueueTime.length
      ? values.automaticallyClearQueueTime.filter(
          (time: string) => time !== 'Invalid date',
        )
      : null;

  // ---------- Text customization -------------
  set(formQueue, 'textInAppCustomization', {
    ...formQueue.textInAppCustomization,
    ...getCustomizableTextsValue(values.textInAppCustomization),
  });
  // ---------- end Text customization -------------
  return formQueue;
}
