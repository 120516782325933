const segmentQueryKeys = {
  getQueryPrefix: () => {
    return 'content-segment';
  },
  getSegmentsList: (tenantId: string, channelId: string, limit = 1000, page = 1) => {
    return [segmentQueryKeys.getQueryPrefix(), 'tenantId', tenantId, 'channelId', channelId, 'limit', limit, 'page', page];
  },
  getSegment: (segmentId: string) => {
    return [segmentQueryKeys.getQueryPrefix(), 'segmentId', segmentId];
  },
  channelSegments: (tenantId: string, channelId: string) => {
    return [segmentQueryKeys.getQueryPrefix(), 'tenantId', tenantId, 'channelId', channelId];
  },
};

export default segmentQueryKeys;
