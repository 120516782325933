import Plan from './organisation-plan';
import SlugGeneration from './slug-generation';
import OrganisationBillingType from './organisation-billing-type.enum';
import LocalizedField from './localized-field';
import { VoucherType } from './voucher';
import Address from './address';

export enum BillingCurrencyEnum {
  SEK = 'SEK',
  USD = 'USD',
  EUR = 'EUR',
}

export enum DataResidencyEnum {
  EU = 'EU',
  US = 'US',
  UAE = 'UAE',
  AU = 'AU',
  IN = 'IN',
  QA = 'QA',
  DEV = 'DEV',
  LOCAL = 'LOCAL',
}

export interface OrganizationPlans {
  id: string;
  supportPlan: Plan | null;
  tenantPlan: Plan | null;
}

export interface CurrentPlanSubscription {
  id?: string;
  planId: string;
  planData?: { name: LocalizedField };
  subscribedAt: string;
  billingDay: number;
}

export interface ScheduledPlanSubscription {
  planId: string;
}

export interface PlanSubscription {
  current: CurrentPlanSubscription;
  scheduled?: ScheduledPlanSubscription;
}

export default interface Organisation {
  authSecret: string;
  country: string;
  currency?: string;
  createdAt: string;
  displayName: string;
  environments: string[];
  id: string;
  organizationName: string;
  updatedAt: string;
  url: string;
  apps: string[];
  dataResidency: DataResidencyEnum;
  timezone: string;
  isPartnerOrganization: boolean;
  parentOrganizationId: string;
  customOverviewDashboard?: string;
  generateSlug?: boolean;
  hasActiveSubscription: boolean;
  externalId?: number;
  billingType?: OrganisationBillingType;
  csmEmail?: string;
  salesEmail?: string;
  billingContactEmail?: string;
  billingAddress?: Address;
  planSubscriptions: {
    tenant: PlanSubscription;
    support: PlanSubscription;
  };
  vatId?: string;
  enabled: boolean;
  restrictUserEmailDomains: boolean;
  allowedUserEmailDomains: string[];
}

export interface OrganizationAccountBalanceEntries {
  order: number;
  type: VoucherType | 'committed_consumption';
  typeDesc: string;
  codes: string[];
  amountTotal: number;
  amountLeft: number;
  currency: BillingCurrencyEnum;
  validity: LocalizedField;
}

export interface OrganizationAccountBalance {
  id: string;
  currency: BillingCurrencyEnum;
  currentMonetaryBalance: number;
  currentSponsorshipBalance: number;
  isSponsorshipAccountActive: boolean;
  totalCredits: number;
  remainingCredits: number;
  entries: OrganizationAccountBalanceEntries[];
  message?: {
    type: 'warning' | 'info' | 'success' | 'danger';
    text: LocalizedField;
  };
}

export interface OrganisationWithSlugGeneration extends Organisation {
  slug: SlugGeneration;
}
