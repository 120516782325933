import React, { useCallback, useMemo, useState } from 'react';
import {
  DataMatrixDataSource,
  GridStyles,
  DownloadFileColumn,
  SpaceDataSourceType,
} from '@ombori/grid-reports';
import { useParams } from 'react-router';
import { Button, message, Table } from 'antd';
import { Link } from 'react-router-dom';
import { snakeCase } from 'lodash';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import CardContainer from '../../card-container';
import UniversalDevice from '../../../../../../store/types/universal-device';
import { useTranslation } from 'react-i18next';
import CardTitleDownloadButtonWrapper from '../../card-title-download-button-wrapper';
import { convertToCSVFromObject, getCsvDataFileName } from '../../../../../../utils/convert-csv';
import useDeviceDataMatrixData, { DeviceDataMatrixDataRow } from '../data-matrix-hooks/use-device-data-matrix-data';
import { prepareDateRangeSearchParams } from '../../../utils';
import { TitleWithSelect } from '../common/data-matrix-common.component';
import Message from '../../../../message';
import downloadCSV from '../../../../../../utils/download-csv';
import * as Sentry from '@sentry/browser';

interface DataMatrixProps {
  tenantId: string;
  installationId?: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  title?: string;
  dataSource: DataMatrixDataSource;
  dataSourceType?: SpaceDataSourceType;
  downloadFileColumns?: DownloadFileColumn[];
  tableColumns: DownloadFileColumn[];
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

const DevicesDataMatrixV1: React.FC<Omit<DataMatrixProps, 'dataSource'>> = React.memo(({
                                                                                         tenantId,
                                                                                         dateFrom,
                                                                                         dateTo,
                                                                                         dataResidency,
                                                                                         title = '',
                                                                                         downloadFileColumns,
                                                                                         tableColumns,
                                                                                         gridStyles,
                                                                                         isVisibleWithoutData,
                                                                                       }) => {
  const { t } = useTranslation();

  const [selectedDevice, setSelectedDevice] = useState<UniversalDevice | undefined>(
    undefined,
  );

  const { spaceId, appId: installationId } = useParams<{
    spaceId?: string;
    appId?: string;
  }>();

  const deviceMatrixState = useDeviceDataMatrixData({
    tenantId,
    spaceId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    columns: tableColumns && tableColumns.length > 0 ? tableColumns : [],
    downloadColumns: downloadFileColumns && downloadFileColumns.length > 0
      ? downloadFileColumns
      : [],
  });

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const newTableColumns = useMemo(() => {
    if (!tableColumns || !deviceMatrixState.data) {
      return [];
    }

    const staticColumn = [
      {
        title: t('device'),
        key: 'device',
        sorter: (source: DeviceDataMatrixDataRow, target: DeviceDataMatrixDataRow) => {
          return source.device.deviceName.localeCompare(target.device.deviceName);
        },
        render: (record: DeviceDataMatrixDataRow) => {
          return (
            <Button type="link">
              <Link
                to={{ pathname: `/organisations/${tenantId}/devices/v3/${record.device.id}/overview`, search: `${searchParams}` }}
              >
                {record.device.deviceName}
              </Link>
            </Button>
          );
        },
      },
    ];

    const otherColumns = tableColumns.map((tableColumn, index) => {
      const title = tableColumn.column.title || `Column ${index + 1}`;
      return {
        title,
        key: title,
        dataIndex: `columns.${title}`,
        sorter: (source: DeviceDataMatrixDataRow, target: DeviceDataMatrixDataRow) => {
          if (typeof source.columns[title] === 'number') {
            return (source.columns[title] as number) - (target.columns[title] as number);
          }

          return (source.columns[title] as string).localeCompare(target.columns[title] as string);
        },
      }
    });

    return [...staticColumn, ...otherColumns];

  }, [tableColumns, searchParams, tenantId, t, deviceMatrixState.data]);

  const newTableColumnsData = useMemo(() => {
    if (!tableColumns || !deviceMatrixState.data) {
      return [];
    }

    if (selectedDevice) {
      return deviceMatrixState.data.tableData
        .filter((dataItem) => dataItem.device.id === selectedDevice.id);
    }

    return deviceMatrixState.data.tableData;
  }, [tableColumns, deviceMatrixState, selectedDevice]);

  const onSelect = useCallback(
    (key: string | undefined) => {
      let selectedDevice: UniversalDevice | undefined;

      if (key === 'all' || !key) {
        selectedDevice = undefined;
      } else {
        selectedDevice = deviceMatrixState.devices.find((device) => device.id === key);
      }

      setSelectedDevice(selectedDevice);
    },
    [setSelectedDevice, deviceMatrixState.devices],
  );

  const deviceOptions = useMemo(() => {
    return deviceMatrixState.data
      ? deviceMatrixState.data.tableData.map((deviceDataRow) => {
        return { key: deviceDataRow.key, label: deviceDataRow.device.deviceName };
      })
      : [];
  }, [deviceMatrixState]);

  const {
    hasData,
    showDownloadButton,
  } = useMemo(() => {
    return {
      hasData: !!deviceMatrixState.data && deviceMatrixState.data.tableData.length > 0,
      showDownloadButton: !!deviceMatrixState.data && deviceMatrixState.data.downloadData.length > 0,
    }
  }, [deviceMatrixState.data]);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownloadButtonClick = () => {
    try {
      setIsDownloading(true);
      let csvData = '';
      const dataExportFileName = getCsvDataFileName({ dateFrom, dateTo, title });

      if (!deviceMatrixState.data || deviceMatrixState.data.downloadData.length === 0) {
        csvData = '';
      } else {
        const data = deviceMatrixState.data.downloadData;
        const columns = data.map((dataItem) => {
          return {
            'Date From': dateFrom,
            'Date To': dateTo,
            'Space': dataItem.space ? dataItem.space.displayName : '',
            'Device': dataItem.device.deviceName,
            ...dataItem.columns,
          }
        });

        csvData = convertToCSVFromObject(columns as Record<string, string>[]);
      }

      if (csvData === '') {
        message.warning(<Message content={t('fileDownload.noDataToDownload')} />);
      } else {
        downloadCSV(csvData, dataExportFileName ? dataExportFileName : 'download-report.csv');
        message.success(<Message content={t('fileDownload.downloadStart')} />);
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(<Message content={(t('fileDownload.downloadFailure'))} />);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <CardContainer
      isLoading={deviceMatrixState.isLoading}
      isSuccess={deviceMatrixState.isSuccess}
      isError={deviceMatrixState.isError}
      hasData={hasData}
      title={(
        <CardTitleDownloadButtonWrapper
          isDownloadButtonVisible={showDownloadButton}
          onDownloadButtonClicked={handleDownloadButtonClick}
          isDownloading={isDownloading}
        >
          {deviceMatrixState.isSuccess ? (
            <TitleWithSelect title={title} items={deviceOptions} onSelect={onSelect} />
          ) : (
            title
          )}
        </CardTitleDownloadButtonWrapper>
      )}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {tableColumns && newTableColumnsData && (
        <Table
          columns={newTableColumns}
          dataSource={newTableColumnsData}
          size="small"
          pagination={{
            pageSize: 5,
          }}
          scroll={{ x: 'max-content' }}
        />
      )}
    </CardContainer>
  );
});

export default DevicesDataMatrixV1;