import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Typography, Col } from 'antd';
import styled from '@emotion/styled';
import {
  Input,
  SelectProps,
  Option,
  validateCharacterLength,
  Select,
  Validator,
  validateByRegExPattern,
  optionalCharacterLength,
  validateOptionalStandardName,
} from '../../../../../common/react-final-form';
import { Description, PanelCard } from '../users-common.component';
import { Divider } from '../common';
import { Field } from 'react-final-form';
import { useActions, useStore } from 'easy-peasy';

import { passwordPattern } from '../../../../../../utils/validation/password';
import {
  isValidPhoneNumberInput,
} from '../../../../../common/schema-form/widgets/phone-number-input/phone-number-input.component';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { requiredNumber } from '../../../queue-details/queue-setup/validate-form';
import PhoneInput from 'react-phone-number-input';
import InputWrap from '../../../../../common/react-final-form/input-wrap';
import { guessClientCountrySync } from '../../../../../../utils/client-location';
import ScopeLevelPicker, { ScopeType } from '../../../alerts/alert-rule/scope-level-picker.component';
import { isProd } from '../../../../../../utils/env';
import {
  ScopeLevelPickerSpaceValues,
} from '../../../../../../store/hooks/organization-users/use-organization-users-edit';

interface OrganizationUserFormFieldsProps {
  title: string;
  description: string;
  isEdit?: boolean;
  organizationId: string;
}

export interface OrganizationUserApiValidationErrors {
  email?: string;
  allowedSpaceIds?: string;
}

const PhoneAdapter = ({ input, meta, isDisabled }: any) => {
  const { t } = useTranslation();
  const guessedDefaultCountry = guessClientCountrySync();

  return (
    <InputWrap
      label={t('phoneNumberAlt')}
      error={meta.error && meta.touched ? meta.error : null}
      className={meta.error && meta.touched ? 'has-error' : ''}>
      <PhoneInputStyled
        international
        defaultCountry={guessedDefaultCountry}
        disabled={isDisabled}
        value={input.value}
        onChange={value => input.onChange(value ? value : '')}
      />
    </InputWrap>
  );
};


const OrganizationUserFormFields = (props: OrganizationUserFormFieldsProps) => {
  const {
    title,
    description,
    isEdit = false,
    organizationId,
  } = props;

  const {
    fetchUserRoles,
  } = useActions<RootModel>((actions) => ({
    fetchUserRoles: actions.userRoles.fetch,
  }));

  const phoneNumberValidator = (value: string) => {
    if (value) {
      return (isValidPhoneNumberInput({ value }) ? undefined : t('invalidPhoneNumber'));
    }

    return undefined;
  };

  const userSpaceValidator = (value: ScopeLevelPickerSpaceValues[]) => {
    if (value.length === 0) {
      return t('userManagement.spaceValidationError')
    }
    return undefined;
  };

  const { userRoles } = useStore<
    RootState
  >((state) => ({
    userRoles: state.userRoles.data,
  }));


  const userRoleOptions = useMemo<SelectProps['options']>(
    () => {
      let formattedRoles: Option[] = [];
      if (userRoles[organizationId] && Object.keys(userRoles[organizationId]).length > 0) {
        Object.keys(userRoles[organizationId]).forEach((role: string) => {
          formattedRoles.push({
            label: userRoles[organizationId][role].displayName,
            value: userRoles[organizationId][role].id,
          });
        });
      }
      return formattedRoles;
    },
    [userRoles, organizationId],
  );

  const { t } = useTranslation();
  const roleRequiredValidator = requiredNumber as Validator<string | number>;

  useEffect(() => {
    fetchUserRoles({ organizationId: organizationId });
  }, [fetchUserRoles, organizationId]);

  return (
    <>
      <Row>
        <Col>
          <PanelCard>
            <Row>
              <FormTitle level={3}>
                {title}
              </FormTitle>
              <Description>{description}</Description>
            </Row>
            <Divider />
            <StyledRow>
              <Input
                name="firstName"
                label={t('firstName')}
                placeholder={isEdit ? '' : t('userManagement.addUserFirstNamePlaceholder')}
                disabled={isEdit}
                validators={[optionalCharacterLength(2, 100), validateOptionalStandardName]}
              />
            </StyledRow>
            <StyledRow>
              <Input
                name="lastName"
                label={t('lastName')}
                placeholder={isEdit ? '' : t('userManagement.addUserLastNamePlaceholder')}
                disabled={isEdit}
                validators={[optionalCharacterLength(2, 100), validateOptionalStandardName]}
              />
            </StyledRow>
            <StyledRow>
              <Field
                name="phoneNumber"
                component={PhoneAdapter}
                isDisabled={isEdit}
                validate={phoneNumberValidator} />
            </StyledRow>
            <StyledRow>
              <Input
                name="email"
                label={t('email')}
                placeholder={t('userManagement.addUserEmailPlaceholder')}
                isRequired
                type="email"
                disabled={isEdit}
                validators={[validateCharacterLength(1, 100)]}
              />
            </StyledRow>
            <StyledRow>
              <Select
                isRequired
                name="roleId"
                label={`${t('role')}`}
                validators={[roleRequiredValidator]}
                options={userRoleOptions}
                isSearchEnabled={false}
              />
            </StyledRow>
            {!isProd() &&
              <StyledRow>
                <Field
                  name="allowedSpaceIds"
                  validate={userSpaceValidator}
                  render={({ input, meta }) => (
                    <InputWrap
                      label={t('space')}
                      isRequired={true}
                      error={(meta.error || meta.submitFailed) && meta.touched ? meta.error : null}
                      className={(meta.error || meta.submitFailed) && meta.touched ? 'has-error' : ''}
                    >
                      <ScopeLevelPicker
                        scope={[ScopeType.LOCATION]}
                        initialScopes={input.value}
                        tenantId={organizationId}
                        onChange={(values) => {
                          input.onChange(({
                            target: {
                              value: values,
                            },
                          } as unknown) as ChangeEvent<string>);
                        }}
                      />
                    </InputWrap>
                  )}
                />
              </StyledRow>
            }
            {!isEdit &&
              <StyledRow>
                <InputWrap hint={t('passwordHint')} label={t('password')}>
                  <Input
                    name="password"
                    placeholder={t('userManagement.addUserPasswordPlaceholder')}
                    isRequired
                    type="password"
                    validators={[validateByRegExPattern(passwordPattern, t('passwordHint'))]}
                  />
                </InputWrap>
              </StyledRow>
            }
          </PanelCard>
        </Col>
      </Row>
    </>
  );
};


const FormTitle = styled(Typography.Title)`
  margin-bottom: 8px !important;
`;

const StyledRow = styled(Row)`
  margin: 1.7em 0;
`;

const PhoneInputStyled = styled(PhoneInput)`
  .PhoneInputInput {
    border-width: 1px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.1) !important;
    padding: 0.3em 0.5em;
  }
`;

export default OrganizationUserFormFields;
