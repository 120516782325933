import { action, Action, select, Select, thunk, Thunk } from 'easy-peasy';
import { set } from 'lodash';
import OrganisationApp from '../../types/organisation-app';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';

export const routes = {
  getApps: () => ['', 'api', 'installations'].join('/'),
  getApp: (appId: string) => ['', 'api', 'apps', appId].join('/'),
  updateApp: (appId: string) => ['', 'api', 'installations', appId].join('/'),
  installApp: () => ['', 'api', 'installations'].join('/'),
  deleteApp: (appId: string) => ['', 'api', 'installations', appId].join('/'),
  updateAndCreateRelease: (appId: string) => ['', 'api', 'v2', 'installations', appId].join('/'),
  enableLayoutV2: (appId: string) => ['', 'api', 'apps', appId, 'enableLayoutV2'].join('/')
};

export interface AppInstallBody {
  packageName: string;
  displayName: string;
  organizationId: string;
  installationGroupId: string;
}

export interface OrganisationAppsModel {
  data: {
    [organisationId: string]: {
      [appId: string]: OrganisationApp;
    };
  } | null;
  error: ApiError | null;
  loading: boolean;
  loaded: Select<OrganisationAppsModel, boolean>;
  values: Select<OrganisationAppsModel, (organisationId: string) => OrganisationApp[]>;
  fetch: Thunk<OrganisationAppsModel, { silent?: boolean }, Injections>;
  setData: Action<OrganisationAppsModel, OrganisationApp[]>;
  setLoading: Action<OrganisationAppsModel, boolean>;
  setError: Action<OrganisationAppsModel, ApiError | null>;
  setSingle: Action<OrganisationAppsModel, OrganisationApp>;
  unsetSingle: Action<OrganisationAppsModel, { appId: string; organisationId: string }>;
  fetchSingle: Thunk<OrganisationAppsModel, { appId: string }, Injections>;
  update: Thunk<
    OrganisationAppsModel,
    Partial<OrganisationApp> & { id: string },
    Injections
  >;
  updateAndCreateRelease: Thunk<
    OrganisationAppsModel,
    Partial<OrganisationApp> & { id: string; autoDeployEnvironment: string },
    Injections
  >;
  enableLayoutV2: Thunk<
    OrganisationAppsModel,
    { id: string; enable: boolean },
    Injections
  >;
  delete: Thunk<
    OrganisationAppsModel,
    Partial<OrganisationApp> & { id: string; organizationId: string },
    Injections
  >;
  install: Thunk<OrganisationAppsModel, AppInstallBody, Injections>;
}

const organisationAppsModel: OrganisationAppsModel = {
  data: null,
  error: null,
  loading: false,
  loaded: select((state) => !!state.data && !!state.data && !state.loading),
  values: select((state) => (organisationId: string) =>
    Object.values((state.data && state.data[organisationId]) || {}).sort((a, b) =>
      a.displayName.localeCompare(b.displayName),
    ),
  ),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setData: action((state, data) => {
    const stateData = {};
    data.forEach((item) => {
      set(stateData, [item.organizationId, item.id], item);
    });
    state.data = stateData;
  }),
  setSingle: action((state, data) => {
    const stateData = state.data || {};
    set(stateData, [data.organizationId, data.id], data);
    state.data = stateData;
  }),
  unsetSingle: action((state, { organisationId, appId }) => {
    if (state.data && state.data[organisationId]) {
      delete state.data[organisationId][appId];
    }
  }),
  fetch: thunk(async (actions, { silent, ...params }, { injections }) => {
    if (!silent) {
      actions.setLoading(true);
    }
    actions.setError(null);
    try {
      const data = await injections.apiService.get<OrganisationApp[]>(
        routes.getApps(),
        params || ({} as any),
      );
      actions.setData(data);
    } catch (err) {
      actions.setError(err);
    } finally {
      if (!silent) {
        actions.setLoading(false);
      }
    }
  }),
  fetchSingle: thunk(async (actions, { appId }, { injections }) => {
    const data = await injections.apiService.get<OrganisationApp>(routes.getApp(appId));
    actions.setSingle(data);
    return data;
  }),
  update: thunk(async (actions, app, { injections }) => {
    const data = await injections.apiService.put<OrganisationApp>(
      routes.updateApp(app.id),
      app,
    );
    actions.setSingle(data);
  }),
  updateAndCreateRelease: thunk(async (actions, app, { injections }) => {
    const autoDeployEnvironment = app.autoDeployEnvironment;
    delete app.autoDeployEnvironment;

    const data = await injections.apiService.put<OrganisationApp>(
      routes.updateAndCreateRelease(app.id),
      app,
      { autoDeployEnvironment },
    );
    actions.setSingle(data);
  }),
  enableLayoutV2: thunk(async (actions, params, { injections }) => {
    const data = await injections.apiService.get<OrganisationApp>(
      routes.enableLayoutV2(params.id),
      params,
    );
    actions.setSingle(data);
  }),
  install: thunk(async (actions, body, { injections }) => {
    const data = await injections.apiService.post<OrganisationApp>(
      routes.installApp(),
      body,
    );
    actions.setSingle(data);
    return data;
  }),
  delete: thunk(async (actions, app, { injections }) => {
    await injections.apiService.delete<void>(
      routes.deleteApp(app.id),
      {},
      {
        deviceType: app.deviceType,
        provider: app.provider,
        organizationId: app.organizationId,
      },
    );
    actions.unsetSingle({ organisationId: app.organizationId, appId: app.id });
  }),
};

export default organisationAppsModel;
