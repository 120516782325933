import {
  UseOrganizationUsersCreateFormFieldsProps,
} from '../store/hooks/organization-users/use-organization-users-create';
import {
  ScopeLevelPickerSpaceValues,
  UseOrganizationUsersEditFormFieldsProps,
} from '../store/hooks/organization-users/use-organization-users-edit';
import { isProd } from './env';

type UserFormFieldsProps = UseOrganizationUsersCreateFormFieldsProps | UseOrganizationUsersEditFormFieldsProps;

export const formatAllowedUserSpacesData = <T extends UserFormFieldsProps>(formData: T): T => {
  const { allowedSpaceIds, ...rest } = formData;
  const spaceArray = allowedSpaceIds as ScopeLevelPickerSpaceValues[];
  const hasAccessToAllSpaces = isProd() ? true : spaceArray.length === 1 && spaceArray[0].id === spaceArray[0].type;
  const updatedAllowedSpaceIds = hasAccessToAllSpaces ? [] : spaceArray.map(space => space.id);
  return {
    ...rest,
    hasAccessToAllSpaces,
    allowedSpaceIds: updatedAllowedSpaceIds,
  } as T;
};