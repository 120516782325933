import React, { useCallback } from 'react';
import { Divider, Tag, Typography } from 'antd';

import { ArrayFields } from '../../../../common/react-final-form';
import { Field } from 'react-final-form';
import { SegmentTagsMedia } from '../../../../../store/types/segment';
import styled from '@emotion/styled';
import { Row } from 'antd/es/grid';
import MediaContentPicker, { MediaContentPickerInput, MediaContentPickerSelection } from '../../../../common/react-final-form/media-content-picker';
import { useTranslation } from 'react-i18next';

interface TagsMediaSectionProps {
  tenantId: string;
  parentPropertyName: string;
  handleSelection: (input: MediaContentPickerInput, value: MediaContentPickerSelection) => void;
}

const TagsMediaSection = (props: TagsMediaSectionProps) => {
  const { tenantId, parentPropertyName, handleSelection } = props;

  const handleOnSelection = useCallback((input: MediaContentPickerInput, value: MediaContentPickerSelection) => {
    handleSelection(input, value);
  }, [handleSelection]);

  return (
    <TagsMediaSectionContainer>
      <MediaContentPicker
        tenantId={tenantId}
        name={`${parentPropertyName}.content`}
        isRequired={true}
        onSelection={(input, value) => {
          handleOnSelection(input, value)
        }}
      />

      <Field
        name={`${parentPropertyName}.tags`}
        render={({ input }) => {
          const tags = input.value as SegmentTagsMedia['tags'];
          return (
            <TagsLabelContainer>
              {tags &&
                tags.length &&
                tags.map((tag) => {
                  return <TagLabel key={tag.value}>{tag.value}</TagLabel>;
                })}
            </TagsLabelContainer>
          );
        }}
      />
    </TagsMediaSectionContainer>
  );
};

interface SegmentFormTagsMediaSectionProps {
  tenantId: string;
  handleSelection: (input: MediaContentPickerInput, value: MediaContentPickerSelection) => void;
}

const SegmentFormTagsMediaSection = (props: SegmentFormTagsMediaSectionProps) => {
  const { tenantId, handleSelection } = props;

  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={4}>{t('contents.content')}</Typography.Title>
      <SegmentTagsMediaSectionSubHeading>
        {t('contents.segmentTagsMediaSectionSubheading')}
      </SegmentTagsMediaSectionSubHeading>

      <DividerFull />

      <ArrayFields
        propertyName="tagsMedia"
        canAdd={false}
        canDelete={false}
        itemRowContent={(parentPropertyName) => {
          return (
            <SegmentTagsMediaSectionContainer>
              <TagsMediaSection tenantId={tenantId} parentPropertyName={parentPropertyName} handleSelection={handleSelection} />
              <DividerFull />
            </SegmentTagsMediaSectionContainer>
          );
        }}
      />
    </>
  );
};

export default SegmentFormTagsMediaSection;

const SegmentTagsMediaSectionSubHeading = styled(Typography.Text)`
  white-space: pre-line;
`;

const SegmentTagsMediaSectionContainer = styled(Row)`
  width: 100% !important;
`;

const TagsLabelContainer = styled.div``;

const TagLabel = styled(Tag)`
  text-transform: capitalize;
`;

const TagsMediaSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DividerFull = styled(Divider)`
  width: calc(100% + 41px);
  margin-left: -20px;
  overflow: none;
`;
