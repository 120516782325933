import React, { useCallback, useEffect } from 'react';
import { Avatar, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Environment from '../../../../store/types/environment';
import CrudList from '../../../common/crud-list/crud-list.component';
import Header from '../../../common/app-layout/header/header.component';

interface EnvironmentsListProps extends RouteComponentProps<{ organisationId: string }> {
  environments: Environment[];
  loaded: boolean;
  fetchEnvironments: (params: { organizationId: string }) => void;
  createEnvironment: (Environment: Partial<Environment>) => Promise<void>;
  updateEnvironment: (Environment: Partial<Environment>) => Promise<void>;
  deleteEnvironment: (id: string) => Promise<void>;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

const getEnvironmentData = (environment: Partial<Environment>) => ({
  environmentName: environment.environmentName,
  displayName: environment.displayName,
});

const PROTECTED_ENVS = ['prod'];

const EnvironmentsList = (props: EnvironmentsListProps) => {
  const {
    loaded,
    fetchEnvironments,
    environments,
    createEnvironment,
    updateEnvironment,
    deleteEnvironment,
    canCreate,
    canUpdate,
    canDelete,
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    fetchEnvironments({ organizationId: organisationId });
  }, [organisationId, fetchEnvironments]);

  const handleEnvironmentDelete = useCallback(
    async (environment: Environment) => {
      await deleteEnvironment(environment.id);
    },
    [deleteEnvironment],
  );

  const handleEnvironmentEdit = useCallback(
    async (environment: Partial<Environment>) => {
      await updateEnvironment({
        ...getEnvironmentData(environment),
        id: environment.id,
      });
    },
    [updateEnvironment],
  );

  const handleEnvironmentCreate = useCallback(
    async (environment: Partial<Environment>) => {
      await createEnvironment({
        ...getEnvironmentData(environment),
        organizationId: organisationId,
      });
    },
    [createEnvironment, organisationId],
  );

  const renderEnvironmentsListItem = useCallback(
    (environment: Environment) => (
      <List.Item.Meta
        avatar={<Avatar icon="cloud" />}
        title={environment.displayName}
        description={`build environment: ${environment.environmentName}`}
      />
    ),
    [],
  );

  const schema = {
    type: 'object',
    properties: {
      environmentName: {
        type: 'string',
        minLength: 1,
        title: t('name'),
      },
      displayName: {
        type: 'string',
        minLength: 1,
        title: t('displayName'),
      },
    },
    required: ['environmentName', 'displayName'],
  };

  const checkActionVisibilityForEnv = useCallback((item: Partial<Environment>) => {
    return item.environmentName && PROTECTED_ENVS.includes(item.environmentName) ? false : true;
  }, []);

  return (
    <>
      <Header title={t('environments')} />
      <div className="content-body">
        <CrudList<Environment>
          onCreate={handleEnvironmentCreate}
          onEdit={handleEnvironmentEdit}
          conditionalCanUpdate={checkActionVisibilityForEnv}
          onDelete={handleEnvironmentDelete}
          conditionalCanDelete={checkActionVisibilityForEnv}
          loaded={loaded}
          createSchema={schema}
          updateSchema={schema}
          renderItem={renderEnvironmentsListItem}
          dataSource={environments}
          createButtonText={t('createEnvironment')}
          modalTitle={t('environment')}
          canCreate={canCreate}
          canUpdate={canUpdate}
          canDelete={canDelete}
        />
      </div>
    </>
  );
};

export default EnvironmentsList;
