import { ContentTagType } from '../../../../../store/common/content-tags/content-tag-type';
import { OrientationTagValue } from '../../../../../store/common/content-tags/orientation';
import { ChannelTag } from '../../../../../store/types/channel';
import ContentTag from '../../../../../store/types/content-tag';
import { SegmentTagsMedia } from '../../../../../store/types/segment';

export const hasChannelTags = (tags: ChannelTag[] | null | undefined) => {
  if (!tags || (tags && !tags.length)) {
    return false;
  }

  return true;
};

// extract channel tags with their values (in channel we only store tagType and tagId)
export const generateSegmentTagsFromChannelTags = (
  tagsData: ContentTag[],
  channelTags: ChannelTag[],
): SegmentTagsMedia['tags'][] => {
  const orientationTag = channelTags.filter(
    (channelTag) =>
      channelTag.tagType === ContentTagType.ORIENTATION && channelTag.tagId === ContentTagType.ORIENTATION,
  );

  const contentTags = tagsData.filter((tag) => {
    return channelTags.some((channelTag) => channelTag.tagId === tag.id);
  });

  const segmentTags: SegmentTagsMedia['tags'][] = [];

  if (orientationTag.length) {
    const orientation = orientationTag[0];
    segmentTags.push([
      {
        tagType: orientation.tagType as ContentTagType.ORIENTATION,
        tagId: orientation.tagId as ContentTagType.ORIENTATION,
        value: OrientationTagValue.LANDSCAPE,
      },
      {
        tagType: orientation.tagType as ContentTagType.ORIENTATION,
        tagId: orientation.tagId as ContentTagType.ORIENTATION,
        value: OrientationTagValue.PORTRAIT,
      },
    ]);
  }

  contentTags.forEach((contentTag) => {
    const tags: SegmentTagsMedia['tags'] = [];

    const { values } = contentTag;

    if (values && values.length) {
      values.forEach((value) => {
        tags.push({
          tagType: ContentTagType.CONTENT_TAG,
          tagId: contentTag.id,
          value,
        });
      });

      segmentTags.push(tags);
    }
  });

  return segmentTags;
};

// generate distinct combinations for segment tags
export const generateSegmentTagsCombinations = (segmentTags: SegmentTagsMedia['tags'][]) => {
  const combinations: SegmentTagsMedia['tags'][] = [];

  generateCombinations(segmentTags, 0, [], combinations);

  function generateCombinations(
    segmentTags: SegmentTagsMedia['tags'][],
    index: number,
    currentCombination: SegmentTagsMedia['tags'],
    combinations: SegmentTagsMedia['tags'][],
  ) {
    if (currentCombination.length === segmentTags.length) {
      combinations.push(currentCombination);
    }

    if (index >= segmentTags.length) {
      return;
    }

    const tags = segmentTags[index];
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      generateCombinations(segmentTags, index + 1, [...currentCombination, tag], combinations);
    }
  }

  return combinations;
};