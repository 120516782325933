import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Icon, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import User from '../../../../store/types/user';
import countBy from 'lodash/countBy';
import capitalize from 'lodash/capitalize';
import { loadingIcon } from '../../spinner/spinner.component';
import UserRole from '../../../../store/types/user-roles';

interface UserListInstallationAccessProps {
  organisationId: string;
  users: User[];
  userRoles: Record<string, UserRole>;
  updateUser: (user: Partial<User> & { organizationId: string }) => Promise<void>;
  deleteUser: (payload: { id?: string; organizationId: string }) => Promise<void>;
  loaded: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

const UserListInstallationAccess = (props: UserListInstallationAccessProps) => {
  const { users, loaded, organisationId, userRoles } = props;

  const { t } = useTranslation();

  const filteredUsers = users.filter((user: User) => {
    const userOrganizationsWithRole = user.organizationsWithRole;

    const isValidUser = userOrganizationsWithRole.some(
      (organization) => organization.organizationId === organisationId,
    );

    return isValidUser;
  });

  const columns: ColumnProps<User>[] = useMemo(
    () => [
      {
        title: t('user'),
        key: 'user',
        sorter: (a: { email: string }, b: { email: string }) =>
          a.email.localeCompare(b.email),
        render: ({
          firstName,
          lastName,
          email,
        }: {
          firstName: string;
          lastName: string;
          email: string;
        }) => {
          return (
            <UserContainer>
              <Name>
                {firstName} {lastName}
              </Name>
              <Email>{email}</Email>
            </UserContainer>
          );
        },
      },
      {
        title: t('role'),
        key: 'organizationsWithRole',
        render: ({
          organizationsWithRole,
        }: {
          organizationsWithRole: { organizationId: string; roleId: string }[];
        }) => {
          const usersRole = organizationsWithRole.find(
            (organization) => organization.organizationId === organisationId,
          );

          const role = usersRole && userRoles && userRoles[usersRole.roleId];

          return <RoleIdContainer>{role ? role.displayName : t('error')}</RoleIdContainer>;
        },
      },
    ],
    [t, organisationId, userRoles],
  );

  const rolesCountSideBarColumns = useMemo(
    () => [
      {
        key: 'role',
        render: ({ count, roleDisplayName }: { count: number; roleDisplayName: string }) => {
          return (
            <SideBarContainer>
              {count} {capitalize(roleDisplayName)}
              <InfoIcon type="info-circle" />
            </SideBarContainer>
          );
        },
      },
    ],
    [],
  );

  const rolesCountStatsDataSource = useMemo(() => {
    const roleCountById = countBy(
      filteredUsers.map((user) => {
        const userOrganizationsWithRole = user.organizationsWithRole;
        return userOrganizationsWithRole.find(
          (organization) => organization.organizationId === organisationId,
        );
      }),
      (role) => (role ? role.roleId : t('error')),
    );
    
    return Object.entries(roleCountById).map(([roleId, count]) => {
      return {
        key: roleId,
        count,
        roleId,
        roleDisplayName: userRoles[roleId] ?  userRoles[roleId].displayName : t('error'),
      };
    })
  }, [filteredUsers, organisationId, userRoles, t]);

  return (
    <Container>
      <TabContainer>
        <UserTableContainer>
          <Table
            size="small"
            rowKey="id"
            dataSource={filteredUsers}
            columns={columns}
            pagination={{
              defaultPageSize: 20,
              pageSizeOptions: ['5', '20', '50', '75', '100'],
              total: filteredUsers.length,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            loading={{
              indicator: loadingIcon,
              spinning: !loaded,
            }}
          />
        </UserTableContainer>
        <SideBarTableContainer>
          <Table
            dataSource={rolesCountStatsDataSource}
            columns={rolesCountSideBarColumns}
            pagination={false}
            showHeader={false}
            size="small"
            loading={{
              indicator: loadingIcon,
              spinning: !loaded,
            }}
          />
        </SideBarTableContainer>
      </TabContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: visible;
  max-width: 100%;
  min-height: 888px;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 24px;
  padding: 32px;
  margin-top: 24px;
`;

const TabContainer = styled.div`
  max-width: 1020px;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  padding: 0px;

  @media only screen and (max-width: 992px) {
    flex-flow: column nowrap;
    display: block;
  }
`;

const UserTableContainer = styled.div`
  height: min-content;
  flex: 1 0 0px;
  display: flex;
  flex-flow: column;
  place-content: center flex-start;
  gap: 32px;
  padding: 0px;

  tr {
    height: 59px;
  }
  .ant-card-body {
    padding: 0px;
    width: 100%;
    background-color: #fff;
  }

  .ant-table-content {
    border: 0px;
  }
  .ant-table-wrapper {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
  }

  .ant-table-row {
    border: none !important;
    padding: 0px 16px;
  }

  .ant-table-thead {
    tr {
      height: 30px;
    }
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SideBarTableContainer = styled.div`
  position: sticky;
  overflow: visible;
  z-index: 1;
  height: min-content;
  left: 0px;
  top: 80px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0px;
  will-change: transform;

  .ant-table-row {
    border: none !important;
  }

  @media only screen and (max-width: 992px) {
    margin-top: 30px;
    width: 100%;
    flex-flow: column nowrap;
    display: block;
  }
`;

const SideBarContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 32px;
  padding-right: 100px;
  font-size: 14px;
  color: #676973;
`;

const InfoIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  padding-top: 4px;
`;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  color: #000;
`;

const Email = styled.div`
  color: #676973;
`;

const RoleIdContainer = styled.div`
  text-transform: capitalize;
  font-size: 14px;
  color: #676973;
`;

export default UserListInstallationAccess;
