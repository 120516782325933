import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cloneDeep, debounce, isNil, omitBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FieldProps, FormValidation } from 'react-jsonschema-form';
import { RouteComponentProps } from 'react-router-dom';
import Organisation, {
  BillingCurrencyEnum,
  OrganisationWithSlugGeneration,
  PlanSubscription,
} from '../../../store/types/organisation';
import Header from '../app-layout/header/header.component';
import CrudList from '../crud-list/crud-list.component';
import OrganisationListItem from './organisations-list-item/organisations-list-item.component';
import SlugWithValidation from '../schema-form/internal-widgets/slug-with-validation/slug-with-validation.component';
import { VatIdInput } from '../schema-form/fields';
import { SelectSearch } from '../schema-form/widgets';
import ValidationStatusEnum from '../../../store/types/validation-status-enum';
import { getDataResidencyInfo as getDataResidencyOptions } from '../../../utils/data-residency';
import OrganisationBillingType from '../../../store/types/organisation-billing-type.enum';
import { getSortedCountries } from '../../../utils/countries';
import { guessClientCountry } from '../../../utils/client-location';
import User from '../../../store/types/user';
import Plan from '../../../store/types/plan';
import { PlanTypeEnum } from '../../../store/types/organisation-plan';
import Address from '../../../store/types/address';
import { getTimezones } from '../../../utils/timezone/timezone';

interface OrganisationsListProps extends RouteComponentProps<{ organisationId: string }> {
  isCreateChildOrganisationMode?: boolean;
  organisations: Organisation[];
  loaded: boolean;
  isCurrentUserSysadmin: boolean;
  fetchOrganisations: () => void;
  fetchCurrentUser: () => void;
  createOrganisation: (Organisation: Partial<Organisation>) => Promise<void>;
  updateOrganisation: (Organisation: Partial<Organisation>) => Promise<void>;
  deleteOrganisation: (id: string) => Promise<void>;
  validateSlug: ({
    organizationName,
    organizationId,
  }: {
    organizationName: string;
    organizationId: string;
  }) => Promise<{ valid: boolean; message: string }>;
  parentOrganization?: Organisation;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  managingUsers?: User[];
  plans?: Plan[];
}

const countries = getSortedCountries();

const getOrganisationData = (
  organisation: Partial<OrganisationWithSlugGeneration>,
  mode: 'create' | 'edit',
): Partial<Organisation> => {
  const country = organisation.country as string;
  const address = omitBy(
    organisation.billingAddress,
    (value) => value === '' || isNil(value),
  );

  const isBillingAddressEmpty =
    Object.keys(address).length === 0 ||
    (Object.keys(address).length === 1 && !isNil(address.country));
  const billingAddress: Address = {
    name: address.name || '',
    email: address.email || '',
    addressLine1: address.addressLine1 || '',
    addressLine2: address.addressLine2 || '',
    city: address.city || '',
    state: address.state || '',
    postal: address.postal || '',
    country: isBillingAddressEmpty ? '' : country,
    notes: address.notes || '',
  };

  const normalizePlanSubscription = (subscription: PlanSubscription) => {
    const { id, planData, ...planCurrent } = subscription.current;
    return {
      current: planCurrent,
      scheduled:
        subscription.scheduled && subscription.scheduled.planId
          ? subscription.scheduled
          : undefined,
    }
  };

  const normalizePlanSubscriptions = (
    planSubscriptions: Organisation['planSubscriptions'] | undefined,
  ) =>
    planSubscriptions && {
      tenant: normalizePlanSubscription(planSubscriptions.tenant),
      support: normalizePlanSubscription(planSubscriptions.support),
    };

  return {
    organizationName: organisation.id
      ? (organisation.slug && organisation.slug.value) || ''
      : undefined,
    displayName: organisation.displayName,
    country,
    ...(mode === 'create' ? { dataResidency: organisation.dataResidency } : {}),
    isPartnerOrganization: organisation.isPartnerOrganization,
    parentOrganizationId:
      organisation.isPartnerOrganization && organisation.parentOrganizationId
        ? organisation.parentOrganizationId
        : '',
    customOverviewDashboard: organisation.customOverviewDashboard || '',
    externalId: organisation.externalId,
    billingType: organisation.billingType,
    csmEmail: organisation.csmEmail,
    salesEmail: organisation.salesEmail,
    billingContactEmail: organisation.billingContactEmail,
    billingAddress,
    timezone: organisation.timezone,
    vatId: organisation.vatId,
    enabled: organisation.enabled,
    restrictUserEmailDomains: organisation.restrictUserEmailDomains,
    allowedUserEmailDomains: organisation.allowedUserEmailDomains,
    planSubscriptions: normalizePlanSubscriptions(organisation.planSubscriptions),
  };
};

const OrganisationsList = (props: OrganisationsListProps) => {
  const {
    isCreateChildOrganisationMode = false,
    parentOrganization,
    loaded,
    isCurrentUserSysadmin,
    fetchOrganisations,
    organisations,
    createOrganisation,
    updateOrganisation,
    deleteOrganisation,
    validateSlug,
    canCreate,
    canUpdate,
    canDelete,
    fetchCurrentUser,
    match: {
      params: { organisationId },
    },
    managingUsers,
    plans,
  } = props;
  const latestFormValueRef = useRef<Partial<OrganisationWithSlugGeneration>>({});
  const validateCurrentSlugRef = useRef<any>({});
  const [defaultCountry, setDefaultCountry] = useState<string>('');
  const { t } = useTranslation();
  const vatIdRef = useRef<{
    isValidating: boolean;
    errors?: string[] | undefined;
  }>({ isValidating: false, errors: undefined });

  useEffect(() => {
    fetchOrganisations();
  }, [fetchOrganisations]);

  useEffect(() => {
    guessClientCountry().then((countryCode) => {
      setDefaultCountry(countryCode);
    });
  }, []);

  const parentOrganizations = useMemo(() => {
    return organisations.reduce(
      (parentOrgs: Organisation[], org) => {
        if (org.parentOrganizationId || !org.isPartnerOrganization) return parentOrgs;
        return [...parentOrgs, org];
      },
      isCreateChildOrganisationMode && parentOrganization ? [parentOrganization] : [],
    );
  }, [organisations, isCreateChildOrganisationMode, parentOrganization]);

  const handleOrganisationDelete = useCallback(
    async (organisation: Organisation) => {
      await deleteOrganisation(organisation.id);
    },
    [deleteOrganisation],
  );

  const handleOrganisationEdit = useCallback(
    (organisation: Partial<Organisation>) =>
      updateOrganisation({
        ...getOrganisationData(organisation, 'edit'),
        id: organisation.id,
        ...(isCreateChildOrganisationMode && {
          isPartnerOrganization: true,
          parentOrganizationId: organisationId,
        }),
      }),
    [isCreateChildOrganisationMode, organisationId, updateOrganisation],
  );

  const handleOrganisationCreate = useCallback(
    async (organisation: Partial<Organisation>) => {
      await createOrganisation({
        ...getOrganisationData(organisation, 'create'),
        ...(isCreateChildOrganisationMode && {
          isPartnerOrganization: true,
          parentOrganizationId: organisationId,
        }),
      });
      await fetchCurrentUser();
      await fetchOrganisations();
    },
    [createOrganisation, isCreateChildOrganisationMode, organisationId, fetchOrganisations, fetchCurrentUser],
  );

  const dependencies = useMemo(
    () => ({
      dependencies: {
        isPartnerOrganization: {
          oneOf: [
            {
              properties: {
                isPartnerOrganization: {
                  enum: [false],
                },
              },
            },
            {
              properties: {
                isPartnerOrganization: {
                  enum: [true],
                },
                parentOrganizationId: {
                  type: 'string',
                  title: t('parentOrganization'),
                  enum: ['', ...parentOrganizations.map((org) => org.id)],
                  enumNames: ['', ...parentOrganizations.map((org) => org.displayName)],
                  default: isCreateChildOrganisationMode ? organisationId : '',
                  readOnly: isCreateChildOrganisationMode,
                },
              },
            },
          ],
        },
      },
    }),
    [isCreateChildOrganisationMode, organisationId, parentOrganizations, t],
  );

  const { dataResidencyOptions, defaultDataResidency } = useMemo(
    () => getDataResidencyOptions(),
    [],
  );

  const [showAllowedEmailDomainsInput, setShowAllowedEmailDomainsInput] = useState(false);

  const createSchema = useMemo(() => {
    const managingUserEmails = (managingUsers || []).map((user) => user.email);

    return {
      type: 'object',
      properties: {
        displayName: {
          type: 'string',
          minLength: 1,
          title: t('name'),
        },
        country: {
          title: t('country'),
          type: 'string',
          enum: countries.codes,
          enumNames: countries.names,
          default: defaultCountry,
        },
        currency: {},
        dataResidency: {
          title: t('dataResidency'),
          type: 'string',
          enum: ['', ...dataResidencyOptions],
          default: defaultDataResidency,
          readOnly: false,
        },
        restrictUserEmailDomains: {
          type: 'boolean',
          default: false,
          title: t('restrictUserEmailDomains'),
        },
        ...(showAllowedEmailDomainsInput && {
          allowedUserEmailDomains: {
            type: 'array',
            items: {
              type: 'string',
              title: t('emailDomain')
            },
            title: t('allowedUserEmailDomains'),
            minItems: 1,
          },
        }),
        timezone: {
          type: 'string',
          title: t('timezone'),
          default: 'UTC',
          enum: getTimezones(),
        },
        ...(isCurrentUserSysadmin && {
          externalId: {
            type: 'number',
            title: t('netSuiteId'),
          },
        }),
        billingType: {
          title: t('billingType'),
          type: 'string',
          enum: [
            OrganisationBillingType.LEGACY,
            OrganisationBillingType.DIRECT_INVOICE,
            OrganisationBillingType.PARTNER_INVOICE,
            OrganisationBillingType.STRIPE,
            OrganisationBillingType.AZURE_SAAS,
          ],
          enumNames: [
            'Legacy',
            'Direct invoice',
            'Partner invoice',
            'Credit card (Stripe)',
            'Azure Marketplace SaaS',
          ],
          default: isCreateChildOrganisationMode
            ? OrganisationBillingType.PARTNER_INVOICE
            : OrganisationBillingType.DIRECT_INVOICE,
        },
        vatId: {
          type: 'string',
          title: t('vatId'),
          minLength: 8,
          maxLength: 14,
        },
        billingContactEmail: {
          type: 'string',
          title: t('tenantBillingContactEmailLabel'),
          default: '',
        },
        ...(isCurrentUserSysadmin && {
          customOverviewDashboard: {
            type: 'string',
            title: t('customOverviewDashboard'),
            default: '',
          },
          csmEmail: {
            type: 'string',
            title: t('tenantCsmLabel'),
            default: '',
            enum: ['', ...managingUserEmails],
          },
          salesEmail: {
            type: 'string',
            title: t('tenantSalesEmailLabel'),
            default: '',
            enum: ['', ...managingUserEmails],
          },
        }),
        ...(isCurrentUserSysadmin &&
          !isCreateChildOrganisationMode && {
            isPartnerOrganization: {
              type: 'boolean',
              title: t('isPartnerOrganization'),
              default: isCreateChildOrganisationMode,
              readOnly: isCreateChildOrganisationMode,
            },
          }),
        billingAddress: {
          type: 'object',
          title: t('address.billingAddress'),
          properties: {
            name: {
              type: 'string',
              title: t('address.name'),
              maxLength: 200,
            },
            email: {
              type: 'string',
              title: t('address.email'),
              default: '',
            },
            addressLine1: {
              type: 'string',
              title: t('address.addressLine1'),
              minLength: 2,
              maxLength: 200,
            },
            addressLine2: {
              type: 'string',
              title: t('address.addressLine2'),
              minLength: 2,
              maxLength: 200,
            },
            city: {
              type: 'string',
              title: t('address.city'),
              minLength: 2,
              maxLength: 200,
            },
            state: {
              type: 'string',
              title: `${t('address.stateProvinceRegion')}`,
              minLength: 2,
              maxLength: 200,
            },
            postal: {
              type: 'string',
              title: t('address.postalZipCode'),
              minLength: 2,
              maxLength: 20,
            },
            notes: {
              type: 'string',
              title: t('address.notes'),
              maxLength: 250,
            }
          },
          dependencies: {
            // TO DO: Possible multiple validation error per required field, check how to collate these dependencies
            addressLine1: ['addressLine1', 'city', 'postal'],
            addressLine2: ['addressLine1', 'city', 'postal'],
            city: ['addressLine1', 'city', 'postal'],
            state: ['addressLine1', 'city', 'postal'],
            postal: ['addressLine1', 'city', 'postal'],
          },
          required: ['name','addressLine1']
        },
      },
      required: ['displayName', 'country', 'dataResidency'],
      ...(isCurrentUserSysadmin && !isCreateChildOrganisationMode && dependencies),
    };
  }, [
    dataResidencyOptions,
    defaultCountry,
    defaultDataResidency,
    dependencies,
    isCreateChildOrganisationMode,
    isCurrentUserSysadmin,
    managingUsers,
    t,
    showAllowedEmailDomainsInput
  ]);

  const updateSchema = useMemo(() => {
    const tenantPlans = (plans || []).filter((plan) => plan.type === PlanTypeEnum.TENANT);
    const supportPlans = (plans || []).filter(
      (plan) => plan.type === PlanTypeEnum.SUPPORT,
    );

    const schema = cloneDeep(createSchema);
    const required = ['slug'];

    if (isCurrentUserSysadmin) {
      schema.properties.currency = {
        title: t('currency'),
        type: 'string',
        enum: Object.keys(BillingCurrencyEnum),
        readOnly: true,
      };
      required.push('currency');
    }

    schema.properties.dataResidency.readOnly = true;

    return {
      ...schema,
      properties: {
        slug: {
          type: 'object',
          title: t('slug'),
          readOnly: true, // dont allow slug changes for now
        },
        enabled: {
          type: 'boolean',
          title: t('tenantEnabledLabel'),
        },
        ...schema.properties,
        ...(isCurrentUserSysadmin &&
          plans && {
            planSubscriptions: {
              type: 'object',
              title: t('updateOrganisationForm.planSubscriptions'),
              properties: {
                tenant: {
                  type: 'object',
                  properties: {
                    scheduled: {
                      type: 'object',
                      properties: {
                        planId: {
                          type: 'string',
                          title: t(
                            'updateOrganisationForm.scheduledTenantPlanSubscription',
                          ),
                          default: '',
                          enum: ['', ...tenantPlans.map((plan) => plan.id)],
                          enumNames: ['', ...tenantPlans.map((plan) => plan.name.en)],
                        },
                      },
                    },
                  },
                },
                support: {
                  type: 'object',
                  properties: {
                    scheduled: {
                      type: 'object',
                      properties: {
                        planId: {
                          type: 'string',
                          title: t(
                            'updateOrganisationForm.scheduledSupportPlanSubscription',
                          ),
                          default: '',
                          enum: ['', ...supportPlans.map((plan) => plan.id)],
                          enumNames: ['', ...supportPlans.map((plan) => plan.name.en)],
                        },
                      },
                    },
                  },
                },
              },
            },
          }),
      },
      required: [...schema.required, ...required],
    };
  }, [createSchema, t, plans, isCurrentUserSysadmin]);

  const uiSchema = useMemo(() => {
    return {
      objectFieldTemplate: 'main-section',
      slug: {
        'ui:field': SlugWithValidation,
      },
      allowedUserEmailDomains: {
        'ui:options': {
          orderable: false
        },
      },
      country: {
        'ui:widget': SelectSearch,
      },
      timezone: {
        'ui:widget': SelectSearch,
      },
      planSubscriptions: {
        tenant: {
          objectFieldTemplate: 'none',
          scheduled: {
            objectFieldTemplate: 'none',
          },
        },
        support: {
          objectFieldTemplate: 'none',
          scheduled: {
            objectFieldTemplate: 'none',
          },
        },
      },
      billingType: {
        'ui:disabled': isCreateChildOrganisationMode,
        'ui:enumDisabled': [
          OrganisationBillingType.AZURE_SAAS,
          OrganisationBillingType.LEGACY,
        ],
      },
      vatId: {
        'ui:field': (fieldProps: FieldProps) => {
          return (
            <VatIdInput
              {...fieldProps}
              onValidation={(
                status: ValidationStatusEnum,
                errors?: string[] | undefined,
              ) => {
                const isValidating =
                  status === (ValidationStatusEnum.VALIDATING as ValidationStatusEnum);

                vatIdRef.current = {
                  isValidating,
                  errors,
                };
              }}
            />
          );
        },
      },
      billingAddress: {
        notes: {
          'ui:widget': 'textarea',
        },
      },
      'ui:order': ['*', 'plans', 'billingAddress'],
      'ui.widgets': ['textarea']
    };
  }, [isCreateChildOrganisationMode]);

  const onFormChange = useCallback(
    async (
      organization: Partial<OrganisationWithSlugGeneration>,
      rootFormChange: any,
    ) => {
      setShowAllowedEmailDomainsInput(organization.restrictUserEmailDomains || false);
      if (!rootFormChange || !organization.id) {
        if (organization) {
          latestFormValueRef.current = organization;
        }
        return;
      }

      const currentSlugValue = ((organization.slug || {}) as any).value || '';
      const prevSlug = latestFormValueRef.current && latestFormValueRef.current.slug;
      const slugDidChange = currentSlugValue !== (prevSlug && prevSlug.value);
      latestFormValueRef.current = organization;
      if (slugDidChange && validateCurrentSlugRef.current.cancel) {
        validateCurrentSlugRef.current.cancel();
      }

      const setSlugStatus = (status: ValidationStatusEnum, error = '') => {
        rootFormChange({
          ...latestFormValueRef.current,
          slug: {
            ...latestFormValueRef.current.slug,
            status,
            error,
          },
        });
      };

      validateCurrentSlugRef.current = debounce(async () => {
        try {
          const { valid, message } = await validateSlug({
            organizationId: organization.id || '',
            organizationName: currentSlugValue,
          });
          setSlugStatus(
            valid ? ValidationStatusEnum.SUCCESS : ValidationStatusEnum.ERROR,
            valid ? '' : message,
          );
        } catch {
          setSlugStatus(ValidationStatusEnum.ERROR, t('error'));
        }
      }, 1500);

      if (slugDidChange) {
        if (currentSlugValue) {
          setSlugStatus(ValidationStatusEnum.VALIDATING);
          validateCurrentSlugRef.current();
        } else {
          setSlugStatus(ValidationStatusEnum.NONE);
        }
      }
    },
    [t, validateSlug],
  );

  const customFormValidation = useCallback(
    (organization: Partial<OrganisationWithSlugGeneration>, error: FormValidation) => {
      const isStillValidating =
        organization.slug && organization.slug.status === ValidationStatusEnum.VALIDATING;
      if (
        organization.slug &&
        (organization.slug.error || !organization.slug.value || isStillValidating)
      ) {
        error.slug.addError(
          !organization.slug.value || isStillValidating ? t('invalidSlug') : '',
        );
      }

      if (vatIdRef.current.errors) {
        vatIdRef.current.errors.forEach((err) => {
          error.vatId.addError(err);
        });
      }

      if (vatIdRef.current.isValidating) {
        error.vatId.addError('');
      }

      return error;
    },
    [t],
  );

  const organizationsWithSlug = useMemo(
    () =>
      organisations.map((org) => {
        const billingAddress = omitBy(org.billingAddress, (val) => {
          return val === '';
        }) as Address;

        if (org.vatId === '') {
          delete org.vatId;
        }

        return {
          ...org,
          slug: {
            value: org.organizationName,
            status: ValidationStatusEnum.NONE,
            error: '',
          },
          billingAddress,
        };
      }),
    [organisations],
  );

  return (
    <>
      <Header
        title={isCreateChildOrganisationMode ? t('managedTenants') : t('organizations')}
        headerTitleTestId="tenants-list-header-title"
      />
      <div className="content-body">
        <CrudList<Organisation>
          onCreate={handleOrganisationCreate}
          onEdit={handleOrganisationEdit}
          onDelete={handleOrganisationDelete}
          loaded={loaded}
          createSchema={createSchema}
          updateSchema={updateSchema}
          metaSchema={uiSchema}
          renderItem={(organisation) => (
            <OrganisationListItem
              organisation={organisation}
              organisations={organisations}
            />
          )}
          dataSource={organizationsWithSlug}
          createButtonText={isCreateChildOrganisationMode ? t('createOrganization') : undefined}
          createButtonTestId="tenants-list-create-tenant-button"
          modalTitle={
            isCreateChildOrganisationMode ? t('managedTenant') : t('organization')
          }
          onFormChange={onFormChange}
          externalFormChange
          canCreate={canCreate}
          canUpdate={canUpdate}
          canDelete={canDelete}
          customFormValidation={customFormValidation}
        />
      </div>
    </>
  );
};

export default OrganisationsList;
