import React, { useEffect } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import { getPermissions } from '../../../../../../utils/auth/permissions';
import SpaceUsers from './space-details-users.component';

type SpaceUsersContainer = Pick<
  React.ComponentProps<typeof SpaceUsers>,
  'match' | 'history' | 'location'
>;

const SpaceUsersContainer = (props: SpaceUsersContainer) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const {
    fetchUsers,
    fetchUserRoles,
    updateUser,
    deleteUser,
  } = useActions<RootModel>((actions) => ({
    fetchUsers: actions.users.fetch,
    updateUser: actions.users.update,
    deleteUser: actions.users.delete,
    fetchUserRoles: actions.userRoles.fetch,
  }));

  const { users, loaded, permissions, loggedInUser, userRoles } = useStore((state: RootState) => ({
    users: state.users.values,
    userRoles: state.userRoles.data[organisationId] || {},
    loaded: state.users.loaded && state.userRoles.loaded(organisationId),
    permissions: getPermissions(state.auth.user, 'grid'),
    loggedInUser: state.auth.user,
  }));

  useEffect(() => {
    if (loggedInUser) {
      fetchUsers({ organizationId: loggedInUser.isSysadmin ? '' : organisationId });
      fetchUserRoles({ organizationId: organisationId });
    }
  }, [fetchUsers, loggedInUser, organisationId, fetchUserRoles]);

  return (
    <SpaceUsers
      {...props}
      loaded={loaded}
      users={users}
      userRoles={userRoles}
      updateUser={updateUser}
      deleteUser={deleteUser}
      canUpdate={permissions.users.update}
      canDelete={permissions.users.remove}
    />
  );
};

export default SpaceUsersContainer;
