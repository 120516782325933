import { Field } from 'react-final-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import get from 'lodash/get';
import toUpper from 'lodash/toUpper';
import {
  FormTextInput,
  InputCheckbox,
  Label,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  RowStyled,
} from './styled-block';
import AvailableTagsForQueue from './available-tags-for-queue';
import Stations from './stations';
import CustomInfoFields from './custom-info-fields';
import { ValuesProps } from './types';
import RegistrationFieldsBuilder from './registration-fields-builder';
import Queue from '../../../../../store/types/queue';
import AutoCheckOut from './auto-check-out';
import CustomizableTextsGroup from './customizable-texts-group';
import TextInAppCustomizationLanguagePicker from './text-in-app-customization-lanugage-picker';

const { Title } = Typography;

const castToUpper = (value: string): string => value && toUpper(value);

interface QueueSectionProps {
  values: any;
  organizationId: string;
  change: (name: string, value: any) => void;
  queue: Queue | null;
}

const QueueSection: React.FC<QueueSectionProps> = ({
  values: valuesAny,
  organizationId,
  change,
  queue,
}) => {
  const { t } = useTranslation();
  const values = valuesAny as ValuesProps;

  return (
    <>
      <PanelCardMain bodyStyle={{ padding: 0 }}>
        <MainSectionTitle>
          <Title level={2}>{t('queue')}</Title>
        </MainSectionTitle>
        <PanelBody>
          <AvailableTagsForQueue
            organizationId={organizationId}
            region={values.region}
            activeLanguage={values.selectedLanguageForTextCustomization}
            change={change}
          />

          <Field name="registrationEnabled" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('enableRegistration')}
                </Label>
              </RowStyled>
            )}
          </Field>

          {!!values.registrationEnabled && (
            <RegistrationFieldsBuilder
              fieldName="registrationFields"
              activeLanguage={values.selectedLanguageForTextCustomization}
              registrationFields={get(queue, 'registrationFields', [])}
              formRegistrationFields={get(values, 'registrationFields', [])}
              categories={get(values, 'categories', [])}
              change={change}
            />
          )}

          <Field name="printingManualTicketsEnabled" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('enableManualTicketsPrinting')}
                </Label>
              </RowStyled>
            )}
          </Field>
          <Field name="checkInByQRCode" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('enableQrCodeScanCheckIn')}
                </Label>
              </RowStyled>
            )}
          </Field>
          <Field
            name="ticketFlowModifiers.anonymous.cancellation.enabled"
            type="checkbox"
          >
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('ticketCancellationIsAbleCancelWalkInTitle')}
                </Label>
              </RowStyled>
            )}
          </Field>
          <Field name="autoCheckOutByNextEnabled" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox
                    {...input}
                    type="checkbox"
                    disabled={!(values.isInStore && values.isEmailLogin)}
                    {...!(values.isInStore && values.isEmailLogin) && { checked: false }}
                  />
                  {t('enableAutoCheckOutByNext')}
                </Label>
              </RowStyled>
            )}
          </Field>
          {values.featureList && values.featureList.includes('BOOKINGS') && (
            <Field
              name="ticketFlowModifiers.anonymous.rescheduling.enabled"
              type="checkbox"
            >
              {({ input }) => (
                <RowStyled>
                  <Label>
                    <InputCheckbox {...input} type="checkbox" />
                    {t('ticketReschedulingIsWalkInReschedulingAvailable')}
                  </Label>
                </RowStyled>
              )}
            </Field>
          )}
          <Field name="notifyExtraPendingPositionOffset">
            {({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                label={t('notifyExtraPendingPosition')}
                description={t('notifyExtraPendingPositionDescription')}
                required
              />
            )}
          </Field>
          <Field name="maxNumberOfPeoplePerWalkInTicket">
            {({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                label={t('maxNumberOfPeoplePerWalkInTicket')}
                required
              />
            )}
          </Field>
          <Field name="positionLabelPrefixes.anonymous" parse={castToUpper}>
            {({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                maxLength={3}
                label={t('anonymousTicketLabel')}
              />
            )}
          </Field>
          <Field name="positionLabelPrefixes.manual" parse={castToUpper}>
            {({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                maxLength={3}
                label={t('manualTicketLabel')}
              />
            )}
          </Field>

          <Stations />

          <CustomInfoFields
            translationKey="сustomInformationForTicket"
            nameProperty="position"
          />

          <Field name="isKeepDistanceInfoEnabled" type="checkbox">
            {({ input }) => (
              <RowStyled>
                <Label>
                  <InputCheckbox {...input} type="checkbox" />
                  {t('isKeepDistanceInfoEnabledLabel')}
                </Label>
              </RowStyled>
            )}
          </Field>

          {values.isKeepDistanceInfoEnabled && (
            <>
              <TextInAppCustomizationLanguagePicker />

              <CustomizableTextsGroup
                textsGroup={t('keepDistance')}
                activeLanguage={values.selectedLanguageForTextCustomization}
              />
            </>
          )}

          <AutoCheckOut />
        </PanelBody>
      </PanelCardMain>
    </>
  );
};

export default QueueSection;
