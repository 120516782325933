import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Menu, Dropdown, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../../../types';
import { Icon } from '../../../../common/schema-form/common';
import { Segment } from '../../../../../store/types/segment';
import useDeleteModal from '../../../../common/use-delete-modal';
import useDeleteSegment from '../../../../../store/hooks/segments/use-delete-segment';
import Message from '../../../../common/message';
import { Link } from 'react-router-dom';

interface ActionComponentProps {
  segment: Segment
  organizationId: string;
  channelId: string;
}

const OrganizationMediaSegmentsListActions = (props: ActionComponentProps) => {
  const { segment, organizationId: tenantId, channelId } = props;

  const { t } = useTranslation();

  const { mutateAsync: deleteSegment } = useDeleteSegment(tenantId, channelId);

  const [showDeleteModal] = useDeleteModal();

  const handleDelete = useCallback(() => {
    showDeleteModal(
      t('contents.delete'),
      t('contents.segmentDelete'),
      undefined,
      async () => {
        try {
          await deleteSegment(segment.id!);
          message.success(<Message content={t('contents.segmentDeleteSuccess')} />);
        } catch(err) {
          message.error(<Message content={t('contents.segmentDeleteFailed')} />);
        }
      }
    )
  }, [deleteSegment, showDeleteModal, segment.id, t]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          <Menu.Item key={`${segment.id}-clone`}>
            <Link to={`/organisations/${tenantId}/content/channels/${channelId}/segments/clone/${segment.id}`}>
              <CloneButton
                type="link"
                icon="plus"
                size="small"
              >
                {t('contents.clone')}
              </CloneButton>
            </Link>
          </Menu.Item>
          <Menu.Item key={`${segment.id}-remove`}>
            <RemoveButton
              type="link"
              icon="delete"
              size="small"
              onClick={handleDelete}
            >
              {t('delete')}
            </RemoveButton>
          </Menu.Item>
        </Menu>
      }
    >
      <StyledIcon type="small-dash" />
    </Dropdown>
  );
};

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as ButtonType;

const CloneButton = styled(Button)`
  color: rgb(35, 100, 170);
  text-transform: capitalize;

  &:hover,
  &:active,
  &:focus {
    color: rgb(40, 113, 194);
  }
` as ButtonType;

const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
`;

export default OrganizationMediaSegmentsListActions;