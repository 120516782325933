import React, { useMemo, useState, useCallback } from 'react';
import { Typography, Button, Divider, Switch } from 'antd';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import arrayMutator from 'final-form-arrays';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import OrganisationSpace, {
  SpaceBillingAddress,
  SpaceBillingContact,
  SpaceTags,
  WeeklySchedule,
  WeeklyScheduleFormFields,
} from '../../../../../store/types/organisation-space';
import Overlay from '../../../../common/overlay/overlay.component';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import OrganisationSpaceTypeEnum from '../../../../../store/types/organisation-space-type.enum';
import SpaceFormDetailsSection from './space-form-details-section.component';
import SpaceFormMainSection from './space-form-main-section.component';
import SpaceFormScheduleSection from './space-form-schedule-section.component';
import SpaceFormNotesSection from './space-form-notes-section.component';
import {
  transformWeeklyScheduleForFormFields,
  transformWeeklyScheduleForFormSubmission,
} from './utils';
import Spinner from '../../../../common/spinner/spinner.component';
import { getCoordinatesByCountryCode } from '@ombori/ga-geolocation-helper';
import { updateSpacesDisplayNameWithParentPath } from '../../../../../utils/spaces';
import SpaceFormBillingSection from './space-form-billing-section.component';
import { EMAIL_REGEXP, getFieldNamesWithError } from '../../../../common/react-final-form';
import { scrollIntoView } from '../../../../common/react-final-form';
import { FormApi } from 'final-form';
import moment from 'moment';
import lodashEvery from 'lodash/every';
import lodashIsEmpty from 'lodash/isEmpty';
import ContentTagPicker from '../../../../common/react-final-form/content-tag-picker';
import { useActiveTenantAllSpaces } from '../../../../common/spaces-provider/spaces-provider.component';
import { isProd } from '../../../../../utils/env';

export interface OrganisationSpaceSettings {
  id?: string;
  organizationId: string;
  displayName: string;
  externalId?: string;
  parentSpaceId?: string;
  type: OrganisationSpaceTypeEnum;
  country: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  weeklySchedule: WeeklySchedule;
  notes: string;
  latitude?: string | number;
  longitude?: string | number;
  tags?: SpaceTags[];
  billingContact?: SpaceBillingContact,
  billingAddress?: SpaceBillingAddress;
}

type OrganisationSpaceFormFields = Omit<
  OrganisationSpaceSettings,
  'id' | 'weeklySchedule'
> & { weeklySchedule: WeeklyScheduleFormFields, openingHours?: { to: moment.Moment, from: moment.Moment } };

interface SpaceFormProps {
  tenantId: string;
  initialValues: OrganisationSpaceSettings;
  onFormSubmit: (values: OrganisationSpaceSettings) => Promise<boolean>;
}

const checkIsOptionalEmailValid = (email: string): boolean => {
  return (
    email && email.length && EMAIL_REGEXP.test(email.toLowerCase()) === false ? false : true
  );
};

const SpaceForm = (props: SpaceFormProps) => {
  const { tenantId, initialValues, onFormSubmit } = props;

  const { t } = useTranslation();

  const { spaces: orgSpacesData } = useActiveTenantAllSpaces();

  const [isFormSubmitting, setisFormSubmitting] = useState(false);

  const [showSpaceLevelBillingInformationToggle, setShowSpaceLevelBillingInformationToggle] = useState(false);

  const [ hasSpaceLevelBillingInformation, setHasSpaceLevelBillingInformation ] = useState(false);

  const formInitialValues = useMemo<OrganisationSpaceFormFields>(() => {
    setShowSpaceLevelBillingInformationToggle(
      initialValues.type === OrganisationSpaceTypeEnum.LOCATION ? true : false,
    );

    const isSpcaeBillingEmpty = lodashEvery(
      {
        ...(initialValues.billingContact ? initialValues.billingContact : {}),
        ...(initialValues.billingAddress ? initialValues.billingAddress : {}),
      },
      lodashIsEmpty,
    );

    setHasSpaceLevelBillingInformation(isSpcaeBillingEmpty ? false : true);

    return {
      ...initialValues,
      weeklySchedule: transformWeeklyScheduleForFormFields(
        initialValues.weeklySchedule || { weeklyTime: {} },
      ),
    };
  }, [initialValues]);

  const spacesDropdownData = useMemo<OrganisationSpace[]>(() => {
    if (!orgSpacesData) {
      return [];
    }

    return updateSpacesDisplayNameWithParentPath(
      initialValues.id
        ? orgSpacesData.filter(
            (orgSpace) => orgSpace.id !== initialValues.id,
          )
        : orgSpacesData,
    );
  }, [orgSpacesData, initialValues]);

  const handleSave = useCallback(
    async (values: OrganisationSpaceFormFields | Object, form: FormApi) => {
      const {
        displayName,
        organizationId,
        parentSpaceId,
        externalId,
        type,
        country,
        address,
        city,
        state,
        postcode,
        weeklySchedule,
        notes,
        tags,
        billingContact,
        billingAddress,
      } = values as OrganisationSpaceFormFields;

      const { lat = 0, long = 0 } = getCoordinatesByCountryCode(country) || {};

      setisFormSubmitting(true);

      const result = await onFormSubmit({
        displayName,
        organizationId,
        externalId,
        ...(parentSpaceId &&
          parentSpaceId !== 'no_parent' && { parentSpaceId }),
        type,
        country,
        address,
        city,
        state,
        postcode,
        weeklySchedule:
          transformWeeklyScheduleForFormSubmission(weeklySchedule),
        notes,
        latitude: Number(lat),
        longitude: Number(long),
        ...(tags && tags.length && { tags }),
        ...(showSpaceLevelBillingInformationToggle && hasSpaceLevelBillingInformation && { billingContact }),
        ...(showSpaceLevelBillingInformationToggle && hasSpaceLevelBillingInformation && { billingAddress }),
      });

      if (!result) {
        // don't set openingHours as it'll reset all other days' hours
        const { openingHours: _, ...restValues } = values as OrganisationSpaceFormFields;
        form.initialize(restValues);
      }

      setisFormSubmitting(false);
    },
    [onFormSubmit, showSpaceLevelBillingInformationToggle, hasSpaceLevelBillingInformation],
  );

  const handleValidation = useCallback((values: OrganisationSpaceSettings | Object) => {
    let hasErrors = false;

    const errors: Partial<OrganisationSpaceSettings> = {
      billingContact: {
        email: '',
      },
      billingAddress: {
        name: '',
        email: '',
        addressLine1: '',
        city: '',
        country: '',
        postal: '',
      }
    };

    const { billingContact, billingAddress } = values as OrganisationSpaceSettings;
    const { email = '' } = billingContact || {};
    const { email: billingEmail = '' } = billingAddress || {};

    if (checkIsOptionalEmailValid(email) === false) {
      errors.billingContact!.email = t('spaceForm.billingContact.emailInvalidError');
      hasErrors = true;
    }

    if (checkIsOptionalEmailValid(billingEmail) === false) {
      errors.billingAddress!.email = t('spaceForm.billingAddress.emailInvalidError');
      hasErrors = true;
    }

    return hasErrors ? errors : {};
  }, [t]);

  const handleSpaceLevelBillingInformationVisibility = useCallback(
    (checked: boolean, updateFormValues: FormApi['change']) => {
      const value = !!checked;
      setHasSpaceLevelBillingInformation(value);
      if (!value) {
        updateFormValues('billingContact', {});
        updateFormValues('billingAddress', {});
      }
    },
    [],
  );

  return (
    <>
      {isFormSubmitting && (
        <StyledOverlay>
          <Spinner />
        </StyledOverlay>
      )}
      <ControlsContainer>
        <Typography.Title>{initialValues.id ? '' : t('newSpace')}</Typography.Title>
        <Button type="primary" form="space-settings-form" htmlType="submit">
          {t('saveAllChanges')}
        </Button>
      </ControlsContainer>
      <Container>
        <ContainerInfo>
          <FormContainer>
            <FormContainerField>
              <Form
                validate={handleValidation}
                onSubmit={handleSave}
                initialValues={formInitialValues}
                mutators={{
                  ...arrayMutator,
                }}
                render={({
                  handleSubmit,
                  errors,
                  invalid,
                  values: formValues,
                  form: { change: updateFormValues },
                }) => {
                  return (
                    <>
                      <SpaceSettingsTitle>
                        <Typography.Title level={4}>
                          {t('spaceForm.spaceSettings')}
                        </Typography.Title>
                      </SpaceSettingsTitle>

                      <form id="space-settings-form" onSubmit={(event) => {
                        handleSubmit(event);

                        const errorFieldNames = getFieldNamesWithError(errors);
                        if (invalid && errorFieldNames.length) {
                          scrollIntoView(errorFieldNames[0]);
                        }
                      }}>
                        <SpaceFormMainSection
                          spacesDropdownData={spacesDropdownData}
                        />

                        <Divider />

                        <SpaceFormDetailsSection />

                        <SpaceFormScheduleSection />

                        {showSpaceLevelBillingInformationToggle && <SpaceLevelBillingInformation>
                          <Switch
                            checked={hasSpaceLevelBillingInformation}
                            onChange={(checked) => {
                              handleSpaceLevelBillingInformationVisibility(checked, updateFormValues)
                            }}
                          />
                          <span>{t('spaceForm.spaceLevelBillingInformation')}</span>
                        </SpaceLevelBillingInformation>}

                        <OnChange name='type'>
                          {(value) => {
                            setShowSpaceLevelBillingInformationToggle(
                              value === OrganisationSpaceTypeEnum.LOCATION ? true : false,
                            );
                            handleSpaceLevelBillingInformationVisibility(false, updateFormValues);
                          }}
                        </OnChange>

                        {showSpaceLevelBillingInformationToggle && hasSpaceLevelBillingInformation && (
                            <SpaceFormBillingSection />
                          )
                        }

                        {showSpaceLevelBillingInformationToggle && <Divider />}

                        {!isProd() && (
                          <>
                            <ContentTagPicker
                              name="tags"
                              label={t('spaceForm.contentTagsHeading')}
                              tenantId={tenantId}
                              updateFormValues={updateFormValues}
                            />
                            <Divider />
                          </>
                        )}

                        <SpaceFormNotesSection />
                      </form>
                    </>
                  );
                }}
              />
            </FormContainerField>
          </FormContainer>
        </ContainerInfo>
      </Container>
    </>
  );
};

export default SpaceForm;

const ControlsContainer = styled(PanelCard)`
  position: sticky;
  border-radius: 0px;
  text-align: end;
  padding: 8px 24px;
  top: 0;
  z-index: 2;

  .ant-card-body {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &::before {
      content: none !important;
      display: flex !important;
    }

    &::after {
      content: none !important;
      display: flex !important;
    }
  }

  h1 {
    margin: 0px;
    font-size: 32px;
  }
`;

const Container = styled.div`
  position: relative;
  overflow: visible;
  max-width: 912px;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 24px;
  padding: 32px;
  background: #fff;
  margin: auto;
`;

const ContainerInfo = styled.div`
  max-width: 1280px;
  width: 100%;
  height: min-content;
  padding: 0px;
`;

const FormContainer = styled.div`
  position: relative;
  height: min-content;
  flex: 0.68 0 0px;
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 32px;
  padding: 0px;
`;

const FormContainerField = styled.div`
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 0px;
  padding: 0px;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px 0px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 32px;
  border: 1px solid #dddddd;
  padding: 16px;

  form {
    width: 100%;
  }

  .form-group {
    padding: 0px;
    margin-top: 12px;
  }

  .control-label {
    font-weight: bold;
  }
`;

const SpaceSettingsTitle = styled.div`
  align-self: flex-start;
`;

const StyledOverlay = styled(Overlay)`
  position: absolute !important;
  flex-direction: column;
  z-index: 1000;
  background: rgb(245 246 250 / 46%);
`;

const SpaceLevelBillingInformation = styled.div`
  display: flex;
  gap: 10px;
  font-weight: bold;
  margin-top: 15px;
`;
