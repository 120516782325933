import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import InputWrap from './input-wrap';
import useOrganisationMedia from '../use-organisation-media';
import Media from '../../../store/types/media';
import PreviewIcon from '../media-library/preview-icon/preview-icon.component';
import MediaPreview from '../media-library/media-preview/media-preview.component';
import ContentLibrary from '../../organisations/organisation-details/content/library/content-library.component';
import Gridapp from '../../../store/types/gridapp';
import GridappBuild from '../../../store/types/gridapp-build';import ContentLibraryAppPreview from '../../organisations/organisation-details/content/library/content-library-app-preview.component';
;

export enum MediaContentPickerSelectionEnum {
  MEDIA = 'media',
  APP = 'app',
}

export interface MediaContentPickerMediaSelection {
  type: MediaContentPickerSelectionEnum.MEDIA;
  mediaItem: Media;
}

export interface MediaContentPickerAppSelection {
  type: MediaContentPickerSelectionEnum.APP;
  gridApp: Gridapp,
  gridAppBuild: GridappBuild,
  appSettingsData: any;
}

export type MediaContentPickerSelection = MediaContentPickerMediaSelection | MediaContentPickerAppSelection;

export interface MediaContentPickerInput {
  onChange: (event: React.ChangeEvent<any>) => void;
}

interface MediaContentPickerProps<T> {
  name: string;
  tenantId: string;
  content?: T | undefined;
  isRequired?: boolean;
  allowedTypes?: string[];
  onSelection: (input: MediaContentPickerInput, value: MediaContentPickerSelection) => void;
}

/**
 * @description This is a react-final-form control and gives the ability to select media or app. It doesn't update the value
 * of the control itself, you'll need to pass in onSelection function as prop to be able to however you want to set the value,
 * this component behaves in a generic way
 * @param props 
 * @returns 
 */

const MediaContentPicker = <T extends Object & {},>(props: MediaContentPickerProps<T>) => {
  const { name, tenantId, content, isRequired, onSelection } = props;

  const [isMediaModalVisible, setIsMediaModalVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<MediaContentPickerMediaSelection | null>(null);
  const [selectedApp, setSelectedApp] = useState<MediaContentPickerAppSelection | null>(null);
  const [selectedFolderId, setSelectedFolderId] = useState<string | undefined>(undefined);
  const [isMediaPreviewModalVisible, setIsMediaPreviewModalVisible] = useState(false);
  const [isAppPreviewSettingsModalVisible, setIsAppPreviewSettingsModalVisible] = useState(false);

  const { data: mediaItems } = useOrganisationMedia(tenantId);

  useEffect(() => {
    // if (!content || (content && ('mediaId' in content)) || !mediaItems || !mediaItems.files) {
    //   return;
    // }

    // const media = mediaItems.files.find((mediaItem) => 'mediaId' in content && content['mediaId'] === mediaItem.id);
    // setSelectedMedia(media ? media : null);
  }, [content, mediaItems]);
  
  const handleMediaLibrary = useCallback(() => {
    setIsMediaModalVisible(true);
  }, []);

  const handleOnAppSelect = useCallback(
    (
      input: MediaContentPickerInput,
      gridApp: Gridapp,
      gridAppBuild: GridappBuild,
      appSettingsData: Record<string, string | number | boolean | Record<string, string>>,
    ) => {
      onSelection(input, {
        type: MediaContentPickerSelectionEnum.APP,
        gridApp,
        gridAppBuild,
        appSettingsData,
      });
  
      setIsMediaModalVisible(false);
      setSelectedApp({
        type: MediaContentPickerSelectionEnum.APP,
        gridApp: gridApp,
        gridAppBuild: gridAppBuild,
        appSettingsData: appSettingsData,
      });
      setSelectedMedia(null);
    },
    [onSelection, setIsMediaModalVisible, setSelectedApp, setSelectedMedia]
  );  

  const { t } = useTranslation();

  return (
    <Field
      name={name}
      validate={isRequired ? requiredAll : undefined}
      render={({ input, meta }) => {
        return (
          <InputWrap
            isRequired={isRequired}
            error={meta.touched && meta.error ? meta.error : undefined}
          >
            {(!input.value) && (
              <ButtonWrap>
                <Button icon="plus" type="link" size="large" onClick={handleMediaLibrary}>{t('contents.addContent')}</Button>
              </ButtonWrap>
            )}

            {selectedMedia && (
              <SelectedMediaContainer onClick={() => setIsMediaPreviewModalVisible(true)}>
                <SelectedMediaPreview>
                  <PreviewIcon mediaItem={selectedMedia.mediaItem} />
                </SelectedMediaPreview>

                <SelectedMediaMeta>
                  <div>{selectedMedia.mediaItem.name}</div>
                  <div>{selectedMedia.type}</div>
                </SelectedMediaMeta>

                {isMediaPreviewModalVisible && (
                  <div onClick={(event) => event.stopPropagation()}>
                    <MediaPreview
                      mediaItem={selectedMedia.mediaItem}
                      onClose={() => setIsMediaPreviewModalVisible(false)}
                    />
                  </div>
                )}
              </SelectedMediaContainer>
            )}

            {selectedApp && (
              <>
                <SelectedAppContainer>
                  <SelectedAppMeta>
                    <Button icon="preview" type="link" size="large" onClick={() => setIsAppPreviewSettingsModalVisible(true)}>
                      {selectedApp.gridApp.displayName} - {selectedApp.gridAppBuild.result && selectedApp.gridAppBuild.result.version}
                    </Button>
                  </SelectedAppMeta>
                </SelectedAppContainer>
                {isAppPreviewSettingsModalVisible && (
                  <Modal
                    title={selectedApp.gridApp.displayName}
                    visible={isAppPreviewSettingsModalVisible}
                    onCancel={() => setIsAppPreviewSettingsModalVisible(false)}
                    centered={true}
                    okText={t('contents.saveAllChanges')}
                    okButtonProps={{ htmlType: 'submit', form: 'content-library-app-preview-schema-form' }}
                  >
                    <ContentLibraryAppPreview
                      tenantId={tenantId}
                      app={selectedApp.gridApp}
                      appVersion={selectedApp.gridAppBuild && selectedApp.gridAppBuild.result && selectedApp.gridAppBuild.result.version}
                      appSettingsData={selectedApp.appSettingsData}
                      onAppSettingsUpdated={(gridApp, gridAppBuild, appSettingsData) => {
                        handleOnAppSelect(input, gridApp, gridAppBuild, appSettingsData);
                        setIsAppPreviewSettingsModalVisible(false);
                      }}                    
                    />
                  </Modal>
                )}
              </>
            )}

            {(input.value && (selectedMedia || selectedApp)) && (
              <ButtonWrap>
                <Button icon="edit" type="link" size="large" onClick={() => {
                  if (selectedMedia && selectedMedia.mediaItem.ancestors && selectedMedia.mediaItem.ancestors.length) {
                    const { ancestors } = selectedMedia.mediaItem;
                    const folderId = ancestors[ancestors.length - 1];
                    setSelectedFolderId(folderId);
                  } else {
                    setSelectedFolderId(undefined);
                  }

                  handleMediaLibrary();
                }}>{t('contents.editContent')}</Button>
              </ButtonWrap>
            )}

            {isMediaModalVisible && (
              <Modal
                title={t('contents.chooseContent')}
                centered={true}
                footer={null}
                visible={isMediaModalVisible}
                width="80%"
                onCancel={() => setIsMediaModalVisible(false)}
              >
                <ContentLibrary
                  titleForPreviewModal={t('contents.contentPreview')}
                  tenantId={tenantId}
                  selectedFolderId={selectedFolderId}
                  mediaSelectText={t('contents.insertContent')}
                  onFolderSelect={(selectedFolderId) => {
                    setSelectedFolderId(selectedFolderId)
                  }}
                  onMediaSelect={(newMediaItem) => {
                    onSelection(input, {
                      type: MediaContentPickerSelectionEnum.MEDIA,
                      mediaItem: newMediaItem,
                    })

                    setIsMediaModalVisible(false);
                    setSelectedMedia({
                      type: MediaContentPickerSelectionEnum.MEDIA,
                      mediaItem: newMediaItem,
                    });
                    setSelectedApp(null);
                  }}
                  onAppSelect={(
                    gridApp: Gridapp,
                    gridAppBuild: GridappBuild,
                    appSettingsData: Record<string, string | number | boolean | Record<string, string>>,
                  ) => {
                    handleOnAppSelect(input, gridApp, gridAppBuild, appSettingsData);
                  }}
                />
              </Modal>
            )}
          </InputWrap>
        )
      }}
    />
  )
}

export default MediaContentPicker;

const SelectedMediaContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const SelectedMediaPreview = styled.div`
  width: 64px;
  height: 64px;
`;

const SelectedMediaMeta = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedAppContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const SelectedAppMeta = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 5px;
`;
