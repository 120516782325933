import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import User from '../../../../../../store/types/user';
import UsersListResourceLevel from '../../../../../common/users-list/users-list-resource-level/users-list-resource-level.component';
import UserRole from '../../../../../../store/types/user-roles';

interface SpaceUsersProps
  extends RouteComponentProps<{
    organisationId: string;
    spaceId: string;
  }> {
  users: User[];
  userRoles: Record<string, UserRole>;
  updateUser: (user: Partial<User> & { organizationId: string }) => Promise<void>;
  deleteUser: (payload: { id?: string; organizationId: string }) => Promise<void>;
  loaded: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}

const SpaceUsers = (props: SpaceUsersProps) => {
  const {
    users,
    userRoles,
    loaded,
    canUpdate,
    canDelete,
    updateUser,
    deleteUser,
    match: {
      params: { organisationId },
    },
  } = props;

  return (
    <>
      <UsersListResourceLevel
        users={users}
        userRoles={userRoles}
        updateUser={updateUser}
        deleteUser={deleteUser}
        loaded={loaded}
        canUpdate={canUpdate}
        canDelete={canDelete}
        organisationId={organisationId}
      />
    </>
  );
};

export default SpaceUsers;
