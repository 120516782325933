import { useMutation, useQuery, useQueryClient } from 'react-query';
import OrganisationApp from '../../../store/types/organisation-app';
import { useStoreActions } from '../../../store/initialize-store';
import { useApiService } from '../api-service-provider';

const buildQueryKey = (appId: string) => ['organisationApp', appId];

interface UseAppProps {
  appId: string;
}

export const useApp = ({ appId }: UseAppProps) => {
  const { getApp } = useStoreActions((actions) => ({
    getApp: actions.organisationApps.fetchSingle,
  }));

  return useQuery(
    buildQueryKey(appId),
    () => getApp({ appId }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useUpdateAndCreateRelease = (appId: string) => {
  const queryClient = useQueryClient();

  const { updateAndCreateRelease } = useStoreActions((actions) => ({
    updateAndCreateRelease: actions.organisationApps.updateAndCreateRelease,
  }));

  return useMutation((app: OrganisationApp & { autoDeployEnvironment: string }) => updateAndCreateRelease(app), {
    onSuccess: () => {
      queryClient.invalidateQueries(buildQueryKey(appId));
    },
  });
};

export const useEnableSettingsV2Layout = (appId: string) => {
  const queryClient = useQueryClient();

  const { enableLayoutV2 } = useStoreActions((actions) => ({
    enableLayoutV2: actions.organisationApps.enableLayoutV2,
  }));

  return useMutation((params: { id: string, enable: boolean }) =>
    enableLayoutV2(params), {
      onSuccess: () => {
        queryClient.invalidateQueries(buildQueryKey(appId));
      },
    }
  );
};

export const useRollbackAppFromBuild = ({ appId, buildId }: {buildId:string, appId:string}) => {

  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/builds/${buildId}/rollback-app`;

  return useMutation(
    async () =>
      apiService.put<OrganisationApp>(
        url,
        {},
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(buildQueryKey(appId));
      },
      
    },
  );
};

interface OrganizationAppSettings {
  provider: Object;
  spacesSettings?: Object;
  devicesSettings?: Object;
}

export const useAppsSettings = (organizationId: string, appIds: string[]) => {
  const apiService = useApiService();
  
  const url = `/api/settings/list/?organizationId=${organizationId}&appIds=${appIds.join(',')}`;

  return useQuery(
    ['appSettings', appIds.join(',')],
    () => apiService.get<Record<string, OrganizationAppSettings>>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  )
}
