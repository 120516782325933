import React, { useCallback } from 'react';
import { Icon, Typography, Col, Row } from 'antd';
import styled from '@emotion/styled';
import OrganisationApp from '../../../../../store/types/organisation-app';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import { useAppBuilds } from '../../../../common/use-app-builds';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import BuildsTable from './builds-table';
import { useEnvironmentsV1 } from '../../../../common/use-environments';
import usePagination from '../../../../common/pagination/hook/use-pagination';
import Pagination from '../../../../common/pagination/pagination.component';

const { Title } = Typography;

const TableContainer = styled.div`
  margin-bottom: 32px;

  &:nth-last-of-type(-n + 1) {
    margin-bottom: 0;
  }
`;

const PaginationContainer = styled.div``;

interface BuildsV2ComponentProps {
  app: OrganisationApp;
  organisationId: string;
}

const BuildsV2Component: React.FC<BuildsV2ComponentProps> = ({ app, organisationId }) => {
  const { t } = useTranslation();

  const {
    page,
    defaultPage,
    pageSize,
    setPage,
    setPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const buildsState = useAppBuilds({
    appId: app.id,
    limit: pageSize,
    skip: page === 1 ? 0 : pageSize * (page - 1),
  });

  const environments = useEnvironmentsV1({ tenantId: organisationId });

  const handlePaginationSizeChange = useCallback(
    (pageNum: number, pageLimit: number) => {
      setPage(defaultPage);

      setPageSize(pageLimit);
    },
    [setPageSize, setPage, defaultPage],
  );

  const handlePageChange = useCallback(
    (pageNum: number) => {
      setPage(pageNum);
    },
    [setPage],
  );

  return (
    <ContentWrap>
      {buildsState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {buildsState.isSuccess && buildsState.data.builds.length === 0 && (
        <Overlay>
          <Title level={4}>
            {t('noBuildsFound')} <Icon type="frown" />
          </Title>
        </Overlay>
      )}

      {buildsState.isSuccess && (
        <Row gutter={[16, 40]}>
          <Col md={24}>
            <TableContainer>
              <BuildsTable
                appType={app.deviceType}
                organisationId={organisationId}
                appId={app.id}
                builds={buildsState.data.builds}
                environments={environments}
              />
            </TableContainer>
          </Col>

          <Col md={24}>
            <PaginationContainer>
              <Pagination
                isLoading={buildsState.isLoading}
                align="right"
                showSizeChanger
                onShowSizeChange={handlePaginationSizeChange}
                current={page}
                pageSize={pageSize}
                total={buildsState.data.total}
                onChange={handlePageChange}
                pageSizes={defaultPageSizeOption}
              />
            </PaginationContainer>
          </Col>
        </Row>
      )}
    </ContentWrap>
  );
};

export default BuildsV2Component;
