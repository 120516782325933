import { Button, Col, Row, List, Typography, Upload, Icon, message } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import Media from '../../../store/types/media';
import Header from '../app-layout/header/header.component';
import Overlay from '../overlay/overlay.component';
import Spinner from '../spinner/spinner.component';
import MediaEditModalContainer from './media-edit-modal/media-edit-modal.container';
import MediaListItem from './media-list-item/media-list-item.component';
import PanelCard from '../panel-card/panel-card.component';
import FormInput from '../form-input/form-input.component';
import ModalFormField from '../modal-form/modal-form-field/modal-form-field.component';
import DataCard from '../data-card/data-card.component';
import ModalForm from '../modal-form/modal-form.component';
import { getMediaItemKind, MediaItemKind } from '../../../utils/media-item-utils';
import OpenAIImg from '../../../assets/images/openai.svg';
import PexelsImg from '../../../assets/images/pexels.png';
import MediaGenerateWithAIModalContainer from './media-generate-with-ai-modal/media-generate-with-ai-modal.container';
import MediaFreeCollection from './media-free-collection/media-free-collection.container';

const { Paragraph } = Typography;

const MediaList = styled(Row)`
  margin-top: 16px;
  flex: 1;
`;

const MediaLibraryUpload = styled(Col)`
  margin: 16px 0;
  background: #fff;
  .ant-upload-drag,
  .ant-upload-list-item {
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.04);
  }
`;

const ListActionRow = styled(Row)`
  margin-bottom: 16px;
  align-items: center;
`;

const SpecialButton = styled(Button)`
  background: transparent;
  border-style: dashed;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
` as any;

const IconImg = styled.img`
  height: 16px;
  width: 16px;
`;

const formSchema = {
  type: 'object',
  properties: {
    folderName: {
      title: 'Key',
      type: 'string',
      minLength: 1,
      pattern: '^[^\\s]*$',
    },
  },

  required: ['folderName'],
};
interface MediaLibraryProps {
  organisationId: string;
  mediaItems: Media[];
  fetchMediaItems: (params: { organization: string; folderId: string | null }) => void;
  loaded: boolean;
  uploadMedia: (params: {
    organisationId: string;
    file: File;
    folderId: string | null;
    onProgress?: (percent: number) => void;
  }) => Promise<void>;
  onSelectedFolder: (folderId: string) => void;
  selectedFolderId: string | null;
  deleteMedia: (id: string) => void;
  onBackClick: (selectedFolderId: string | null) => void;
  onSelectMediaItem?: (item: Media) => void;
  mediaTypesFilter?: string[];
  newFolderButtonText?: string;
  backButtonText?: string;
  subscribeChanges: (organisationId: string) => Promise<() => void>;
  canViewMedia: boolean;
  canUpdateMedia: boolean;
  canRemoveMedia: boolean;
  canUploadMedia: boolean;
}

const MediaLibrary = (props: MediaLibraryProps) => {
  const {
    mediaItems,
    fetchMediaItems,
    loaded,
    organisationId,
    uploadMedia,
    deleteMedia,
    onSelectMediaItem,
    mediaTypesFilter,
    newFolderButtonText,
    selectedFolderId,
    onSelectedFolder,
    onBackClick,
    backButtonText,
    subscribeChanges,
    canViewMedia,
    canUpdateMedia,
    canRemoveMedia,
    canUploadMedia,
  } = props;
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedMediaItemId, setSelectedMediaItemId] = useState<string | null>(null);
  const [showAIPhotosModal, setShowAIPhotosModal] = useState<boolean>(false);
  const [showFreeMediaCollection, setShowFreeMediaCollection] = useState<boolean>(false);
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState<boolean>(
    false,
  );

  useEffect(() => {
    fetchMediaItems({ organization: organisationId, folderId: selectedFolderId });
  }, [fetchMediaItems, organisationId, selectedFolderId]);

  const handleUploadInputChange = (event: UploadChangeParam) => {
    if (
      event.file &&
      event.file.status &&
      ['done', 'error'].includes(event.file.status)
    ) {
      setFileList((list) => list.filter((file) => file.uid !== event.file.uid));
    } else {
      setFileList([...event.fileList]);
    }
  };

  const handleUpload = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const handleProgress = (percent: number) => {
      onProgress({ percent });
    };
    try {
      await uploadMedia({
        organisationId,
        file,
        folderId: selectedFolderId,
        onProgress: handleProgress,
      });
      onSuccess();
    } catch (error) {
      message.error(t('errorUploadingTheFile'));
      onError(error);
    }
  };

  const handleDelete = useCallback(
    (mediaItem: Media) => {
      deleteMedia(mediaItem.id);
    },
    [deleteMedia],
  );

  const handleCreateFolderClick = useCallback(() => {
    setIsCreateFolderModalVisible(true);
  }, [setIsCreateFolderModalVisible]);

  const handleCreateFolderModalSubmit = useCallback(
    async (folder: { folderName: string }) => {
      try {
        const file = new File([], folder.folderName, {
          type: 'folder',
          lastModified: Date.now(),
        });
        await uploadMedia({ organisationId, file, folderId: selectedFolderId });
        message.success(t('successfullyCreatedMediaFolder'))
      } catch (e) {
        message.error(t('failedToCreateMediaFolder'));
        throw e;
      }
    },
    [uploadMedia, organisationId, selectedFolderId, t],
  );

  const handleCreateFolderModalCancel = useCallback(() => {
    setIsCreateFolderModalVisible(false);
  }, [setIsCreateFolderModalVisible]);

  const openEditModal = useCallback((mediaItem: Media) => {
    setSelectedMediaItemId(mediaItem.id);
  }, []);

  const closeEditModal = () => {
    setSelectedMediaItemId(null);
  };

  const handleBackClick = () => {
    onBackClick(selectedFolderId);
  };

  const handleSelectMediaItem = useCallback(
    (item: Media) => {
      const isFolder = getMediaItemKind(item) === MediaItemKind.folder;

      if (isFolder) {
        onSelectedFolder(item.id);
        return;
      }

      if (onSelectMediaItem) {
        onSelectMediaItem(item);
      }
    },
    [onSelectedFolder, onSelectMediaItem],
  );

  const handleGenerateImageWithAIClick = useCallback(() => {
    setShowAIPhotosModal(true);
  }, []);

  const handleShowFreeMediaCollection = useCallback(() => {
    setShowFreeMediaCollection(true);
  }, []);

  useEffect(() => {
    let unsubscribe = () => {};

    const subscribe = async () => {
      try {
        unsubscribe = await subscribeChanges(organisationId);
      } catch {
        // ignore the error
      }
    };

    subscribe();

    return () => {
      unsubscribe();
    };
  }, [subscribeChanges, organisationId]);

  const renderMediaListItem = useCallback(
    (item: Media) => (
      <MediaListItem
        item={item}
        mediaTypesFilter={mediaTypesFilter}
        onSelect={handleSelectMediaItem}
        onDelete={handleDelete}
        onEdit={openEditModal}
        canViewMedia={canViewMedia}
        canUpdateMedia={canUpdateMedia}
        canRemoveMedia={canRemoveMedia}
      />
    ),
    [
      handleSelectMediaItem,
      handleDelete,
      openEditModal,
      canViewMedia,
      canUpdateMedia,
      canRemoveMedia,
      mediaTypesFilter,
    ],
  );

  const title = useMemo(() => {
    return (
      <ListActionRow type="flex" justify="space-between">
        <Col>{t('mediaLibrary')}</Col>
        <ListActionRow type="flex" gutter={[16, 16]}>
          <Col >
            <SpecialButton size="large" onClick={handleShowFreeMediaCollection} data-testid="media-free-collection-button">
              <Icon component={() => <IconImg src={PexelsImg} />} />
              {t('explorePhotos')}
            </SpecialButton>
          </Col>
          <Col>
            <SpecialButton size="large" onClick={handleGenerateImageWithAIClick} data-testid="media-ai-generate-button">
              <Icon component={() => <IconImg src={OpenAIImg} />} />
              {t('generatePhotosWithAI')}
            </SpecialButton>
          </Col>
        </ListActionRow>
      </ListActionRow>
    );
  }, [t, handleGenerateImageWithAIClick, handleShowFreeMediaCollection]);

  return (
    <>
      <Header title={title} />
      <div className="content-body">
        {(selectedFolderId || canUploadMedia) && (
          <ListActionRow type="flex" justify="space-between">
            <Col>
              {selectedFolderId && (
                <Button size="large" icon="arrow-up" onClick={handleBackClick} data-testid="enclosing-folder-back-button">
                  {backButtonText || t('Enclosing folder')}
                </Button>
              )}
            </Col>
            {canUploadMedia && (
              <Col>
                <Button size="large" icon="plus" onClick={handleCreateFolderClick} data-testid="create-folder-button">
                  {newFolderButtonText || t('newFolder')}
                </Button>
              </Col>
            )}
          </ListActionRow>
        )}
        <ModalForm
          schema={formSchema}
          title={t('folder')}
          visible={isCreateFolderModalVisible}
          onClose={handleCreateFolderModalCancel}
          onSubmit={handleCreateFolderModalSubmit}
        >
          <DataCard>
            <ModalFormField
              name="folderName"
              label={t('folderName')}
              component={FormInput}
              required
              data-testid="input-folder-name-form"
            />
          </DataCard>
        </ModalForm>
        <Col>
          {canUploadMedia && (
            <Row>
              <MediaLibraryUpload data-testid="upload-media-button">
                <Upload.Dragger
                  showUploadList={{ showPreviewIcon: true, showRemoveIcon: false }}
                  onChange={handleUploadInputChange}
                  fileList={fileList}
                  listType="picture"
                  customRequest={handleUpload}
                  name="media"
                  multiple
                >
                  <Icon type="upload" />
                  <Paragraph>{t('clickOrDragFileToThisAreaToUpload')}</Paragraph>
                </Upload.Dragger>
              </MediaLibraryUpload>
            </Row>
          )}
          <MediaList>
            {loaded ? (
              <Col>
                <PanelCard
                  bodyStyle={{
                    paddingTop: 8,
                    paddingLeft: 0,
                    paddingBottom: 8,
                    paddingRight: 0,
                  }}
                >
                  <List
                    itemLayout="horizontal"
                    rowKey="id"
                    dataSource={mediaItems}
                    renderItem={renderMediaListItem}
                    data-testid="media-list-items"
                  />
                </PanelCard>
              </Col>
            ) : (
              <Overlay>
                <Spinner />
              </Overlay>
            )}
          </MediaList>
        </Col>
        <MediaEditModalContainer
          visible={!!selectedMediaItemId}
          onClose={closeEditModal}
          mediaItemId={selectedMediaItemId}
          canEdit={canUpdateMedia}
        />
        <MediaGenerateWithAIModalContainer
          visible={showAIPhotosModal}
          onClose={() => setShowAIPhotosModal(false)}
          organisationId={organisationId}
        />
        <MediaFreeCollection
          visible={showFreeMediaCollection}
          onClose={() => setShowFreeMediaCollection(false)}
          organisationId={organisationId}
        />
      </div>
    </>
  );
};

export default MediaLibrary;
