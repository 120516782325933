import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import styled from '@emotion/styled';
import { Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormTextInput } from '../../queue-details/queue-setup/styled-block';
import {
  InputWrap,
} from '../../../../common/react-final-form';
import { requiredAll } from '../../queue-details/queue-setup/validate-form';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import OrganisationSpaceTypeEnum from '../../../../../store/types/organisation-space-type.enum';
import { ChangeEvent } from '../../../../../types';
import SuperSelect from '../../../../common/super-select';

interface SpaceFormMainSectionProps {
  spacesDropdownData: OrganisationSpace[];
}

interface SpaceTypes {
  value: string;
  label: string;
}

const SpaceFormMainSection = (props: SpaceFormMainSectionProps) => {
  const { t } = useTranslation();

  const { spacesDropdownData } = props;

  const spaceTypes: SpaceTypes[] = useMemo(() => {
    return [
      {
        value: OrganisationSpaceTypeEnum.LOCATION,
        label: t(`spaceTypes.${OrganisationSpaceTypeEnum.LOCATION}`),
      },
      {
        value: OrganisationSpaceTypeEnum.REGION,
        label: t(`spaceTypes.${OrganisationSpaceTypeEnum.REGION}`),
      },
      {
        value: OrganisationSpaceTypeEnum.SECTION,
        label: t(`spaceTypes.${OrganisationSpaceTypeEnum.SECTION}`),
      },
      {
        value: OrganisationSpaceTypeEnum.FLOOR,
        label: t(`spaceTypes.${OrganisationSpaceTypeEnum.FLOOR}`),
      },
      {
        value: OrganisationSpaceTypeEnum.CUSTOM,
        label: t(`spaceTypes.${OrganisationSpaceTypeEnum.CUSTOM}`),
      },
    ];
  }, [t]);

  const getSelectOptions = (spacesDropdownData: OrganisationSpace[] | null, t: (key: string) => string) => {
    const options = [];

    // Option for no parent space
    options.push(
      <Select.Option key="no_parent" value="no_parent">
        {t('spaceForm.noParentSpace')}
      </Select.Option>
    );

    // Options for spaces
    if (spacesDropdownData) {
      options.push(
        ...spacesDropdownData.map((space) => (
          <Select.Option key={space.id} value={space.id}>
            {space.displayName}
          </Select.Option>
        ))
      );
    }

    return options;
  };

  return (
    <>
      <FieldsContainer gutter={[12, 12]}>
        <Col span={12}>
          <Field
            name="displayName"
            validate={requiredAll}
            render={({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                required={true}
                label={t('spaceForm.name')}
              />
            )}
          />
        </Col>

        <Col span={12}>
          <Field
            name="externalId"
            render={({ input, meta }) => (
              <FormTextInput
                input={input}
                meta={meta}
                label={t('spaceForm.externalId')}
              />
            )}
          />
        </Col>
      </FieldsContainer>

      <FieldsContainer gutter={[12, 12]}>
        <Col span={12}>
          <Field
            name="parentSpaceId"
            render={({ input, meta }) => (
              <InputWrap
                label={t('spaceForm.parentSpace')}
                error={meta.error && meta.touched ? meta.error : null}
              >
                <SuperSelect
                  disabled={!spacesDropdownData || !spacesDropdownData.length}
                  showSearch={true}
                  filterOption={true}
                  optionFilterProp="children"
                  value={
                    !spacesDropdownData || !spacesDropdownData.length
                      ? ''
                      : input.value
                  }
                  onChange={(value: any) => {
                    input.onChange({
                      target: {
                        value,
                      },
                    } as unknown as ChangeEvent<string>);
                  }}
                >
                  {getSelectOptions(spacesDropdownData, t)}
                </SuperSelect>

              </InputWrap>
            )}
          />
        </Col>

        <Col span={12}>
          <Field
            name="type"
            render={({ input, meta }) => (
              <InputWrap
                label={t('spaceForm.spaceType')}
                error={meta.error && meta.touched ? meta.error : null}
              >
                <Select
                  value={input.value}
                  onChange={(value) => {
                    input.onChange({
                      target: {
                        value,
                      },
                    } as unknown as ChangeEvent<string>);
                  }}
                >
                  {spaceTypes &&
                    spaceTypes.map((spaceType) => (
                      <Select.Option key={spaceType.value} value={spaceType.value}>
                        {spaceType.label}
                      </Select.Option>
                    ))}
                </Select>
              </InputWrap>
            )}
          />
        </Col>
      </FieldsContainer>
    </>
  );
};

const FieldsContainer = styled(Row)`
  margin-bottom: 15px !important;
`;

export default SpaceFormMainSection;
