import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, message } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../../../types';
import useGoBack from '../../../../common/use-go-back';
import SegmentFormFields, {
  SegmentFormApiValidationErrors,
  SegmentFormValues,
} from './segment-form-fields.component';
import { ApiError } from '../../../../../services/api/api-error';
import Message from '../../../../common/message';
import { extractApiValidationErrors } from '../../../../common/react-final-form';
import useCreateSegment from '../../../../../store/hooks/segments/use-create-segment';
import useChannelById from '../../../../../store/hooks/channels/use-channel-by-id';
import Overlay from '../../../../common/overlay/overlay.component';
import { Spinner } from '../../../../common/spinner';
import useSegmentById from '../../../../../store/hooks/segments/use-segment-by-id.';
import { SegmentAppContent, SegmentMediaContent } from '../../../../../store/types/segment';

interface SegmentCloneFormProps
  extends RouteComponentProps<{ organisationId: string; channelId: string; segmentId: string }> {}

const SegmentCloneForm = (props: SegmentCloneFormProps) => {
  const { t } = useTranslation();

  const {
    match: {
      params: { organisationId: tenantId, channelId, segmentId },
    },
    history,
  } = props;

  const goBack = useGoBack();

  const handleBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const {
    data: channel,
    isLoading: isLoadingChannel,
    isError: isErrorChannel,
  } = useChannelById(tenantId, channelId);

  const {
    data: segment,
    isLoading: isLoadingSegment,
    isError: isErrorSegment,
  } = useSegmentById(tenantId, segmentId);

  const initialValues = useMemo<SegmentFormValues>(() => {
    return {
      name: segment ? segment.name : '',
      startDate: segment ? segment.startDate : '',
      endDate: segment ? segment.endDate : '',
      content: segment && segment.content ? segment.content as SegmentMediaContent | SegmentAppContent : undefined,
      tagsMedia: segment && segment.tagsMedia ? segment.tagsMedia : [],
      conditions: segment && segment.conditions ? segment.conditions : [],
    };
  }, [segment]);

  const { mutateAsync: createContentSegment } = useCreateSegment(tenantId, channelId);

  const onFormSubmit = useCallback(
    async (values: SegmentFormValues): Promise<SegmentFormApiValidationErrors> => {
      try {
        await createContentSegment({
          channelId,
          ...values,
        });

        message.success(<Message content={t('contents.segmentCloneSuccess')} />);
        history.push(`/organisations/${tenantId}/content/channels/${channelId}/segments`);

        return {};
      } catch (error) {
        message.error(<Message content={t('contents.segmentCloneFailed')} />);

        const errors = extractApiValidationErrors(error as ApiError);
        return errors;
      }
    },
    [createContentSegment, tenantId, channelId, history, t],
  );

  return (
    <SegmentFormContainer>
      {(isLoadingChannel || isLoadingSegment) && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {(isErrorChannel || isErrorSegment) && <NotFound>{t('contents.resourceFetchFailed')}</NotFound>}

      {channel && segment && (
        <>
          <BackToListButton type="default" icon="arrow-left" onClick={handleBack}>
            {t('back')}
          </BackToListButton>

          <SegmentFormFields
            tenantId={tenantId}
            channel={channel}
            title={t('contents.cloneSegment')}
            initialValues={initialValues}
            onFormSubmit={onFormSubmit}
          />
        </>
      )}
    </SegmentFormContainer>
  );
};

export default SegmentCloneForm;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const SegmentFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 30px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
