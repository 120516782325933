const { REACT_APP_FORCE_ENV } = process.env;

const {
  REACT_APP_LOCAL_BASE_API_URL,
  REACT_APP_DEV_BASE_API_URL,
  REACT_APP_QA_BASE_API_URL,
  REACT_APP_PROD_BASE_API_URL,
  REACT_APP_LOCAL_APP_HOSTNAME,
  REACT_APP_DEV_APP_HOSTNAME,
  REACT_APP_QA_APP_HOSTNAME,
  REACT_APP_ITAB_QA_APP_HOSTNAME,
  REACT_APP_PROD_APP_HOSTNAME,
  REACT_APP_ITAB_PROD_APP_HOSTNAME,
  REACT_APP_PROD_GENERIC_BROWSER_URL,
  REACT_APP_QA_GENERIC_BROWSER_URL,
  REACT_APP_DEV_GENERIC_BROWSER_URL,
  REACT_APP_PHYGRID_DEV_APP_HOSTNAME,
  REACT_APP_PHYGRID_QA_APP_HOSTNAME,
  REACT_APP_PHYGRID_PROD_APP_HOSTNAME,
} = process.env;

export enum Env {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}

const apiUrls = {
  [Env.LOCAL]: REACT_APP_LOCAL_BASE_API_URL,
  [Env.DEV]: REACT_APP_DEV_BASE_API_URL,
  [Env.QA]: REACT_APP_QA_BASE_API_URL,
  [Env.PROD]: REACT_APP_PROD_BASE_API_URL,
};

export const getEnv = () => {
  // NOTE: use `REACT_APP_FORCE_ENV=prod yarn start` to run app with prod backend
  if (REACT_APP_FORCE_ENV) return REACT_APP_FORCE_ENV as Env;

  const { hostname } = document.location;
  let env;

  switch (hostname) {
    case REACT_APP_LOCAL_APP_HOSTNAME:
      env = Env.LOCAL;
      break;
    case REACT_APP_DEV_APP_HOSTNAME:
    case REACT_APP_PHYGRID_DEV_APP_HOSTNAME:
      env = Env.DEV;
      break;
    case REACT_APP_QA_APP_HOSTNAME:
    case REACT_APP_ITAB_QA_APP_HOSTNAME:
    case REACT_APP_PHYGRID_QA_APP_HOSTNAME:
      env = Env.QA;
      break;
    case REACT_APP_ITAB_PROD_APP_HOSTNAME:
    case REACT_APP_PROD_APP_HOSTNAME:
    case REACT_APP_PHYGRID_PROD_APP_HOSTNAME:
      env = Env.PROD;
      break;
    default:
      env = Env.LOCAL;
      break;
  }

  return env;
};

export const getApiUrl = () => {
  const env = getEnv();
  return (apiUrls[env] as string) || '';
};

export const getGenericBrowserUrl = () => {
  const env = getEnv();

  switch (env) {
    case Env.DEV:
    case Env.LOCAL:
      return REACT_APP_DEV_GENERIC_BROWSER_URL;
    case Env.QA:
      return REACT_APP_QA_GENERIC_BROWSER_URL;
    case Env.PROD:
    default:
      return REACT_APP_PROD_GENERIC_BROWSER_URL;
  }
}

export const isProd = () => getEnv() === Env.PROD;
