import React, { useMemo, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Tabs, Icon, Dropdown, Menu, message, Button, Popconfirm } from 'antd';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../../../../utils/auth/use-permissions';
import { getPermissionPath, permissionKeys } from '../../../../../utils/auth/permissions';
import ProtectedRouteContainer from '../../../../common/protected-route/protected-route.container';
import TabBar from '../../../../common/tab-bar/tab-bar.component';
import SpaceDevices from './space-details-devices.component';
// import SpaceInstallations from './space-details-installations.component';
import SpaceSettings from './space-details-settings.component';
import SpaceUsersContainer from './space-details-users/space-details-users.container';
import SpaceHome from './space-details-home.component';
import Spinner from '../../../../common/spinner/spinner.component';
import { useDeleteSpace, useSpace } from '../../../../common/use-spaces';
import Overlay from '../../../../common/overlay/overlay.component';
import { useAnalyticsParams } from '../../../../common/use-analytics-params';

const { TabPane } = Tabs;

interface SpaceDetailsProps
  extends RouteComponentProps<{ spaceId: string; organisationId: string }> {
}

const SpaceDetails = (props: SpaceDetailsProps) => {
  const {
    match: {
      path,
      url,
      params: { organisationId, spaceId },
    },
    location,
    history,
  } = props;

  const { t, i18n } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);

  const { analyticsQueryParams } = useAnalyticsParams();


  const { showUsersTab, canDeleteSpace } = useMemo(
    () => ({
      showUsersTab: isAllowed(permissionKeys.users.viewAll),
      canDeleteSpace: isAllowed(permissionKeys.spaces.remove),
    }),
    [isAllowed],
  );

  const getOrganisationPermissionPath = useCallback(
    (permissionKey: string) => getPermissionPath(organisationId, permissionKey),
    [organisationId],
  );

  const handleTabChange = useCallback(
    (key: string) => {
      history.replace(key);
    },
    [history],
  );

  const { mutateAsync: deleteSpace } = useDeleteSpace(organisationId, spaceId);

  const handleSpaceDelete = useCallback(
    async () => {
      try {
        if (!spaceId) return;
        await deleteSpace();
        message.success(`${t('successfullyDeletedSpace')}`);
        history.push(`/organisations/${organisationId}/marketplace/browse/public-apps`);
      } catch {
        message.error(t('failedToDeleteSpace'));
      }
    },
    [deleteSpace, t, organisationId, history, spaceId],
  );

  const spaceState = useSpace(spaceId);

  return (
    <Container>
      {spaceState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {spaceState.isSuccess && (
        <TitleContainer>
          <IconContainer>
            <EnvironmentIconContainer>
              <EnvironmentIcon type="environment" />
            </EnvironmentIconContainer>
          </IconContainer>
          <TextContainer>
            <TextTitleContainer>
              <TitleLabel>{spaceState.data.displayName}</TitleLabel>
            </TextTitleContainer>
            <TabBar
              onChange={handleTabChange}
              activeKey={`${location.pathname}${location.search}`}
              lang={i18n.language}
            >
              <TabPane tab={<HomeIcon type="home" />} key={`${url}/home${analyticsQueryParams}`} />
              <TabPane tab={t('devices')} key={`${url}/devices`} />
              {/* <TabPane tab={t('installations')} key={`${url}/installations`} /> */}
              <TabPane tab={t('settings')} key={`${url}/settings`} />
              {showUsersTab && <TabPane tab={t('users')} key={`${url}/users`} />}
            </TabBar>
          </TextContainer>
          {canDeleteSpace && (
            <ExtraActionsContainer>
              <EllipseIconContainer>
                <Dropdown
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      <Menu.Item key={`${spaceId}-remove`}>
                        <Popconfirm
                          title={t('areYouSureYouWantToDelete')}
                          onConfirm={handleSpaceDelete}
                          okText={t('yes')}
                          cancelText={t('no')}
                        >
                          <RemoveButton type="link" icon="delete" size="small">
                            {t('delete')}
                          </RemoveButton>
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <StyledIcon type="ellipsis" />
                </Dropdown>
              </EllipseIconContainer>
            </ExtraActionsContainer>
          )}
        </TitleContainer>
      )}

      {spaceState.isError && (
        <ErrorMessage>{t('errorDuringFetchingSpaces')}</ErrorMessage>
      )}
      <Switch>
        <Route exact path={path} render={() => <Redirect to={{ pathname: `${url}/home`, search: location.search }} />} />
        <ProtectedRouteContainer
          permissionPath={getOrganisationPermissionPath(
            permissionKeys.spaces.viewAnalytics,
          )}
          path={`${path}/home`}
          component={SpaceHome}
        />
        <ProtectedRouteContainer
          permissionPath={getOrganisationPermissionPath(permissionKeys.devices.viewAll)}
          path={`${path}/devices`}
          component={SpaceDevices}
        />
        {/* <ProtectedRouteContainer
          permissionPath={getOrganisationPermissionPath(permissionKeys.apps.viewAll)}
          path={`${path}/installations`}
          component={SpaceInstallations}
        /> */}
        <ProtectedRouteContainer
          permissionPath={getOrganisationPermissionPath(permissionKeys.spaces.update)}
          path={`${path}/settings`}
          component={SpaceSettings}
        />
        <ProtectedRouteContainer
          permissionPath={getOrganisationPermissionPath(permissionKeys.users.viewAll)}
          path={`${path}/users`}
          component={SpaceUsersContainer}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #fff;
`;

const TitleContainer = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  padding: 0px;
  padding-left: 32px;
  padding-top: 15px;
  border-bottom: 1px solid #f2f2fa;
`;

const IconContainer = styled.div`
  width: 56px;
  height: 56px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: center;
  align-items: center;
  gap: 0px;
  background-color: rgb(32, 32, 32);
  opacity: 1;
  border-radius: 8px;
`;

const EnvironmentIconContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  color: #fff;
`;

const EnvironmentIcon = styled(Icon)`
  width: 100%;
  cursor: auto;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 0px;
`;

const TextTitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 0px 0px;
  width: 100%;
  height: min-content;
  inset: auto;
  flex: 0 0 auto;
  align-self: auto;
`;

const TitleLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #000;
  font-size: 24px;
  line-height: 1em;
`;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

const HomeIcon = styled(Icon)`
  padding-left: 8px;
`;

const StyledIcon = styled(Icon)`
  font-size: 21px;
`;

const ExtraActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-left:32px;
`;
const EllipseIconContainer = styled.div`
  bottom: 4px;
  position: relative;
`;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as any;

export default SpaceDetails;
