import { useGridDataService } from '../../grid-data-service-provider';
import { useQuery } from 'react-query';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import routes from '../routes';

export type RawMonitoringEvent = {
  tenantId: string;
  eventTime: Date;
  dataResidency: string;
  sessionId: string;
  clientId: string;
  eventType: string;
  interaction: boolean;
  ip: string;
  captureId: string;
  productId?: string;
  categoryId?: string;
  int1?: number;
  int2?: number;
  int3?: number;
  int4?: number;
  int5?: number;
  str1?: string;
  str2?: string;
  str3?: string;
  str4?: string;
  str5?: string;
  stateful?: boolean;
  public?: number;
  spaceId: string;
  deviceId: string;
  installationId: string;
};

export type AggregatedMonitoringEvent = {
  id: string;
  date: string;
  spaceId: string;
  deviceId: string;
  eventType: string;
  tenantId: string;
  installationId: string;
  str1: string;
  str2: string;
  history: Array<{ int1: number; eventTime: string }>;
};

export type DistinctColumnKeyType =
  | 'str1'
  | 'str2'
  | 'str3'
  | 'str4'
  | 'str5'
  | 'int1'
  | 'int2'
  | 'int3'
  | 'int4'
  | 'int5'
  | 'deviceId';

interface BuildQueryKeyProps {
  tenantId: string;
  eventType: string;
  dataResidency: DataResidencyEnum;
}

const buildQueryKey = ({
  tenantId,
  eventType,
  dataResidency,
}: BuildQueryKeyProps) => [
  'analytics',
  'report',
  'realtime-status',
  'dataResidency',
  dataResidency,
  'tenantId',
  tenantId,
  'eventType',
  eventType,
];

interface BaseProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  eventType: string;
}

interface UseInstallationRealtimeStatusProps extends BaseProps {
  installationId: string;
  distinctColumns: DistinctColumnKeyType[];
}

interface UseInstallationMonitoringStatusHistoryProps extends BaseProps {
  installationId: string;
  dateFrom: string;
  dateTo: string;
}

export const useInstallationRealtimeStatus = ({
  tenantId,
  installationId,
  dataResidency,
  distinctColumns,
  eventType,
}: UseInstallationRealtimeStatusProps) => {
  const gridDataService = useGridDataService();
  const distinctColumnsQs = `${distinctColumns.join(',')}`;

  const buildAppSessionsQuery = () => [
    ...buildQueryKey({ tenantId, eventType, dataResidency }),
    'distinctColumns',
    distinctColumnsQs,
    'installationId',
    installationId,
  ];

  return useQuery(
    buildAppSessionsQuery(),
    () =>
      gridDataService.get<RawMonitoringEvent[]>(
        routes.getRealtimeStatus(tenantId),
        dataResidency,
        {
          installationId,
          distinctColumns: distinctColumnsQs,
          eventType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceRealtimeStatusProps extends BaseProps {
  deviceId: string;
  distinctColumns: DistinctColumnKeyType[];
}

export const useDeviceRealtimeStatus = ({
  tenantId,
  deviceId,
  dataResidency,
  distinctColumns,
  eventType,
}: UseDeviceRealtimeStatusProps) => {
  const gridDataService = useGridDataService();
  const distinctColumnsQs = `${distinctColumns.join(',')}`;

  const buildRequestQueryKey = () => [
    ...buildQueryKey({ tenantId, eventType, dataResidency }),
    'distinctColumns',
    distinctColumnsQs,
    'deviceId',
    deviceId,
  ];

  return useQuery(
    buildRequestQueryKey(),
    () =>
      gridDataService.get<RawMonitoringEvent[]>(
        routes.getRealtimeStatus(tenantId),
        dataResidency,
        {
          deviceId,
          distinctColumns: distinctColumnsQs,
          eventType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseTenantRealtimeEventsProps<TransformDataFnReturnType = RawMonitoringEvent[]> extends BaseProps {
  transformDataFn?: (data: RawMonitoringEvent[]) => TransformDataFnReturnType;
}

export const useTenantRealtimeEvents = <TransformDataFnReturnType = RawMonitoringEvent[]>({
  tenantId,
  dataResidency,
  eventType,
  transformDataFn,
}: UseTenantRealtimeEventsProps<TransformDataFnReturnType>) => {
  const gridDataService = useGridDataService();

  const buildRequestQueryKey = () => [
    ...buildQueryKey({ tenantId, eventType, dataResidency }),
  ];

  return useQuery(
    buildRequestQueryKey(),
    () =>
      gridDataService.get<RawMonitoringEvent[]>(
        routes.getRealtimeEvents(tenantId),
        dataResidency,
        {
          eventType,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      ...(transformDataFn && { select: transformDataFn }),
    },
  );
};

interface UseInstallationRealtimeEventsProps<TransformDataFnReturnType = RawMonitoringEvent[]> extends BaseProps {
  installationId: string;
  transformDataFn?: (data: RawMonitoringEvent[]) => TransformDataFnReturnType;
}

export const useInstallationRealtimeEvents = <TransformDataFnReturnType = RawMonitoringEvent[]>({
  tenantId,
  dataResidency,
  eventType,
  installationId,
  transformDataFn,
}: UseInstallationRealtimeEventsProps<TransformDataFnReturnType>) => {
  const gridDataService = useGridDataService();

  const buildRequestQueryKey = () => [
    ...buildQueryKey({ tenantId, eventType, dataResidency }),
    'installationId',
    installationId,
  ];

  return useQuery(
    buildRequestQueryKey(),
    () =>
      gridDataService.get<RawMonitoringEvent[]>(
        routes.getRealtimeEvents(tenantId),
        dataResidency,
        {
          eventType,
          installationId,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      ...(transformDataFn && { select: transformDataFn }),
    },
  )
}

export const useInstallationMonitoringStatusHistory = ({
  tenantId,
  installationId,
  dataResidency,
  eventType,
  dateFrom,
  dateTo,
}: UseInstallationMonitoringStatusHistoryProps) => {
  const gridDataService = useGridDataService();

  const buildAppSessionsQuery = () => [
    'monitoring-history-status',
    'tenantId',
    tenantId,
    'installationId',
    installationId,
    'dataResidency',
    dataResidency,
    'eventType',
    eventType,
    'dateFrom',
    dateFrom,
    'dateTo',
    dateTo,
  ];

  return useQuery(
    buildAppSessionsQuery(),
    () =>
      gridDataService.get<AggregatedMonitoringEvent[]>(
        routes.getInstallationMonitoringStatusHistory(tenantId, installationId),
        dataResidency,
        {
          eventType,
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

interface UseDeviceMonitoringStatusHistoryProps extends BaseProps {
  deviceId: string;
  dateFrom: string;
  dateTo: string;
}

export const useDeviceMonitoringStatusHistory = ({
  tenantId,
  deviceId,
  dataResidency,
  eventType,
  dateFrom,
  dateTo,
}: UseDeviceMonitoringStatusHistoryProps) => {
  const gridDataService = useGridDataService();

  const buildQueryKey = () => [
    'monitoring-history-status',
    'tenantId',
    tenantId,
    'deviceId',
    deviceId,
    'dataResidency',
    dataResidency,
    'eventType',
    eventType,
    'dateFrom',
    dateFrom,
    'dateTo',
    dateTo,
  ];

  return useQuery(
    buildQueryKey(),
    () =>
      gridDataService.get<AggregatedMonitoringEvent[]>(
        routes.getDeviceMonitoringStatusHistory(tenantId, deviceId),
        dataResidency,
        {
          eventType,
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};
