import { useQuery, useQueryClient, useMutation } from 'react-query';
import toString from 'lodash/toString';
import { useApiService } from '../api-service-provider';
import OrganisationSpace from '../../../store/types/organisation-space';
import { OrganisationSpaceSettings } from '../../organisations/organisation-details/spaces-list/space-form/space-form.component';
import { getAllTenantSpacesQueryKey } from '../use-all-spaces/use-all-spaces';

export const routes = {
  createSpace: () => `/api/spaces`,
  getSpaces: () => ['', 'api', 'v2', 'spaces'].join('/'),
  getSpace: (spaceId: string) => ['', 'api', 'v2', 'spaces', spaceId].join('/'),
  updateSpace: (spaceId: string) => ['', 'api', 'v2', 'spaces', spaceId].join('/'),
  updateSpacev1: (spaceId: string) => `/api/spaces/${spaceId}`,
  deleteSpace: (spaceId: string) => ['', 'api', 'spaces', spaceId].join('/'),
};

const queryKeys = {
  allSpaces: (organisationId: string) => ['spaces', 'organisationId', organisationId],
  spaces: (organisationId: string, limit: number, skip: number) => [
    'spaces',
    'organisationId',
    organisationId,
    'limit',
    limit,
    'skip',
    skip,
  ],
  space: (spaceId: string) => ['spaces', 'spaceId', spaceId],
  organisationSpaces: (organisationId: string) => [
    'spaces',
    'organisationId',
    organisationId,
  ],
};

interface UseSpacesProps {
  organisationId: string;
  limit?: number;
  skip?: number;
  includeSubspaces?: boolean;
  select?: (response: UseSpacesResponse) => UseSpacesResponse;
}

export interface UseSpacesResponse {
  spaces: OrganisationSpace[];
  total: number;
}

export const useSpaces = ({
  organisationId,
  limit = 50,
  skip = 0,
  includeSubspaces = true,
  select,
}: UseSpacesProps) => {
  const apiService = useApiService();

  return useQuery(
    queryKeys.spaces(organisationId, limit, skip),
    () =>
      apiService.get<UseSpacesResponse>(routes.getSpaces(), {
        organizationId: organisationId,
        limit: toString(limit),
        skip: toString(skip),
        includeSubspaces: includeSubspaces,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      select,
    },
  );
};

interface UseAllSpacesProps {
  organisationId: string;
  includeSubspaces?: boolean;
  select?: (response: UseSpacesResponse) => UseSpacesResponse;
}

export interface UseAllSpacesResponse {
  spaces: OrganisationSpace[];
  total: number;
}

export const useAllSpaces = ({
  organisationId,
  includeSubspaces = true,
  select,
}: UseAllSpacesProps) => {
  const apiService = useApiService();

  const initialSkip = 0;
  const itemsPerRequest = 1000;

  async function fetchSpaces({ skip = 0 } = { skip: 0 }) {
    const { spaces, total } = await apiService.get<UseSpacesResponse>(
      routes.getSpaces(),
      {
        organizationId: organisationId,
        limit: toString(itemsPerRequest),
        skip: toString(skip),
        includeSubspaces: includeSubspaces,
      },
    );

    return { spaces, total };
  }

  async function fetchAllSpaces(): Promise<UseAllSpacesResponse> {
    const { spaces, total } = await fetchSpaces();

    async function iter(
      skip: number,
      accumulator: OrganisationSpace[],
    ): Promise<OrganisationSpace[]> {
      if (accumulator.length >= total) {
        return accumulator;
      }

      const { spaces } = await fetchSpaces({ skip });

      return iter(skip + itemsPerRequest, [...accumulator, ...spaces]);
    }

    const allSpaces = await iter(initialSkip + itemsPerRequest, spaces);

    return { spaces: allSpaces, total };
  }

  return useQuery(queryKeys.allSpaces(organisationId), fetchAllSpaces, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    select,
  });
};

export const useSpace = (spaceId: string) => {
  const apiService = useApiService();

  return useQuery(
    queryKeys.space(spaceId),
    () => apiService.get<OrganisationSpace>(routes.getSpace(spaceId)),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: spaceId.length > 0,
    },
  );
};

interface SpaceBody {
  organizationId: string;
  displayName: string;
  longitude: number;
  latitude: number;
  type: string;
  externalId?: string;
  notes?: string;
}

export const useCreateSpace = (organisationId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(
    (spaceBody: OrganisationSpaceSettings) =>
      apiService.post<OrganisationSpace>(routes.createSpace(), spaceBody),
    {
      onSuccess: async (newSpace) => {
        await queryClient.cancelQueries({ queryKey: getAllTenantSpacesQueryKey(organisationId)});
        queryClient.setQueryData<OrganisationSpace[] | undefined>(
          getAllTenantSpacesQueryKey(organisationId),
          (prevSpaces) => {
            if (!prevSpaces) {
              return [newSpace];
            }
    
            const existingIndex = prevSpaces.findIndex((prevSpace) => prevSpace.id === newSpace.id);
    
            if (existingIndex > -1) {
              const updatedSpaces = [...prevSpaces];
              updatedSpaces[existingIndex] = newSpace;
              return prevSpaces;
            }
    
            return [...prevSpaces, newSpace];
          }
        );
        queryClient.invalidateQueries(['all-tenant-spaces', organisationId]);
        queryClient.invalidateQueries(queryKeys.organisationSpaces(organisationId));
      },
    },
  );
};

export const useUpdateSpace = (organisationId: string, spaceId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation<OrganisationSpace, Error, OrganisationSpaceSettings>(
    (spaceBody) =>
      apiService.put(routes.updateSpacev1(spaceId), spaceBody),
    {
      onSuccess: async (newSpace) => {
        await queryClient.cancelQueries({ queryKey: getAllTenantSpacesQueryKey(organisationId)})
        queryClient.setQueryData<OrganisationSpace[] | undefined>(
          getAllTenantSpacesQueryKey(organisationId),
          (prevSpaces) => {
            if (!prevSpaces) {
              return [newSpace];
            }

            const existingIndex = prevSpaces ? prevSpaces.findIndex((prevSpace) => prevSpace.id === spaceId) : -1;

            if (existingIndex === -1) {
              return [...prevSpaces, newSpace];
            }

            const updatedSpaces = [...prevSpaces];
            updatedSpaces[existingIndex] = newSpace;

            return updatedSpaces;
          }
        );
        queryClient.invalidateQueries(getAllTenantSpacesQueryKey(organisationId));
        queryClient.invalidateQueries(queryKeys.organisationSpaces(organisationId));
        queryClient.invalidateQueries(queryKeys.space(spaceId));
      },
    },
  );
};

export const useDeleteSpace = (organisationId: string, spaceId: string) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  return useMutation(() => apiService.delete(routes.deleteSpace(spaceId)), {
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: getAllTenantSpacesQueryKey(organisationId)});
      queryClient.setQueryData<OrganisationSpace[] | undefined>(
        getAllTenantSpacesQueryKey(organisationId),
        (prevSpaces) => {
          if (!prevSpaces) {
            return prevSpaces;
          }

          const updatedSpaces = [...prevSpaces];
          return updatedSpaces.filter((prevSpace) => prevSpace.id !== spaceId);
        }
      );
      queryClient.invalidateQueries(getAllTenantSpacesQueryKey(organisationId));
      queryClient.invalidateQueries(queryKeys.organisationSpaces(organisationId));
      queryClient.invalidateQueries(queryKeys.space(spaceId));
    },
  });
};
