import React from 'react';
import { Icon } from 'antd';
import UniversalDeviceType from '../../../../store/types/universal-device-type.enum';

const types: { [type: string]: [string, string] } = {
  [UniversalDeviceType.IOTHUB]: ["apartment", "Grid OS"],
  [UniversalDeviceType.TIZEN]: ["desktop", "Tizen"],
  [UniversalDeviceType.BROWSER]: ["global", "Generic"],
  [UniversalDeviceType.ANDROID]: ["android", "Android"],
  [UniversalDeviceType.ANDROID_UNMANAGED]: ["android", "Android unmanaged"],
  [UniversalDeviceType.WINDOWS]: ["windows", "Windows"],
  [UniversalDeviceType.MACOS]: ["apple", "Mac OS"],
}

const DeviceTypeIcon = ({ deviceType }: { deviceType: UniversalDeviceType }) => {
  const [icon, title] = types[deviceType] || ["global", deviceType];
  return <>
    <Icon type={icon} style={{ marginRight: '4px' }} />
    {title}
  </>;
};

export default DeviceTypeIcon;
