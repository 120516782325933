import { ContentTagType } from "../../common/content-tags/content-tag-type";
import { SegmentContent, SegmentTagsMedia } from "../../types/segment";

export interface SegmentBody {
  id?: string;
  channelId: string;
  name: string;
  startDate: string;
  endDate: string;
  content?: SegmentContent;
  tagsMedia?: SegmentTagsMedia[];
  conditions: SegmentConditions;
}

export enum SegmentConditionType {
  LOCATION = 'location',
  SECTION = 'section',
  CUSTOM = 'custom',
  COUNTRY = 'country',
  REGION = 'region',
  CITY = 'city',
  FLOOR = 'floor',
};

export enum SegmentConditionOperator {
  EQUAL_TO = 'equal-to',
  NOT_EQUAL_TO = 'not-equal-to',
  SET = 'set',
  NOT_SET = 'not-set',
}

export interface SegmentCondition {
  tagType: ContentTagType | undefined;
  tagId: SegmentConditionType | undefined;
  operator: SegmentConditionOperator | undefined;
  value: string[] | undefined;
}

export type SegmentConditionGroup = SegmentCondition[];

export type SegmentConditions = SegmentConditionGroup[];
