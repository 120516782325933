import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Col, message, Row } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { StickyColumn } from '../../../queue-details/queue-setup/styled-block';
import {
  extractApiValidationErrors,
  getFieldNamesWithError,
  scrollIntoView,
} from '../../../../../common/react-final-form';
import { useTranslation } from 'react-i18next';
import Message from '../../../../../common/message';
import { ApiError } from '../../../../../../services/api/api-error';
import Overlay from '../../../../../common/overlay';
import useGoBack from '../../../../../common/use-go-back/use-go-back';
import OrganizationUserFormFieldsComponent, {
  OrganizationUserApiValidationErrors,
} from './organization-user-form-fields.component';
import OrganizationUserFormSidePanel from './organization-user-form-side-panel.component';
import useOrganizationUsersCreate, {
  UseOrganizationUsersCreateFormFieldsProps,
} from '../../../../../../store/hooks/organization-users/use-organization-users-create';
import { FormContainer, BackToListButton } from '../common';
import {formatAllowedUserSpacesData} from '../../../../../../utils/user-space-utils';

export type OrganizationUserCreateFormProps = RouteComponentProps<{
  organisationId: string;
}>;

const OrganizationUserCreateForm = (props: OrganizationUserCreateFormProps) => {
  const { match } = props;
  const { organisationId: tenantId } = match.params;
  const { t } = useTranslation();
  const goBack = useGoBack();
  const { mutateAsync: createUser } = useOrganizationUsersCreate();
  const organizationUsersListPath = `/organisations/${tenantId}/user-management/users`;


  const handleBackClick = useCallback(() => {
    goBack(organizationUsersListPath);
  }, [goBack, organizationUsersListPath]);

  const initialValues = useMemo<UseOrganizationUsersCreateFormFieldsProps>(() => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phoneNumber: '',
      roleId: '',
      allowedSpaceIds: [],
      hasAccessToAllSpaces: true
    };
  }, []);

  const handleFormCreateSubmit = useCallback(
    async (values: Partial<UseOrganizationUsersCreateFormFieldsProps>) => {
      const payload = formatAllowedUserSpacesData(values as UseOrganizationUsersCreateFormFieldsProps);
      try {
        await createUser({ payload, tenantId });
        message.success(<Message content={t('userManagement.addUserSuccess')} />);
        handleBackClick();
        return {};
      } catch (error) {
        const validationErrors = extractApiValidationErrors<OrganizationUserApiValidationErrors>(error as ApiError);
        message.error(<Message content={t('userManagement.addUserFailure')} error={error as ApiError} />);
        return validationErrors;
      }

    },
    [
      t,
      createUser,
      handleBackClick,
      tenantId,
    ],
  );

  return (
    <FormContainer>
      <Overlay>
        <BackToListButton type="default" icon="arrow-left" onClick={handleBackClick}>
          {t('back')}
        </BackToListButton>
        <Form
          onSubmit={handleFormCreateSubmit}
          keepDirtyOnReinitialize
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          render={(
            {
              submitting: isSubmitting,
              handleSubmit,
              invalid: isFormInvalid,
              errors,
              form: formApi,
              dirty,
            }) => {
            return (
              <form
                onSubmit={(event) => {
                  handleSubmit(event);
                  const errorFieldNames = getFieldNamesWithError(errors);
                  if (!isFormInvalid || !errorFieldNames.length) {
                    return;
                  }
                  scrollIntoView(errorFieldNames[0]);
                }}
              >
                <Row gutter={{ md: 20, xl: 40 }}>
                  <Col md={24} xl={15}>
                    <OrganizationUserFormFieldsComponent
                      organizationId={tenantId}
                      title={t('userManagement.createUser')}
                      description={t('userManagement.addUserFormDescription')} />
                  </Col>
                  <StickyColumn md={24} xl={9}>
                    <OrganizationUserFormSidePanel
                      isSubmitEnabled={!isSubmitting}
                      isSubmitting={isSubmitting}
                      isDirty={dirty}
                    />
                  </StickyColumn>
                </Row>
              </form>
            );
          }}
        />
      </Overlay>
    </FormContainer>
  );
};

export default OrganizationUserCreateForm;
