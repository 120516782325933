import React, { useMemo, useState } from 'react';
import { keyBy } from 'lodash';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import CardContainer from './card-container';
import MonitoringStatusHistory, { StatusReferenceType } from '../charts/monitoring-status-history.component';
import {
  useInstallationMonitoringStatusHistory,
  useDeviceMonitoringStatusHistory,
} from '../../use-analytics-report';
import { GridStyles } from '@ombori/grid-reports';
import useDevice, { useTenantDevices } from '../../use-devices';
import UniversalDevice from '../../../../store/types/universal-device';
import { useAllSpaces, useSpaces } from '../../use-spaces';
import OrganisationSpace from '../../../../store/types/organisation-space';
import CardTitleDownloadButtonWrapper from './card-title-download-button-wrapper';
import styled from '@emotion/styled';
import { convertToCSVFromObject, getCsvDataFileName } from '../../../../utils/convert-csv';
import downloadCSV from '../../../../utils/download-csv';
import Message from '../../message';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

interface MonitoringStatusHistoryProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
  eventType: string;
  title: string;
  statusReference?: Record<
    string | number,
    {
      label: string;
      color: string;
    }
  >;
}

type DevicesRecord = Record<number, UniversalDevice>;

type SpacesRecord = Record<number, OrganisationSpace>;

const DEFAULT_STATUS_REFERENCE = {
  Offline: {
    label: 'Offline',
    color: '#d9534f',
  },
  1: {
    label: 'Online',
    color: '#5cb85c',
  },
  [-1]: {
    label: 'Unknown',
    color: '#292b2c',
  },
};

const minus1Day = (date: string) => {
  const targetDate = new Date(date);
  targetDate.setDate(targetDate.getDate() - 1);
  return targetDate.toISOString().split('T')[0];
};

const getCsvFileStatusLabel = (ref: StatusReferenceType, statusNum: number | string) => {
  const status = ref[+statusNum];
  if (status) {
    return status.label;
  }

  return '';
};

const getCsvFileSpaceName = (spaces: Record<string, OrganisationSpace>, spaceId: string) => {
  if (spaces[spaceId]) {
    return spaces[spaceId].displayName;
  }

  return 'Unknown space';
};

const getCsvFileDeviceName = (devices: Record<string, UniversalDevice>, deviceId: string) => {
  if (devices[deviceId]) {
    return devices[deviceId].deviceName;
  }

  return `<${deviceId}>`;
};


interface InstallationMonitoringStatusHistoryProps extends MonitoringStatusHistoryProps {
  installationId: string;
  dateFrom: string;
  dateTo: string;
}

export const InstallationMonitoringStatusHistory: React.FC<
  InstallationMonitoringStatusHistoryProps
> = ({
       tenantId,
       installationId,
       dataResidency,
       gridStyles,
       isVisibleWithoutData,
       eventType,
       title,
       dateFrom,
       dateTo,
       statusReference = DEFAULT_STATUS_REFERENCE,
     }) => {
  const spacesFetchingState = useSpaces({ organisationId: tenantId, limit: 15000 });

  const devicesFetchingState = useTenantDevices({ tenantId });

  const installationSessionsState = useInstallationMonitoringStatusHistory({
    tenantId,
    installationId,
    dataResidency,
    eventType,
    dateFrom: minus1Day(dateFrom),
    dateTo,
  });

  const spaces = useMemo(() => {
    if (spacesFetchingState.isSuccess) {
      return spacesFetchingState.data.spaces.reduce(
        (spacesRecord: SpacesRecord, space) => {
          return {
            ...spacesRecord,
            [space.id]: space,
          };
        },
        {},
      );
    }

    return {};
  }, [spacesFetchingState]);

  const devices = useMemo(() => {
    if (devicesFetchingState.isSuccess) {
      return devicesFetchingState.data.reduce((devicesRecord: DevicesRecord, device) => {
        return {
          ...devicesRecord,
          [device.id]: device,
        };
      }, {});
    }

    return {};
  }, [devicesFetchingState]);

  const onRefetchLabel = useMemo(() => {
    if (installationSessionsState.isSuccess) {
      return new Date(installationSessionsState.dataUpdatedAt).toUTCString();
    }

    return '';
  }, [installationSessionsState]);

  const isLoading =
    installationSessionsState.isLoading ||
    devicesFetchingState.isLoading ||
    spacesFetchingState.isLoading;

  const isSuccess =
    installationSessionsState.isSuccess &&
    devicesFetchingState.isSuccess &&
    spacesFetchingState.isSuccess;

  const isError =
    installationSessionsState.isError ||
    devicesFetchingState.isError ||
    spacesFetchingState.isError;

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={
        installationSessionsState.isSuccess && installationSessionsState.data.length > 0
      }
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      onRefetchLabel={onRefetchLabel}
      onRefetch={() => installationSessionsState.refetch()}
    >
      {installationSessionsState.isSuccess &&
        devicesFetchingState.isSuccess &&
        spacesFetchingState.isSuccess && (
          <MonitoringStatusHistory
            data={installationSessionsState.data}
            spaces={spaces}
            devices={devices}
            statusReference={statusReference}
            dateTo={new Date(dateTo)}
            dateFrom={new Date(dateFrom)}
          />
        )}
    </CardContainer>
  );
};

interface DeviceMonitoringStatusHistoryProps extends MonitoringStatusHistoryProps {
  deviceId: string;
  dateFrom: string;
  dateTo: string;
}

export const DeviceMonitoringStatusHistory: React.FC<
  DeviceMonitoringStatusHistoryProps
> = ({
       tenantId,
       deviceId,
       dataResidency,
       gridStyles,
       isVisibleWithoutData,
       eventType,
       title,
       dateFrom,
       dateTo,
       statusReference = DEFAULT_STATUS_REFERENCE,
     }) => {

  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const deviceFetchingState = useDevice(deviceId);

  const spacesFetchingState = useSpaces({ organisationId: tenantId, limit: 15000 });

  const monitoringHistoryFetchingState = useDeviceMonitoringStatusHistory({
    tenantId,
    deviceId,
    dataResidency,
    eventType,
    dateFrom: minus1Day(dateFrom),
    dateTo,
  });

  const spaces = useMemo(() => {
    if (spacesFetchingState.data) {
      return keyBy(spacesFetchingState.data.spaces || [], 'id');
    }

    return {};
  }, [spacesFetchingState.data]);

  const devices = useMemo(() => {
    if (deviceFetchingState.isSuccess) {
      return {
        [deviceFetchingState.data.id]: deviceFetchingState.data,
      };
    }

    return {};
  }, [deviceFetchingState]);

  const onRefetchLabel = useMemo(() => {
    if (monitoringHistoryFetchingState.isSuccess) {
      return new Date(monitoringHistoryFetchingState.dataUpdatedAt).toUTCString();
    }

    return '';
  }, [monitoringHistoryFetchingState]);

  const isLoading =
    monitoringHistoryFetchingState.isLoading ||
    deviceFetchingState.isLoading ||
    spacesFetchingState.isLoading;

  const isSuccess =
    monitoringHistoryFetchingState.isSuccess &&
    deviceFetchingState.isSuccess &&
    spacesFetchingState.isSuccess;

  const isError =
    monitoringHistoryFetchingState.isError ||
    deviceFetchingState.isError ||
    spacesFetchingState.isError;

  const handleDownloadButtonClick = () => {
    try {
      setIsDownloading(true);
      let csvData = '';
      const dataExportFileName = getCsvDataFileName({ dateFrom, dateTo, title });

      if (monitoringHistoryFetchingState.data) {
        const formattedData: Record<string, string | number>[] = [];

        monitoringHistoryFetchingState.data.forEach(status => {
          status.history.forEach((history) => {
            const datePart = history.eventTime.split('T')[0];
            const timePart = history.eventTime.split('T')[1].slice(0, -1).split('.')[0];
            formattedData.push({
              Date: datePart,
              Time: timePart,
              Device: getCsvFileDeviceName(devices, status.deviceId),
              Space: getCsvFileSpaceName(spaces, status.spaceId),
              'Associated Device Type': status.str1,
              'Associated Device IP Address': status.str2,
              'Status Value': history.int1,
              'Status Name': getCsvFileStatusLabel(statusReference, history.int1),
            });
          });
        });

        csvData = convertToCSVFromObject(formattedData as Record<string, string>[]);
        if (csvData === '') {
          message.warning(<Message content={t('fileDownload.noDataToDownload')} />);
        } else {
          downloadCSV(csvData, dataExportFileName ? dataExportFileName : 'download-report.csv');
          message.success(<Message content={t('fileDownload.downloadStart')} />);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(<Message content={(t('fileDownload.downloadFailure'))} />);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={
        monitoringHistoryFetchingState.isSuccess &&
        monitoringHistoryFetchingState.data.length > 0
      }
      title={(
        <CardTitleDownloadButtonWrapper
          isDownloadButtonVisible={(monitoringHistoryFetchingState.data && monitoringHistoryFetchingState.data.length > 0) || false}
          onDownloadButtonClicked={handleDownloadButtonClick}
          isDownloading={isDownloading}
        >
          <Title>
            {title}
          </Title>
        </CardTitleDownloadButtonWrapper>
      )}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
      onRefetchLabel={onRefetchLabel}
      onRefetch={() => monitoringHistoryFetchingState.refetch()}
    >
      {monitoringHistoryFetchingState.isSuccess &&
        deviceFetchingState.isSuccess &&
        spacesFetchingState.isSuccess && (
          <MonitoringStatusHistory
            data={monitoringHistoryFetchingState.data}
            spaces={spaces}
            devices={devices}
            statusReference={statusReference}
            dateTo={new Date(dateTo)}
            dateFrom={new Date(dateFrom)}
          />
        )}
    </CardContainer>
  );
};

const Title = styled.div`
    font-size: 16px;
    margin-right: 8px;
`;