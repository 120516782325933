import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ImageMimeType, VideoMimeType } from '../../../../../store/types/media';
import MediaContentPicker, { MediaContentPickerInput, MediaContentPickerSelection, } from '../../../../common/react-final-form/media-content-picker';
import { SegmentAppContent, SegmentMediaContent } from '../../../../../store/types/segment';

interface SegmentFormMediaSectionProps {
  tenantId: string;
  handleSelection: (input: MediaContentPickerInput, value: MediaContentPickerSelection) => void;
}

const SegmentFormMediaSection = (props: SegmentFormMediaSectionProps) => {
  const { tenantId, handleSelection } = props;

  const { t } = useTranslation();

  const handleOnSelection = useCallback((input: MediaContentPickerInput, value: MediaContentPickerSelection) => {
    handleSelection(input, value);
  }, [handleSelection]);

  return (
    <>
      <Typography.Title level={4}>{t('contents.content')}</Typography.Title>
      <Typography.Text>{t('contents.contentSubHeading')}</Typography.Text>

      <MediaContainer>
        <MediaContentPicker<SegmentMediaContent | SegmentAppContent>
          name="content"
          tenantId={tenantId}
          isRequired={true}
          allowedTypes={[ImageMimeType.PNG, ImageMimeType.JPEG, VideoMimeType.MP4, VideoMimeType.WEBM]}
          onSelection={(input, value) => {
            handleOnSelection(input, value);
          }}
        />
      </MediaContainer>
    </>
  );
};

export default SegmentFormMediaSection;

const MediaContainer = styled.div`
  margin-top: 15px;
`;
