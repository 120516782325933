import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Typography, message, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import * as momentTimeZone from 'moment-timezone';
import EmailListPicker from '../../../../common/email-list-picker/email-list-picker';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component';
import Queue from '../../../../../store/types/queue';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import Spinner from '../../../../common/spinner/spinner.component';
import Overlay from '../../../../common/overlay/overlay.component';
import { ApiError } from '../../../../../services/api/api-error';
import QueueExtraDetails from './queue-extra-details/queue-extra-details.component';
import {
  Container,
  StickyColumn,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
  FormTextInput,
  Label,
  InputCheckbox,
  RowStyled,
  Select,
} from './styled-block';
import SelectAdapter from './select-adapter';
import initializeValues from './init-data';
import convertFormValues, { QueueWithoutExtraProperties } from './convert-data-from-form';
import validateForm from './validate-form';
import AutoResetTime from './auto-reset-time';
import WeekdayHourPeriods from './weekday-hour-periods/weekday-hour-periods.component';
import TextInAppCustomizationLanguagePicker from './text-in-app-customization-lanugage-picker';
import EmailsForTeamsCurbsideNotifications from './eamils-for-teams-curbside-notifications';
import LoginMethodsSection from './login-methods-section';
import Feedback from './feedback';
import CustomizableTextsGroup from './customizable-texts-group';
import NotificationsSection from './notifications-section';
import Bookings from './bookings';
import QueueSection from './queue-section';
import Categories from './categories';
import StaffNotifications from './staff-notifications';
import DigitalCallBambuserSection, {
  isBambuserDigitalCallSectionVisible,
} from './digital-call-bambuser-section';
import { DataResidencyEnum } from '../../../../../store/types/organisation';
import User from '../../../../../store/types/user';
import ApiService from '../../../../../services/api/api.service';
import { getApiUrl } from '../../../../../utils/env';
import { SupportedQueueLanguages } from './types';
import Sensors from './sensors-section';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import { OmboriAppsProviderEnums } from '../../../../../utils/installations/get-installation-app-icon-url';
import SpacePicker from './space-picker.component';

const { Title } = Typography;

interface QueueSetupProps
  extends RouteComponentProps<{
    organisationId: string;
    queueId: string;
    appId: string;
  }> {
  queue: Queue | null;
  onSubmit: (values: QueueWithoutExtraProperties) => Promise<void>;
  loaded: boolean;
  regions: DataResidencyEnum[];
  submitButtonText: string;
  canSave: boolean;
  headerTitle: string;
  loading: boolean;
  error: ApiError | null;
  successMessage: string;
  onSuccess: Record<string, any> | null;
  errorMessage: string;
  buttonLoading: boolean;
  organisationId: string;
  user: User;
  provider?: OmboriAppsProviderEnums;
}

const timeZones = momentTimeZone.tz.names();

type UsersFetchingState = 'requested' | 'failed' | 'finished' | 'idle';

const apiService = new ApiService(getApiUrl());

type Direction = 'rtl' | 'ltr';

const getDirection = (language: SupportedQueueLanguages): Direction => {
  if (toLower(language) === toLower(SupportedQueueLanguages.ar)) {
    return 'rtl';
  }

  return 'ltr';
};

const QueueSetup = (props: QueueSetupProps) => {
  const {
    queue,
    onSubmit,
    loaded,
    loading,
    regions,
    submitButtonText,
    error,
    successMessage,
    onSuccess,
    errorMessage,
    buttonLoading,
    canSave,
    organisationId,
    provider,
  } = props;
  const { t } = useTranslation();

  // here: if you need init some values, please update initValues
  // if you want to add validation, please update validateForm function
  const initialValues = useMemo(() => initializeValues({ queue, timeZones, regions }), [
    queue,
    regions,
  ]);
  const validateFormWrapper = useCallback((values: any) => validateForm(values), []);

  useEffect(() => {
    if (error) {
      message.error(errorMessage);
    }
  }, [error, errorMessage]);

  useEffect(() => {
    if (onSuccess) message.success(successMessage);
  }, [onSuccess, successMessage]);

  const [users, setUsers] = useState<User[]>([]);
  const [usersFetchingState, setUsersFetchingState] = useState<UsersFetchingState>(
    'idle',
  );
  const fetchUsers = useCallback(async () => {
    setUsersFetchingState('requested');

    try {
      const response: User[] = await apiService.get(
        `/api/users?organizationId=${organisationId}&orgWithSysadmin=true`,
      );
      setUsers(response);
      setUsersFetchingState('finished');
    } catch (err) {
      setUsersFetchingState('failed');
    }
  }, [organisationId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const onButtonClick = useCallback(
    async (values) => {
      const formQueue = convertFormValues(values);

      onSubmit(formQueue);
    },
    [onSubmit],
  );

  return (
    <ContentWrap>
      <Container>
        <ErrorBoundary>
          {loading && (
            <Overlay>
              <Spinner />
            </Overlay>
          )}
          <Row>
            {loaded && (
              <Form
                onSubmit={onButtonClick}
                mutators={{
                  ...arrayMutators,
                }}
                initialValues={initialValues}
                validate={validateFormWrapper}
                render={({
                  handleSubmit,
                  values,
                  pristine,
                  submitting,
                  form: { change },
                }) => {
                  const featureList = get(values, 'featureList', []);

                  const direction = getDirection(
                    values.selectedLanguageForTextCustomization,
                  );

                  return (
                    <FormComponent onSubmit={handleSubmit} direction={direction}>
                      <Row>
                        <Col md={24} xl={15}>
                          <PanelCardMain bodyStyle={{ padding: 0 }}>
                            <MainSectionTitle>
                              <Title level={2}>{t('generalSettings')}</Title>
                            </MainSectionTitle>
                            <PanelBody>
                              {!queue && regions.length > 1 && (
                                <Field name="region">
                                  {({ input }) => (
                                    <RowStyled>
                                      <Label>{t('region')}</Label>
                                      <Select
                                        {...input}
                                        disabled={queue ? true : undefined}
                                      >
                                        {regions.map((region) => (
                                          <option value={region} key={region}>
                                            {region}
                                          </option>
                                        ))}
                                      </Select>
                                    </RowStyled>
                                  )}
                                </Field>
                              )}
                              <Field name="title">
                                {({ input, meta }) => (
                                  <FormTextInput
                                    input={input}
                                    meta={meta}
                                    label={t('queueTitle')}
                                    required
                                  />
                                )}
                              </Field>
                              <Field name="gridAppProperties.spaceId">
                                {({ input }) => (
                                  <RowStyled>
                                    <Label>{t('space')}</Label>
                                    <SpacePicker tenantId={organisationId} input={input} />
                                  </RowStyled>
                                )}
                              </Field>
                              <Field name="timeZone">
                                {({ input }) => (
                                  <RowStyled>
                                    <Label>{t('timezone')}</Label>
                                    <SelectAdapter options={timeZones} input={input} />
                                  </RowStyled>
                                )}
                              </Field>
                              <Field
                                name={`displayTitle.${
                                  values.selectedLanguageForTextCustomization
                                }`}
                              >
                                {({ input, meta }) => (
                                  <FormTextInput
                                    input={input}
                                    meta={meta}
                                    label={t('queueDisplayTitle')}
                                    labelChildren={
                                      <TextInAppCustomizationLanguagePicker />
                                    }
                                  />
                                )}
                              </Field>
                              <Field name="externalId">
                                {({ input, meta }) => (
                                  <FormTextInput
                                    input={input}
                                    meta={meta}
                                    label={t('externalIdentifier')}
                                  />
                                )}
                              </Field>
                              {canSave && (
                                <Field name="isInStore" type="checkbox">
                                  {({ input }) => (
                                    <RowStyled>
                                      <Label>
                                        <InputCheckbox
                                          {...input}
                                          disabled={values.isAutoCallEnabled}
                                          type="checkbox"
                                        />
                                        {t('inStoreMode')}{' '}
                                        <small>({t('inStoreHint')})</small>
                                      </Label>
                                    </RowStyled>
                                  )}
                                </Field>
                              )}
                              <Field name="logoUrl">
                                {({ input, meta }) => (
                                  <FormTextInput
                                    input={input}
                                    meta={meta}
                                    label={t('logoUrl')}
                                  />
                                )}
                              </Field>
                              <Field name="globalStyles">
                                {({ input, meta }) => (
                                  <>
                                    <FormTextInput
                                      input={input}
                                      meta={meta}
                                      label={t('globalStyles')}
                                    />
                                  </>
                                )}
                              </Field>
                              <WeekdayHourPeriods
                                name="regularHours"
                                label={t('openingHours')}
                                change={change}
                              />
                              <AutoResetTime />
                            </PanelBody>
                          </PanelCardMain>
                          <LoginMethodsSection
                            loginMethod={values.isEmailLogin ? 'email' : 'pin'}
                            emailComponent={
                              <EmailListPicker
                                fetchState={usersFetchingState}
                                users={users}
                                fieldName="emailsAllowed"
                                fieldLabel={t('emails')}
                                placeholder={t('chooseEmails')}
                              />
                            }
                          />
                          {values &&
                            values.featureList &&
                            values.featureList.includes('OCCUPANCY') && (
                              <>
                                <PanelCardMain bodyStyle={{ padding: 0 }}>
                                  <MainSectionTitle>
                                    <Title level={2}>{t('occupancy')}</Title>
                                  </MainSectionTitle>
                                  <PanelBody>
                                    <Field name="storeCapacity">
                                      {({ input, meta }) => (
                                        <FormTextInput
                                          input={input}
                                          meta={meta}
                                          label={t('storeCapacity')}
                                          required
                                        />
                                      )}
                                    </Field>
                                    <Field name="maximumStoreCapacity">
                                      {({ input, meta }) => (
                                        <FormTextInput
                                          input={input}
                                          meta={meta}
                                          label={t('maximumStoreCapacity')}
                                        />
                                      )}
                                    </Field>
                                    <Field name="allowEntryAboveCapacity" type="checkbox">
                                      {({ input }) => (
                                        <RowStyled>
                                          <Label>
                                            <InputCheckbox {...input} type="checkbox" />
                                            {t('allowEntryAboveCapacity')}
                                          </Label>
                                        </RowStyled>
                                      )}
                                    </Field>
                                  </PanelBody>
                                </PanelCardMain>
                                <Sensors values={values} />
                              </>
                            )}
                          {values &&
                            values.featureList &&
                            values.featureList.includes('QUEUE') && (
                              <QueueSection
                                values={values}
                                organizationId={organisationId}
                                change={change}
                                queue={queue}
                              />
                            )}
                          {values &&
                            values.featureList &&
                            (featureList.includes('QUEUE') ||
                              featureList.includes('BOOKING')) && (
                              <Categories
                                activeLanguage={
                                  values.selectedLanguageForTextCustomization
                                }
                              />
                            )}
                          {values &&
                            values.featureList &&
                            values.featureList.includes('BOOKINGS') && (
                              <Bookings values={values} change={change} queue={queue} />
                            )}
                          <NotificationsSection
                            smsEnabled={values.smsEnabled}
                            emailEnabled={values.emailEnabled}
                            applePassEnabled={values.applePassEnabled}
                            webPushEnabled={values.webPushEnabled}
                          />
                          <Feedback
                            isActive={values.feedback.isActive}
                            activeLanguage={values.selectedLanguageForTextCustomization}
                          />
                          {featureList.includes('QUEUE') && (
                            <StaffNotifications
                              isEnabled={values.staffNotifications.isEnabled}
                              emailComponent={
                                <EmailListPicker
                                  fetchState={usersFetchingState}
                                  users={users}
                                  fieldName="staffNotifications.emailList"
                                  fieldLabel={t('staffNotificationsEmailList')}
                                  placeholder={t('chooseEmails')}
                                  required
                                />
                              }
                            />
                          )}
                          {values &&
                            values.featureList &&
                            values.featureList.includes('CURBSIDEPICKUP') && (
                              <PanelCardMain bodyStyle={{ padding: 0 }}>
                                <MainSectionTitle>
                                  <Title level={2}>{t('curbsidePickup')}</Title>
                                </MainSectionTitle>
                                <PanelBody>
                                  <Field name="curbsidePickupArrivalWindowDuration">
                                    {({ input, meta }) => (
                                      <FormTextInput
                                        input={input}
                                        meta={meta}
                                        label={t('timeslotDuration')}
                                        required
                                      />
                                    )}
                                  </Field>
                                  <Field name="curbsidePickupArrivalWindowCapacity">
                                    {({ input, meta }) => (
                                      <FormTextInput
                                        input={input}
                                        meta={meta}
                                        label={t('timeslotCapacity')}
                                        required
                                      />
                                    )}
                                  </Field>
                                  <Field name="curbsideLogoUrl">
                                    {({ input, meta }) => (
                                      <FormTextInput
                                        input={input}
                                        meta={meta}
                                        label={t('curbsideLogoUrl')}
                                      />
                                    )}
                                  </Field>
                                  <Field
                                    name="seperateCurbsidePickupHours"
                                    type="checkbox"
                                  >
                                    {({ input }) => (
                                      <RowStyled>
                                        <Label>
                                          <InputCheckbox {...input} type="checkbox" />
                                          {t('seperateCurbsidePickupHours')}
                                        </Label>
                                      </RowStyled>
                                    )}
                                  </Field>
                                  {values && values.seperateCurbsidePickupHours && (
                                    <WeekdayHourPeriods
                                      name="curbsidePickupHours"
                                      label={t('curbsidePickupHours')}
                                      change={change}
                                      isCapacityAdjustable
                                    />
                                  )}
                                </PanelBody>
                              </PanelCardMain>
                            )}
                          <PanelCardMain bodyStyle={{ padding: 0 }}>
                            <MainSectionTitle>
                              <Title level={2}>{t('textCustomization')}</Title>
                            </MainSectionTitle>
                            <PanelBody>
                              {featureList.length && featureList[0] !== 'CURBSIDEPICKUP' && (
                                <AlertInfo
                                  message={
                                    /* eslint-disable no-template-curly-in-string */
                                    <>
                                      {t('templatingMarkerEmbed')}
                                      <br />
                                      <CodeBlock>{'${location}'}</CodeBlock>{' '}
                                      {t('templatingMarkerEmbedLocation')}
                                      <br />
                                      <CodeBlock>{'${date}'}</CodeBlock>{' '}
                                      {t('templatingMarkerEmbedDate')}
                                      <br />
                                      <CodeBlock>{'${positionURL}'}</CodeBlock>{' '}
                                      {t('templatingMarkerEmbedTicketLink')}
                                      <br />
                                      <CodeBlock>{'${station}'}</CodeBlock>{' '}
                                      {t('templatingMarkerEmbedStation')}
                                      <br />
                                      <CodeBlock>{'${ticketLabel}'}</CodeBlock>{' '}
                                      {t('templatingMarkerEmbedTicketLabel')}
                                      <br />
                                    </>
                                    /* eslint-enable no-template-curly-in-string */
                                  }
                                  type="info"
                                  showIcon
                                />
                              )}
                              <TextInAppCustomizationLanguagePicker />
                              {featureList.includes('QUEUE') && (
                                <>
                                  <Label>
                                    {t('queueTextCustomizationQueueSectionTitle')}
                                  </Label>
                                  <PanelCardMain>
                                    <CustomizableTextsGroup
                                      textsGroup={t('smsCustomization.queue')}
                                      activeLanguage={
                                        values.selectedLanguageForTextCustomization
                                      }
                                    />
                                  </PanelCardMain>
                                </>
                              )}

                              {featureList.includes('BOOKINGS') && (
                                <>
                                  <Label>
                                    {t('queueTextCustomizationBookingSectionTitle')}
                                  </Label>
                                  <PanelCardMain>
                                    <CustomizableTextsGroup
                                      textsGroup={t('smsCustomization.booking')}
                                      activeLanguage={
                                        values.selectedLanguageForTextCustomization
                                      }
                                    />
                                  </PanelCardMain>
                                </>
                              )}

                              {(featureList.includes('QUEUE') ||
                                featureList.includes('BOOKING')) && (
                                <>
                                  <Label>
                                    {t('queueTextCustomizationGeneralSectionTitle')}
                                  </Label>
                                  <PanelCardMain>
                                    <CustomizableTextsGroup
                                      textsGroup={t('smsCustomization.general')}
                                      activeLanguage={
                                        values.selectedLanguageForTextCustomization
                                      }
                                    />
                                  </PanelCardMain>
                                </>
                              )}

                              {featureList.includes('CURBSIDEPICKUP') && (
                                <>
                                  <Label>
                                    {t('queueTextCustomizationCurbsideSectionTitle')}
                                  </Label>
                                  <PanelCardMain>
                                    <CustomizableTextsGroup
                                      textsGroup={t('smsCustomization.curbside')}
                                      activeLanguage={
                                        values.selectedLanguageForTextCustomization
                                      }
                                    />
                                  </PanelCardMain>
                                </>
                              )}
                            </PanelBody>
                          </PanelCardMain>
                          {canSave && featureList.includes('CURBSIDEPICKUP') && (
                            <PanelCardMain bodyStyle={{ padding: 0 }}>
                              <MainSectionTitle>
                                <Title level={2}>
                                  {t(
                                    'queueTextCustomizationMSTeamsCustomizationSectionTitle',
                                  )}
                                </Title>
                              </MainSectionTitle>
                              <PanelBody>
                                <EmailsForTeamsCurbsideNotifications />
                              </PanelBody>
                            </PanelCardMain>
                          )}
                          {featureList.includes('QUEUE') &&
                            isBambuserDigitalCallSectionVisible(
                              values.availableTagsForQueue,
                              values.digitalCall.agentBaseUrl,
                            ) && (
                              <DigitalCallBambuserSection
                                isEnabled={values.digitalCall.isEnabled}
                                queueType={values.digitalCall.queueType}
                              />
                            )}
                        </Col>

                        <StickyColumn
                          md={{ span: 24, offset: 0 }}
                          xl={{ span: 8, offset: 1 }}
                        >
                          <PanelCard bodyStyle={{ padding: 16 }}>
                            {!!queue && (
                              <QueueExtraDetails
                                provider={provider}
                                queue={queue}
                                featureList={values.featureList}
                              />
                            )}
                            {canSave && (
                              <Button
                                size="large"
                                block
                                type="primary"
                                disabled={submitting || pristine}
                                htmlType="submit"
                                loading={buttonLoading}
                              >
                                {submitButtonText}
                              </Button>
                            )}
                          </PanelCard>
                        </StickyColumn>
                      </Row>
                    </FormComponent>
                  );
                }}
              />
            )}
          </Row>
        </ErrorBoundary>
      </Container>
    </ContentWrap>
  );
};

const FormComponent = styled.form<{ direction: Direction }>`
  input[type='text'] {
    ${({ direction }) => `
      direction: ${direction};
    `}
  }
`;

const CodeBlock = styled.div`
  background-color: #c2d8ea;
  border: 1px solid #afcbe4;
  padding: 0px 2px;
  border-radius: 2px;
  margin: 5px 5px auto auto;
  display: inline-block;
`;

const AlertInfo = styled(Alert)`
  margin-bottom: 16px;
`;

export default QueueSetup;