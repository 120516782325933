import React from 'react';
import styled from '@emotion/styled';
import { v4 as uuidV4 } from 'uuid';
import get from 'lodash/get';
import { Typography, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import {
  FormTextInput,
  MainSectionTitle,
  PanelBody,
  PanelCardMain,
} from './styled-block';
import { Category, buildLocalizedField } from '../../../../../store/types/queue';
import LanguagePickerComponent from './text-in-app-customization-lanugage-picker';
import { required } from './validate-form';

const { Title, Text } = Typography;
const { Option } = Select;

const silverTree = '#52B596';
const mojo = '#C63F3A';
const royalBlue = '#3684DD';
const goldenSand = '#F6CD7C';
const mediumPurple = '#6861DE';
const japonica = '#D2765C';
const downy = '#5CCAC8';
const cranberry = '#D65291';

const colors = [
  silverTree,
  mojo,
  royalBlue,
  goldenSand,
  mediumPurple,
  japonica,
  downy,
  cranberry,
];

const buildCategory = (props: Partial<Category> = {}): Category => {
  const {
    id = uuidV4(),
    title = buildLocalizedField(),
    labelPrefix = '',
    color = mediumPurple,
  } = props;

  const result = {
    id,
    title,
    labelPrefix,
    color,
  };

  return result;
};

interface CategoriesProps {
  activeLanguage: string;
}

const Categories: React.FC<CategoriesProps> = ({ activeLanguage }) => {
  const { t } = useTranslation();

  return (
    <PanelCardMain bodyStyle={{ padding: 0 }}>
      <MainSectionTitle>
        <Title level={2}>{t('categoriesTitle')}</Title>

        <Text>{t('categoriesSubtitle')}</Text>
      </MainSectionTitle>

      <PanelBody>
        <FieldArray key="categories" name="categories">
          {({ fields }) => {
            const fieldsLength = get(fields, 'length', 0);
            const isAnyFieldExists = fieldsLength > 0;

            return (
              <>
                <Content>
                  {isAnyFieldExists && (
                    <>
                      <LanguagePicker />

                      <CategoryItem>
                        <CategoryTitle>
                          <Heading>{t('categoryTitle')}</Heading>
                        </CategoryTitle>

                        <CategoryPrefix>
                          <Heading>{t('categoryLabelPrefix')}</Heading>
                        </CategoryPrefix>

                        <CategoryColor>
                          <Heading>{t('categoryColor')}</Heading>
                        </CategoryColor>
                      </CategoryItem>
                    </>
                  )}

                  {fields.map((fieldName, index) => (
                    <CategoryItem key={fieldName}>
                      <CategoryTitle>
                        <Field
                          name={`${fieldName}.title[${activeLanguage}]`}
                          validate={required}
                        >
                          {({ input, meta }) => {
                            return <FormInput input={input} meta={meta} required />;
                          }}
                        </Field>
                      </CategoryTitle>

                      <CategoryPrefix>
                        <Field name={`${fieldName}.labelPrefix`} validate={required}>
                          {({ input, meta }) => {
                            return <FormInput input={input} meta={meta} required />;
                          }}
                        </Field>
                      </CategoryPrefix>

                      <CategoryColor>
                        <Field name={`${fieldName}.color`} validate={required}>
                          {({ input }) => {
                            const onChange = (value: any) => {
                              input.onChange((value as unknown) as React.ChangeEvent);
                            };

                            const filteredColors = colors.filter(
                              (color) => !input.value.includes(color),
                            );

                            return (
                              <SelectContainer>
                                <SelectComponent onChange={onChange} value={input.value}>
                                  {filteredColors.map((color) => (
                                    <Option value={color} key={color}>
                                      <Color style={{ backgroundColor: color }} />
                                    </Option>
                                  ))}
                                </SelectComponent>

                                {!!input.value && (
                                  <SelectValue>
                                    <Color style={{ backgroundColor: input.value }} />
                                  </SelectValue>
                                )}
                              </SelectContainer>
                            );
                          }}
                        </Field>
                      </CategoryColor>

                      <RemoveCategoryButton
                        onClick={() => fields.remove(index)}
                        icon="minus"
                      />
                    </CategoryItem>
                  ))}
                </Content>

                <Footer>
                  <AddCategoryButton
                    onClick={() => fields.push(buildCategory())}
                    icon="plus"
                  />
                </Footer>
              </>
            );
          }}
        </FieldArray>
      </PanelBody>
    </PanelCardMain>
  );
};

const categoryColumnMargin = 15;
const smallCategoryColumnWidth = 100;

const Content = styled.div``;

const CategoryItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  padding-right: 45px;
`;

const CategoryColumn = styled.div`
  margin-right: ${categoryColumnMargin}px;
`;

const CategoryTitle = styled(CategoryColumn)`
  width: calc(100% - ${smallCategoryColumnWidth * 2 + categoryColumnMargin * 2}px);
`;

const CategoryPrefix = styled(CategoryColumn)`
  width: ${smallCategoryColumnWidth}px;
`;

const CategoryColor = styled(CategoryColumn)`
  width: ${smallCategoryColumnWidth}px;
  margin-right: 0;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #000000;
`;

const Footer = styled.div`
  padding-bottom: 15px;
`;

const AddCategoryButton = styled(Button)`` as any;

const RemoveCategoryButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
` as any;

const LanguagePicker = styled(LanguagePickerComponent)`
  margin-bottom: 15px;
`;

const FormInput = styled(FormTextInput)`
  padding: 0;
`;

const Color = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 2px;
`;

const SelectComponent = styled(Select)`
  .ant-select-selection__rendered {
    display: flex;
    width: 100%;
    align-items: center;
    opacity: 0;
  }
`;

const SelectContainer = styled.div`
  position: relative;
`;

const SelectValue = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  pointer-events: none;
`;

export default Categories;
