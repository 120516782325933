import Form, {
  AjvError,
  UiSchema,
  WidgetProps,
  FormValidation,
} from 'react-jsonschema-form';
import { RouteComponentProps } from 'react-router-dom';
import React, { forwardRef, useImperativeHandle, useRef, useCallback } from 'react';
import { uniqBy } from 'lodash';
import { Button, message } from 'antd';
import styled from '@emotion/styled';
import ObjectField from './object-field/object-field.component';
import ArrayField from './array-field/array-field.component';
import CodeEditor from './widgets/code-editor/code-editor.component';
import User from '../../../store/types/user';
import RuleAwareSchemaField from './rule-aware-schema-field/rule-aware-schema-field.component';
import CustomTitleField from './custom-title/custom-title.component';
import useSchemaFormErrorMessage from '../use-schema-form/use-schema-form-error-message';
import MediaPickerContainer from './widgets/media-picker/media-picker.container';
import {
  MultiLanguageText,
  MultiLanguageMediaPicker,
} from './widgets/multi-language/multi-language.container';
import MultiSelect from './widgets/multi-select/multi-select.component';
import CronWidget from './widgets/cron-widget/cron-widget.component';
import { GridappPicker } from './widgets/gridapp-picker';
import { MultiLanguageOptions } from '../../../utils/language/language.util';
import QueuePicker from './widgets/queue-picker/queue-picker.component';
import MobileEndpointPicker from './widgets/mobile-endpoint-picker/mobile-endpoint-picker.cotainer';
import ScreenInstallationPicker from './widgets/installation-picker/screen-installation-picker.container';
import ProductsPicker from './widgets/product-picker/product-picker.component';
import ProductsQuery from './widgets/products-query/products-query.component';
import WeeklySchedulePicker from './widgets/weekly-schedule/weekly-schedule-picker.component';
import DevicePicker from './widgets/device-picker/device-picker.component';
import { InstallationSettingsLevel } from '../../../store/types/organisation-app';
import FilePicker from './fields/file-picker';
import ColorPicker from './fields/color-picker';
import ButtonPicker from './fields/button-picker';
import Input from './fields/input';
import TypographyPicker from './fields/typography';
import Select from './fields/select';
import UserContactPicker from './fields/user-contact-picker';
import ScopeLevelPicker from './fields/scope-level-picker';
import SpacePicker from './widgets/space-picker/space-picker.container';
import ContentTagPicker from './widgets/content-tag-picker/content-tag-picker.component';

interface SchemaFormProps {
  id?: string;
  schema: any;
  data: any;
  uiSchema?: UiSchema;
  onSubmit?: (formData: any) => void;
  onChange?: (formData: any) => void;
  onError?: (error: any) => void;
  formContext?: SchemaFormContext;
  // TODO: make the schema form component more generic and not tied to app/organisation forms
  organisationId?: string;
  liveValidate?: boolean;
  tagName?: string;
  customFormValidation?: (formData: any, errors: FormValidation) => FormValidation;
  scrollToErrorView?:
  | boolean
  | {
    block?: 'start' | 'center' | 'end' | 'nearest';
    inline?: 'start' | 'center' | 'end' | 'nearest';
  };
  enableErrorMessage?: boolean;
  transformErrors?: (errors: AjvError[]) => AjvError[];
}

export interface SchemaFormRef {
  submit: () => void;
}

export type SchemaFormContext = Partial<RouteComponentProps> & {
  user?: User | null;
  languageOptions?: MultiLanguageOptions;
  organisationId?: string;
  appId?: string;
  hideMainGridAppPicker?: boolean;
  isGridAppVersionPickerVisible?: boolean;
  showSettingsOverridingIndicator?: boolean;
  globalSettingsContext?: any;
  spaceSettingsContext?: any;
  settingsLevel?: InstallationSettingsLevel;
}

const widgets = {
  css: (props: WidgetProps) => <CodeEditor {...props} mode="css" />,
  javascript: (props: WidgetProps) => <CodeEditor {...props} mode="javascript" />,
  cron: CronWidget,
};

const fields = {
  SchemaField: RuleAwareSchemaField,
  TitleField: CustomTitleField,
  mediaPicker: MediaPickerContainer,
  filePicker: FilePicker,
  gridappPicker: GridappPicker,
  multiLanguageText: MultiLanguageText,
  multiLanguageMediaPicker: MultiLanguageMediaPicker,
  multiSelect: MultiSelect,
  queuePicker: QueuePicker,
  productPicker: ProductsPicker,
  productsQuery: ProductsQuery,
  mobileEndpointPicker: MobileEndpointPicker,
  screenInstallationPicker: ScreenInstallationPicker,
  weeklySchedulePicker: WeeklySchedulePicker,
  devicePicker: DevicePicker,
  colorPicker: ColorPicker,
  buttonPicker: ButtonPicker,
  input: Input,
  select: Select,
  typographyPicker: TypographyPicker,
  userContactPicker: UserContactPicker,
  scopeLevelPicker: ScopeLevelPicker,
  spacePicker: SpacePicker,
  contentTagPicker: ContentTagPicker,
};

const SchemaForm = forwardRef(
  (
    {
      id,
      schema,
      data,
      uiSchema,
      onSubmit,
      formContext,
      onChange,
      organisationId,
      onError,
      liveValidate,
      tagName,
      customFormValidation,
      scrollToErrorView = true,
      enableErrorMessage = false,
      transformErrors,
    }: SchemaFormProps,
    ref: React.Ref<SchemaFormRef>,
  ) => {
    const submitButton = useRef<HTMLButtonElement>(null);

    const { getErrorMessage: getSchemaErrorMessage } = useSchemaFormErrorMessage();

    useImperativeHandle(
      ref,
      () => ({
        submit: () => {
          if (submitButton.current) {
            submitButton.current.click();
          }
        },
      }),
      [submitButton],
    );

    const handleSubmit = ({ formData }: any) => {
      if (onSubmit) {
        onSubmit(formData);
      }
    };

    const handleChange = ({ formData }: any) => {
      if (onChange) {
        onChange(formData);
      }
    };

    const handleError = useCallback(
      (e: any) => {
        if (onError) {
          onError(e);
        }

        if (scrollToErrorView) {
          const field = document.querySelector('div[class*="field-error"]');
          if (field) {
            const newConfig = scrollToErrorView === true ? {} : scrollToErrorView;
            field.scrollIntoView({ behavior: 'smooth', block: 'center', ...newConfig });
          }
        }

        if (enableErrorMessage) {
          message.error(
            <MessageContent>
              There are errors in the form
              <Button
                type="link"
                size="small"
                icon="close"
                onClick={() => message.destroy()}
              />
            </MessageContent>,
          );
        }
      },
      [enableErrorMessage, onError, scrollToErrorView],
    );

    const handleTransformErrors = useCallback(
      (errors: AjvError[]) => {
        const newErrors = uniqBy(
          errors.map((error) => {
            const { isCustomMessage = false } = error.params || {};

            return {
              ...error,
              ...(isCustomMessage ? {} : { message: getSchemaErrorMessage(error.name) }),
            };
          }),
          'stack',
        );

        return transformErrors ? transformErrors(newErrors) : newErrors;
      },
      [getSchemaErrorMessage, transformErrors],
    );

    return (
      <>
        <Form
          {...(id && { id })}
          formContext={{
            organisationId,
            ...formContext,
          }}
          widgets={widgets}
          fields={fields}
          ArrayFieldTemplate={ArrayField}
          ObjectFieldTemplate={ObjectField}
          noHtml5Validate
          showErrorList={false}
          schema={schema}
          formData={data}
          uiSchema={uiSchema}
          onError={handleError}
          onSubmit={handleSubmit}
          onChange={handleChange}
          transformErrors={handleTransformErrors}
          liveValidate={liveValidate}
          validate={customFormValidation}
          {...{ tagName }}
        >
          {/* was schema, uiSchema */}
          {/* TODO: remove inline style */}
          <button type="submit" style={{ display: 'none' }} ref={submitButton} />
        </Form>
      </>
    );
  },
);

SchemaForm.defaultProps = {
  liveValidate: true,
};

const MessageContent = styled.div`
  display: inline-flex;

  > button {
    left: 10px;
    top: -2px;

    > .anticon {
      color: #00000073;
      font-size: 12px;
    }
  }
`;

export default SchemaForm;
