import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import SelectTree, {
  TreeNode,
  SelectTag,
} from '../../../../common/schema-form/common/select-tree.component';
import useLocation from '../../../../common/use-all-spaces';
import useInstallations from '../../../../common/use-installation';
import { AlertScope } from '../../../../../store/types/alerts';

interface UserContactPickerPropsUiOptions {
  isBordered?: boolean;
  subtitle?: string;
}

export enum ScopeType {
  GLOBAL = 'global',
  LOCATION = 'location',
  INSTALLATION = 'installation',
}

interface FormValue {
  type: ScopeType;
  id: string;
}

interface ScopeLevelPickerProps {
  tenantId: string;
  initialScopes?: AlertScope[];
  onChange: (value: FormValue[]) => void;
  scope?: (ScopeType.GLOBAL | ScopeType.LOCATION | ScopeType.INSTALLATION)[];
}

const ScopeLevelPicker = (props: ScopeLevelPickerProps) => {
  const {
    onChange,
    initialScopes,
    tenantId,
    scope = [ScopeType.GLOBAL, ScopeType.LOCATION, ScopeType.INSTALLATION],
  } = props;

  const { t } = useTranslation();

  const {
    isLoading: isLocationsLoading,
    // isError: isLocationsError,
    data: locations = [],
  } = useLocation(tenantId);

  const {
    isLoading: isInstallationsLoading,
    // isError: isInstallationsError,
    data: installations = [],
  } = useInstallations(tenantId);

  const isLoading = isLocationsLoading || isInstallationsLoading;

  const parentOptions = useMemo(() => {
    return [
      { key: ScopeType.GLOBAL, title: t('scopeLevel.global') },
      { key: ScopeType.LOCATION, title: t('scopeLevel.allLocations') },
      { key: ScopeType.INSTALLATION, title: t('scopeLevel.allInstallations') },
    ];
  }, [t]);

  const treeOptions = useMemo<TreeNode[]>(() => {
    if (!locations) {
      return [];
    }

    let locationList;
    let installationList;
    let childrenNodes: TreeNode[] = [];
    let rootNode: TreeNode = {
      icon: 'global',
      key: ScopeType.GLOBAL,
      title: t('scopeLevel.global'),
      children: [],
    };

    scope.forEach(scopeItem => {
      switch (scopeItem) {
        case ScopeType.INSTALLATION:
          installationList = installations.map((installation) => {
            return {
              icon: 'setting',
              key: `${installation.id}`,
              title: installation.displayName,
            };
          });
          childrenNodes.push({
            icon: 'setting',
            key: ScopeType.INSTALLATION,
            title: t('scopeLevel.allInstallations'),
            children: installationList,
          });
          break;
        case ScopeType.LOCATION:
          locationList = locations.map((location) => {
            return {
              icon: 'environment',
              key: location.id,
              title: location.displayName,
            };
          });
          childrenNodes.push({
            icon: 'environment',
            key: ScopeType.LOCATION,
            title: t('scopeLevel.allLocations'),
            children: locationList,
          });
          break;
      }
    });

    if(scope.includes(ScopeType.GLOBAL)){
      rootNode.children = childrenNodes;
      return [rootNode];
    } else {
      return childrenNodes;
    }
  }, [locations, installations, t, scope]);

  const mappedInitialScopes = useMemo<SelectTag[]>(() => {
    if (!initialScopes) {
      return [];
    }

    return initialScopes.map((data) => {
      const matchedParentOption = parentOptions.find((option) => {
        return option.key === data.id;
      });

      if (matchedParentOption) {
        return {
          key: data.id,
          label: matchedParentOption.title,
          parentKey: 'root',
        };
      }

      const matchedLocation = locations.find((location) => location.id === data.id);

      if (matchedLocation) {
        return {
          key: data.id,
          label: matchedLocation.displayName,
          parentKey: data.type,
        };
      }

      const matchedInstallation = installations.find(
        (installation) => installation.id === data.id,
      );

      return {
        key: data.id,
        label: matchedInstallation ? matchedInstallation.displayName : data.id,
        parentKey: data.type,
      };
    });
  }, [initialScopes, installations, locations, parentOptions]);

  const handleChange = useCallback(
    (value: SelectTag[]) => {
      const mappedValue = value.map((tag) => {
        return {
          id: tag.key,
          type: (tag.parentKey === 'root' ? tag.key : tag.parentKey) as ScopeType,
        };
      });

      onChange(mappedValue);
    },
    [onChange],
  );

  return (
    <SelectTreeContainer>
      <SelectTree
        className="scope-picker-form-field"
        isBordered={true}
        treeOptions={treeOptions}
        onChange={handleChange}
        value={mappedInitialScopes}
        isLoading={isLoading}
      />
    </SelectTreeContainer>
  );
};

export default ScopeLevelPicker;

const SelectTreeContainer = styled.div`
  .scope-picker-form-field:hover {
    border-color: #2364aa !important;
  }
`;
